/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from 'styles/layout/LegalSection.module.sass';

const LegalSection: React.FC = () => {
  return (
    <section className={styles.legalSection}>
      <div className={styles.header}>
        <h1 className={styles.title}>Legal Policy</h1>
        <p className={styles.lastModified}>
          <strong>Last Modified:</strong> 20th October 2024
        </p>
      </div>
      
      <div className={styles.content}>
        <p>
          Welcome to Zerone AnalytiQs! This Legal Policy governs your use of Zerone AnalytiQs' services, including our website (https://zeroneanalytiqs.com), our data collection and analytics mobile application <strong>ZeroneData</strong> (available on Android and iOS), and any other related services. By accessing or using our services, you agree to the terms of this Legal Policy. If you do not agree to this policy, please refrain from using our services.
        </p>
        <p>
          Zerone AnalytiQs is headquartered in Canada and operates in multiple jurisdictions, including Ghana. As we serve clients across Africa and globally, our Legal Policy outlines the terms and regulations governing data privacy, intellectual property, liability, and compliance with relevant laws in multiple regions.
        </p>

        <h2 className={styles.subtitle}>1. Corporate Identity and Jurisdiction</h2>
        <p>
          Zerone AnalytiQs operates as a legal entity in Canada and Ghana, and our services are governed by the laws of these jurisdictions as well as international regulations relevant to data collection, privacy, and intellectual property. This Legal Policy is applicable to all users of our website, mobile app, and services, irrespective of their location.
        </p>
        <h3>a. Headquarters</h3>
        <div className={styles.officeInfo}>
        <h4>Zerone AnalytiQs</h4>
          <address>
            1200 – 900 West Hastings Street<br />
            Vancouver, BC V6C 1E5
          </address>
          <p>Registered in Canada</p>
        </div>
        <h3>b. Regional Office</h3>
        <div className={styles.officeInfo}>
        <h4>Zerone AnalytiQs</h4>
          <address>
            18 South Ofankor<br />
            Ga West, Accra<br />
            GW 737 3350
          </address>
          <p>Registered in Ghana.</p>
        </div>
        <h3>c. Scope of Operations</h3>
        <p>
          This policy applies globally, and local laws where clients operate may also apply to their use of our services.
        </p>

        <h2 className={styles.subtitle}>2. Compliance with Global Data Privacy Laws</h2>
        <p>Zerone AnalytiQs is committed to complying with global data privacy regulations, including:</p>
        <ul>
          <li>Canada's Personal Information Protection and Electronic Documents Act (PIPEDA)</li>
          <li>European Union's General Data Protection Regulation (GDPR)</li>
          <li>Ghana's Data Protection Act, 2012 (Act 843)</li>
          <li>Other applicable regional data protection laws</li>
        </ul>
        <p>
          Our services are designed to respect the privacy rights of individuals and provide transparent information about data collection, processing, and storage. Please refer to our Privacy Policy for detailed information on how we handle your data.
        </p>

        <h2 className={styles.subtitle}>3. Data Collection and Usage</h2>
        <p>
          We collect data through our website and the ZeroneData mobile application to provide analytics and insights to businesses, governments, and individuals. The types of data we collect include:
        </p>
        <ul>
          <li>Personal Information: Including but not limited to names, email addresses, and payment information.</li>
          <li>Non-Personal Information: Including usage patterns, location data (if permitted), and device details.</li>
        </ul>
        <p>
          All data collected is used to improve the quality of our services and to provide users with actionable insights into market trends and consumer behavior. The use of data will always be in compliance with applicable laws, and data subjects will be informed of their rights.
        </p>

        <h2 className={styles.subtitle}>4. Intellectual Property Rights</h2>
        <p>
          All intellectual property related to our services, including software, algorithms, data models, trademarks, logos, and copyrighted content, is owned by Zerone AnalytiQs or its licensors. No part of our services may be reproduced, distributed, modified, or otherwise exploited without express written permission.
        </p>
        <h3>a. Trademarks</h3>
        <p>
          The "Zerone AnalytiQs" name and logo are registered trademarks of Zerone AnalytiQs Ltd. Unauthorized use of our trademarks is strictly prohibited.
        </p>
        <h3>b. User-Generated Content</h3>
        <p>
          Any content that users submit or upload through our services, including data contributions, reviews, or feedback, will be owned by the user but licensed to Zerone AnalytiQs for use in providing and improving our services.
        </p>

        <h2 className={styles.subtitle}>5. Liability and Limitation of Liability</h2>
        <h3>a. Use of Services</h3>
        <p>
          Zerone AnalytiQs provides its services on an "as is" and "as available" basis. We do not make any warranties or guarantees about the accuracy, reliability, or availability of our services. By using our services, you acknowledge that you bear any risks associated with the use of these services.
        </p>
        <h3>b. Limitations</h3>
        <p>
          To the fullest extent permitted by law, Zerone AnalytiQs will not be liable for any damages, including direct, indirect, incidental, consequential, or punitive damages, arising from the use or inability to use our services. This limitation applies regardless of the basis of liability, whether in contract, tort, or otherwise.
        </p>
        <h3>c. Indemnification</h3>
        <p>
          You agree to indemnify and hold Zerone AnalytiQs harmless from any claims, damages, or expenses arising from your use of our services or your breach of this Legal Policy.
        </p>

        <h2 className={styles.subtitle}>6. User Responsibilities</h2>
        <p>As a user of Zerone AnalytiQs services, including our website and mobile app, you agree to:</p>
        <ul>
          <li>Use our services in a manner consistent with all applicable laws and regulations.</li>
          <li>Not engage in any activity that interferes with the functionality of our services or imposes an unreasonable load on our infrastructure.</li>
          <li>Refrain from accessing or attempting to access any part of our systems that you are not authorized to use.</li>
        </ul>

        <h2 className={styles.subtitle}>7. Data Security and Confidentiality</h2>
        <p>
          We implement industry-standard security measures to protect your data from unauthorized access, disclosure, or loss. This includes encryption, secure data transmission, and regular security audits. However, no method of data transmission or storage is 100% secure, and we cannot guarantee the absolute security of your information.
        </p>
        <h3>a. Breach Notifications</h3>
        <p>
          In the event of a data breach, Zerone AnalytiQs will comply with applicable data breach notification laws and promptly inform affected users.
        </p>
        <h3>b. Confidentiality</h3>
        <p>
          All proprietary and confidential information exchanged between Zerone AnalytiQs and its clients will be treated with strict confidentiality. We will not disclose such information to third parties unless required by law.
        </p>

        <h2 className={styles.subtitle}>8. Acceptable Use Policy</h2>
        <p>Users must abide by the following acceptable use guidelines:</p>
        <ul>
          <li>No use of the services to conduct illegal activities, including fraud, identity theft, or money laundering.</li>
          <li>No attempts to reverse engineer, disassemble, or otherwise tamper with ZeroneData or our website.</li>
          <li>No uploading or sharing of harmful software, viruses, or malicious code.</li>
          <li>No use of our services to harm, harass, or violate the rights of other individuals or entities.</li>
        </ul>

        <h2 className={styles.subtitle}>9. Third-Party Services</h2>
        <p>
          Zerone AnalytiQs may include third-party services or integrations with external platforms, such as payment gateways, cloud storage providers, and analytics tools. While we take care in selecting third-party service providers, we do not control their practices, and users should refer to their respective legal policies and terms of service.
        </p>

        <h2 className={styles.subtitle}>10. Complaints and Dispute Resolution</h2>
        <p>
          If you have any complaints or disputes related to your use of our services, we encourage you to first contact us directly to seek resolution. In the event that disputes cannot be resolved amicably, they will be handled as follows:
        </p>
        <h3>a. Governing Law</h3>
        <p>
          This Legal Policy is governed by the laws of Canada, without regard to its conflict of law provisions. However, local laws in Ghana or the region of service provision may also apply.
        </p>
        <h3>b. Arbitration</h3>
        <p>
          All disputes arising from or related to this policy will be resolved through binding arbitration in accordance with the International Chamber of Commerce (ICC) Arbitration Rules. The seat of arbitration will be in [Insert Arbitration Location], and the arbitration will be conducted in English.
        </p>

        <h2 className={styles.subtitle}>11. Amendments and Modifications</h2>
        <p>
          Zerone AnalytiQs reserves the right to modify or amend this Legal Policy at any time. Any significant changes will be communicated to users via email or a notification on our website or mobile app. Continued use of our services after such amendments constitutes acceptance of the updated policy.
        </p>

        <h2 className={styles.subtitle}>12. Termination of Services</h2>
        <p>
          Zerone AnalytiQs reserves the right to terminate or suspend access to our services at any time, with or without cause or notice. Upon termination, you must cease using our services, and all applicable provisions of this Legal Policy, including intellectual property rights, indemnification, and limitation of liability, will remain in effect.
        </p>

        <h2 className={styles.subtitle}>13. Legal and Regulatory Compliance</h2>
        <p>
          Zerone AnalytiQs complies with all relevant legal and regulatory frameworks in Canada, Ghana, and the regions where we provide services. We also adhere to industry standards and best practices in data analytics, privacy, and security. Should local laws conflict with this Legal Policy, the most protective legal standard to the user will apply.
        </p>

        <h2 className={styles.subtitle}>14. User Acknowledgment and Agreement</h2>
        <p>
          By using the Zerone AnalytiQs services, website, or mobile application, you acknowledge that you have read, understood, and agreed to be bound by this Legal Policy. If you do not agree with the terms set out in this policy, you must cease using our services immediately.
        </p>

        <h2 className={styles.subtitle}>15. Contact Information</h2>
        <p>
          If you have any questions about this Legal Policy or need to raise a concern, please contact us at:{' '}
          <a href="https://zeroneanalytiqs.com/contact-us.html" className={styles.link}>
            https://zeroneanalytiqs.com/contact-us.html
          </a>
        </p>
        <div className={styles.contactSection}>
          <div className={styles.officeInfo}>
            <h4>Zerone AnalytiQs</h4>
            <h4>Head quarters:</h4>
            <address>
              1200 – 900 West Hastings Street<br />
              Vancouver, BC V6C 1E5
            </address>
          </div>
          <div className={styles.officeInfo}>
            <h4>Ghana Office (Operations):</h4>
            <address>
              18 South Ofankor<br />
              Ga West, Accra<br />
              GW 737 3350
            </address>
          </div>
          <div className={styles.contactDetails}>
            <p>
              Email:{' '}
              <a href="mailto:legal@zeroneanalytiqs.com" className={styles.link}>
                legal@zeroneanalytiqs.com
              </a>
            </p>
            <p>
              Phone:{' '}
              <a href="tel:+233538855417" className={styles.link}>
                +233 538855417
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LegalSection;