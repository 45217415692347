import endpoints from "constants/api/Endpoints"
import { LindaArthur } from "constants/data/author/LindaArthur"
import { zeroneBlogs } from "constants/data/blog/ZeroneBlogs"
import { BlogContent } from "interface/BlogContent"
import FetchDataByIDProps from "interface/FetchDataByIDProps"
import { logError } from "services/logService/errorLogger"

// TODO: return [] if error during production, defaultBlogs is only for testing

/**
 * Fetches blogs and logs errors if the request fails.
 *
 * @param {string} tag - The name of the component calling this function, used for logging purposes.
 * @returns {Promise<BlogContent[]>} - A promise that resolves to an array of blog contents.
 * 
 * @example
 * // Fetch blogs and log with a tag indicating the calling component
 * const blogs = await fetchBlogs('BlogComponent');
 * console.log(blogs); // Outputs an array of BlogContent objects
 */
export async function fetchBlogs(tag: string): Promise<BlogContent[]> {
    try {
        const result = await endpoints.getBlogs({ tag })
        if (result.error) {
            logError('Error fetching blogs.', { result }, tag)
            return defaultBlogs()
        }
        return result.data
    } catch (error) {
        logError(
            'Unable to retrieve blogs.',
            {error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return defaultBlogs()
    }
}

/**
 * Fetches a hot topic blog post and logs errors if the request fails.
 *
 * @param {string} tag - The name of the component calling this function, used for logging purposes.
 * @returns {Promise<BlogContent>} - A promise that resolves to a single hot topic blog content.
 * 
 * @example
 * // Fetch a hot topic and log with a tag indicating the calling component
 * const hotTopic = await fetchHotTopic('HotTopicComponent');
 * console.log(hotTopic); // Outputs a BlogContent object for the hot topic
 */
export async function fetchHotTopic(tag: string): Promise<BlogContent> {
    try {
        const result = await endpoints.getHotTopic({ tag })
        if (result.error || result.data === null) {
            logError('Error fetching hot topic.', { result }, tag)
            return defaultHotTopic()
        }
        return result.data
    } catch (error) {
        logError(
            'Unable to retrieve hot topic.',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return defaultHotTopic()
    }
}

/**
 * Fetches a specific blog post by its ID and logs errors if the request fails.
 *
 * @param {FetchBlogPostProps} param - An object containing the tag used for logging and the ID of the blog post.
 * @returns {Promise<BlogContent | null>} - A promise that resolves to a single blog content or null if not found.
 * 
 * @example
 * // Fetch a specific blog post by ID and log with a tag indicating the calling component
 * const blogPost = await fetchBlogPost({ tag: 'BlogDetailComponent', id: '12345' });
 * console.log(blogPost); // Outputs a BlogContent object or null if not found
 */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
export async function fetchBlogPost({ tag, id }: FetchDataByIDProps): Promise<BlogContent | null> {
    if (!id) {
        logError('Invalid ID provided.', { id }, tag)
        return null
    }
    try {
        const result = await endpoints.getBlogPost({ tag, id })
        if (result.error || result.data === null) {
            logError('Error fetching blog post.', { id: id, result }, tag)
            return defaultBlogPost(id)
        }
        return result.data
    } catch (error) {
        logError(
            'Unable to retrieve blog post.',
            { id, error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return defaultBlogPost(id)

    }
}

function defaultBlogs() {
    return zeroneBlogs;
}

function defaultHotTopic() {
    const defaultHotTopic: BlogContent = {
        id: "1d84eb88-c142-4b88-97e3-a6241b90a4c1",
        category: "Markets, Industries & Sectors",
        title: "Are Our Neglections on Illegal Mining Catching Up With Us?",
        catchyPhrase: "Over 90% of Ghanaians say illegal mining is destroying our water, and 100% agree it's time to act—now!",
        author: LindaArthur,
        meta: {
            createdDate: new Date('2024-09-27'),
            imageUrl: 'https://images.unsplash.com/photo-1582280776971-087fe7cfa1bd?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            readTime: '5 min read'
        }
    }

    return defaultHotTopic
}

function defaultBlogPost(id: string): BlogContent | null {
    const blog = zeroneBlogs.find(blog => blog.id === id)
    return blog || null
}