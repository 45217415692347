import { Author } from 'interface/Author';
import { Meta } from 'interface/BlogContent';
import React from 'react'
import styles from 'styles/components/BlogSummary.module.sass'

/**
 * Props for the BlogSummary component.
 * @interface BlogSummaryProps
 * @property {string} id - Unique identifier for the blog post.
 * @property {string} category - Category of the blog post.
 * @property {string} title - Title of the blog post.
 * @property {string} catchyPhrase - A catchy phrase or short description for the blog post.
 * @property {Author} author - The author of the blog post.
 * @property {Meta} meta - Metadata related to the blog post.
 * @example
 * const blogProps = {
 *   id: '12345',
 *   category: 'Technology',
 *   title: 'Understanding React Hooks',
 *   catchyPhrase: 'A deep dive into React’s most powerful features.',
 *   author: { name: 'Jane Doe', imageUrl: 'https://example.com/jane.jpg' },
 *   meta: {
 *     createdDate: new Date('2024-01-01'),
 *     imageUrl: 'https://example.com/blog-image.jpg',
 *     readTime: '5 min read'
 *   }
 * };
 */
interface BlogSummaryProps {
    id: string;
    category: string;
    title: string;
    catchyPhrase: string;
    author: Author;
    meta: Meta
}

/**
 * BlogSummary component - A summary display component for a blog post.
 * @param {BlogSummaryProps} props - Props for the component.
 * @returns {JSX.Element} The rendered BlogSummary component.
 * @example
 * <BlogSummary
 *   id="12345"
 *   category="Technology"
 *   title="Understanding AI"
 *   catchyPhrase="A deep dive into AI’s most powerful features."
 *   author={{ name: 'Jane Doe', imageUrl: 'https://zerone.com/jane.jpg' }}
 *   meta={
 *     createdDate: new Date('2024-01-01'),
 *     imageUrl: 'https://zerone.com/blog-image.jpg',
 *     readTime: '5 min read'
 *   }
 * />
 */
const BlogSummary: React.FC<BlogSummaryProps> = ({ id, category, title, catchyPhrase, author, meta }) => {
    const date = meta.createdDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    })

    return (
        <div 
            className={styles['blog-summary']} 
            key={id}  
            style={{
                background: `linear-gradient(rgba(233, 247, 255, 0.9), rgba(219, 242, 255, 0.9)), url(${meta.imageUrl || ''})` 
            }}
        >
            <div className={styles['main-wrapper']}>
                <div className={styles['left-section']}>
                    <div className={styles['top']}>
                        <span className={styles['category']}>{category}</span>
                        <p className={styles['date-wrapper']}> • <span className={styles['date']}>{date}</span></p>
                    </div>
                    <h3 className={styles['title']}>{title}</h3>
                    <p className={styles['catchy-phrase']}>{catchyPhrase}</p>
                    <div className={styles['author']}>
                        <img src={author.imageUrl} alt={author.name} className={styles['author-image']} />
                        <div className={styles['name-role-container']}>
                            <p className={styles['name']}>by <span className={styles['author-name']}>{author.name}</span> • <span className={styles['read-time']}>{meta.readTime}</span></p>
                            <p className={styles['role']}>{author.role}</p>
                        </div>
                    </div>
                </div>
                <div className={styles['right-section']} style={{ backgroundImage: `url(${meta.imageUrl || ''})` }}></div>
            </div>
        </div>
    )
}

export default BlogSummary;