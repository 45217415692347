import React from 'react'
import styles from 'styles/components/ExperienceMetricsCard.module.sass'

/**
 * Metric interface defines the structure for a metric displayed in the ExperienceMetricsCard.
 *
 * @interface Metric
 * @property {string} title - The title of the metric (e.g., '4+').
 * @property {string} description - A detailed description of the metric, with optional newline characters for formatting.
 */
interface Metric {
    title: string;
    description: string;
}

const experienceList: Metric[] = [
    {
        title: '4+',
        description: 'African\n countries'
    },
    {
        title: '40',
        description: 'Years combined experience\n in African markets'
    }
]

/**
 * ExperienceMetricsCard component displays a set of metrics highlighting experience in African markets.
 *
 * Each metric includes a title and a description. Descriptions are split into lines using newline (`\n`)
 * characters and rendered with line breaks for better readability.
 *
 * @component
 * @example
 * // Example usage:
 * <ExperienceMetricsCard />
 *
 * @returns {JSX.Element} The JSX element displaying the experience metrics.
 */
const ExperienceMetricsCard: React.FC = () => {
    return (
        <div className={styles['metrics-card']}>
            {experienceList && (
                experienceList.map((experience) => (
                    <div className={styles['metric']} key={experience.title}>
                        <h2 className={styles['title']}>{experience.title}</h2>
                        <p className={styles['description']}>
                            {experience.description.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>
                    </div>
                ))
            )}
        </div>
    )
}

export default ExperienceMetricsCard;