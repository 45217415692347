import { Feature } from "interface/Feature"
import defaultFeatBgImage from 'assets/images/svg/graph-background-feature.svg'
import featureImage from 'assets/images/png/feature-discover-reports.png'
import featureIcon from '@iconify-icons/solar/export-line-duotone'

/**
 * Daiscover Reports feature object.
 * 
 * @type {Feature}
 * @property {IconifyIcon} icon - The icon for the feature, using the Iconify icon.
 * @property {boolean} isIcon - Determines whether the feature uses an icon.
 * @property {string} iconWrapperBgColor - Background color of the icon wrapper.
 * @property {string} featureImage - The path to the image that will be displayed.
 * @property {string} hoverBackgroundImage - Background image that appears on hover.
 * @property {string} hoverBackground - The gradient background applied on hover.
 * @property {string} title - The title of the feature (Discover Reports).
 * @property {string} description - The description of the feature, explaining its purpose.
 * @property {string} defaultColor - Default color for the icon or text.
 * @property {string} hoverColor - Color applied when the feature is hovered.
 */
export const discoverReportsFeat : Feature = {
    icon: featureIcon,
    isIcon: true,
    iconWrapperBgColor: '#FFFBFA',
    featureImage: featureImage,
    hoverBackgroundImage: defaultFeatBgImage,
    hoverBackground: "linear-gradient(224.8deg, #fff, #d1efff)",
    title: 'Discover Reports',
    description: 'Access expert industry reports and consumer insights across Africa. Get in-depth analysis and recommendations in PDF or quick insights in PPT.',
    defaultColor: '#707070',
    hoverColor: '#005581',
}