import React, { useState } from 'react'
import styles from 'styles/components/SearchBar.module.sass'
import { IconifyIcon } from '@iconify/react';
import { IconType } from 'react-icons';
import searchIcon from '@iconify/icons-ph/magnifying-glass-light'
import ReusableIcon from './ReusableIcon';

/**
 * Interface for SearchBarProps to define the properties accepted by the SearchBar component.
 * 
 * @interface SearchBarProps
 * 
 * @property {string} [placeholder] - Optional placeholder text for the search input.
 * @property {function} onSearch - Callback function triggered when the user types in the search bar.
 * @property {number} [debounceDelay=300] - Optional delay in milliseconds for debounce timing to prevent immediate search calls.
 * @property {string | IconType | IconifyIcon} [icon] - Optional icon to display in the search bar, can be a string path or an icon object.
 * @property {string} [primaryColor] - Optional primary color for the icon.
 */
interface SearchBarProps {
    placeholder?: string;
    onSearch: (query: string) => void;
    debounceDelay?: number;
    icon?: string | IconType | IconifyIcon;
    primaryColor?: string;
}

/**
 * SearchBar component renders a customizable input field for searching with a debounce effect and an optional icon.
 * 
 * @component
 * 
 * @param {SearchBarProps} props - Props for configuring the SearchBar component.
 * @param {string} [props.placeholder='Search'] - Placeholder text displayed in the input field.
 * @param {function} props.onSearch - Function to be called when a search is performed.
 * @param {number} [props.debounceDelay=300] - Debounce delay in milliseconds for search input changes.
 * @param {string | IconType | IconifyIcon} [props.icon=searchIcon] - Icon displayed inside the search bar.
 * @param {string} [props.primaryColor='#B3CEDC'] - Color applied to the search bar icon.
 * 
 * @example
 * // Example usage of SearchBar component
 * <SearchBar
 *   placeholder="Type to search..."
 *   onSearch={(query) => console.log(query)}
 *   debounceDelay={500}
 *   icon={customIcon}
 *   primaryColor="#FF5522"
 * />
 */
const SearchBar: React.FC<SearchBarProps> = ({
    placeholder = 'Search',
    onSearch,
    debounceDelay = 300,
    icon = searchIcon,
    primaryColor = '#B3CEDC'
 }) => {
    const [query, setQuery] = useState('')
    const [debounceTimer, setDebounceTimer] = useState<number | undefined>()

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setQuery(value)

        if(debounceTimer) {
            clearTimeout(debounceTimer)
        }

        setDebounceTimer(
            window.setTimeout(() => {
                onSearch(value)
            }, debounceDelay)
        )
    }

    return (
        <div className={styles['search-bar']}>
            <input
                type='text'
                value={query}
                onChange={handleInputChange}
                placeholder={placeholder}
                className={styles['search-input']} 
            />
            <ReusableIcon icon={icon} className={styles['search-icon'] || 'search-icon'} color={primaryColor} />
        </div>
    )
}

export default SearchBar;