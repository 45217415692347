import CryptoJS from 'crypto-js';
import { logError } from 'services/logService/errorLogger';

/**
 * Creates a cryptographic HMAC signature for a given JSON object or string using a secret key.
 *
 * @param {object | string} data - The data to be signed. It must be a non-null object or string.
 * @param {string} [TAG='CreatePostSignature'] - An optional tag (file calling this method) for logging errors.
 * @returns {string | null} The generated HMAC signature as a hexadecimal string, or null if an error occurs.
 *
 * @example
 * // Example usage:
 * const requestData = { id: 123, name: 'John Doe' };
 * const signature = createSignature(requestData, 'RequestTag');
 * if (signature) {
 *     console.log('Generated Signature:', signature);
 * } else {
 *     console.error('Failed to generate signature.');
 * }
 */
export const createSignature = (data: object | string, TAG: string = 'CreatePostSignature'): string | null => {
    const secret = process.env.REACT_APP_POST_SIGNATURE_SECRET
    if (!secret) {
        logError('SIGNATURE_SECRET is missing.', {}, TAG)
        return null
    }

    if ((typeof data !== 'object' && typeof data !== 'string') || data === null) {
        logError('Invalid data: Input must be a non-null object or string.', { data }, TAG)
        return null
    }

    try {
        const sortedData = typeof data === 'object' ? JSON.stringify(data, Object.keys(data).sort()) : data
        const hmac = CryptoJS.HmacSHA256(sortedData, secret)
        return hmac.toString(CryptoJS.enc.Hex)
    } catch (error) {
        logError(
            'Error creating POST signature',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            TAG
        )
        return null
    }
}
