import { Link } from 'interface/Link'
import React, { useEffect } from 'react'
import styles from 'styles/components/SimpleFooter.module.sass'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

/**
 * Array of footer links to be displayed in the footer component.
 * 
 * @constant
 * @type {Link[]}
 * @example
 * Example usage:
 * 
 * ```javascript
 * const footerLinks: Link[] = [
 *   { text: "Contact", href: "#" },
 *   { text: "FAQ", href: "#" },
 *   { text: "Privacy Policy", href: "#" }
 * ];
 * ```
 * 
 */

const footerLinks: Link[] = [
    {
        text: "Contact",
        href: "/contact-us"
    },
    {
        text: "FAQ",
        href: "#"
    },
    {
        text: "Privacy Policy",
        href: "/privacy"
    }
]

/**
 * SimpleFooter component renders a footer section with a set of links and a copyright notice.
 *
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import SimpleFooter from 'components/SimpleFooter';
 * 
 * const App: React.FC = () => (
 *   <div>
 *     <SimpleFooter />
 *   </div>
 * );
 * 
 * export default App;
 * ```
 * 
 * @returns {JSX.Element} The JSX element representing the footer section.
 */
const SimpleFooter: React.FC = () => {
    const navigate = useNavigate()
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function handleLogoClick() {
        navigate('/')
    }
    return (
        <div className={styles['simple-footer']}>
            <div className={styles['links-section']}>
                {footerLinks.map((link) => (
                    <RouterLink
                        key={link.text}
                        to={link.href}
                        className={styles['link']}>
                            {link.text}
                    </RouterLink>
                ))}
            </div>
            <div className={styles['copyright-section']}>
                <p className={styles['copyright']} onClick={handleLogoClick}>
                    Zerone Analytiqs © {new Date().getFullYear()}
                </p>
            </div>
        </div>
    )
}

export default SimpleFooter;