import { Icon } from '@iconify/react';
import { ButtonProps } from 'interface/ButtonProps';
import React from 'react'
import styles from 'styles/components/SecondaryButton.module.sass'

/**
 * SecondaryButton component renders a reusable button with customizable colors,
 * text, font weight, and optional icon, designed for hover effects.
 * 
 * @component
 * @param {string} width - The width of the button (optional).
 * @param {string} padding - The padding of the button (optional).
 * @param {string} text - The text to display inside the button.
 * @param {string} border - The border of the button (optional) defaults to '2px solid' if not provided.
 * @param {string} [fontWeight='500'] - The font weight of the text (optional). Default is '500'.
 * @param {string} primaryColor - The default background color of the button.
 * @param {string} textColor - The default text color of the button.
 * @param {string} hoverColor - The background color when the button is hovered.
 * @param {string} hoverTextColor - The text color when the button is hovered.
 * @param {string | IconifyIcon} [icon] - Optional icon to display alongside the text.
 * @param {() => void} doSomething - The function to execute when the button is clicked.
 * 
 * @example
 * <SecondaryButton
 *   width="100%"
 *   padding="12px 20px"
 *   text="Contact Us"
 *   primaryColor="#FF5522"
 *   textColor="#FF5522"
 *   hoverColor="#FFFFFF"
 *   hoverTextColor="#FF5522"
 *   icon={arrowIcon}
 *   doSomething={() => console.log('Contact Us clicked!')}
 * />
 * 
 * @returns {JSX.Element} The JSX for the SecondaryButton component.
 */
const SecondaryButton: React.FC<ButtonProps> = ({
    width,
    padding = '12px 20px',
    text,
    border = '2px solid',
    fontWeight = '500',
    primaryColor,
    textColor,
    hoverColor,
    hoverTextColor,
    icon,
    doSomething
 }) => {
    const newWidth : string = String(width) || 'auto';
    return (
        <button
            className={styles['secondary-button']}
            style={{
                backgroundColor: primaryColor,
                color: textColor,
                fontWeight: fontWeight || '700',
                border: border || '2px solid',
                width: newWidth,
                padding: padding || '12px 20px'
            }}
            onClick={(e) => { doSomething(e) }}
            onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = hoverColor;
                e.currentTarget.style.color = hoverTextColor;
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = primaryColor;
                e.currentTarget.style.color = textColor;
            }}>
                <span>{text}</span>
                {icon && <Icon icon={icon} className={styles['button-icon']} />}
            </button>
    );
};

export default SecondaryButton