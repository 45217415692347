import { Login } from "interface/Login"

/**
 * Validates and sanitizes input values for email and password.
 * 
 * @param {Login} input - An object containing email and password fields to validate and sanitize.
 * @returns {{ isValid: boolean, sanitizedEmail: string, sanitizedPassword: string, errors: string[] }} - The result of the validation with sanitized values and error messages.
 */
export const validateAndSanitizeLogin = ({email, password} : Login) => {
    const errors: string[] = []
    const sanitizedEmail = email.trim().toLowerCase().replace(/[<>"']/g, '')
    const sanitizedPassword = password.trim().replace(/[<>"']/g, '')

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(sanitizedEmail)) {
        errors.push('Invalid email.')
    }

    // Password validation
    if (sanitizedPassword.length < 6) {
        errors.push('Password must be at least 6 characters long.')
    } else {
        const hasUpperCase = /[A-Z]/.test(sanitizedPassword);
        const hasLowerCase = /[a-z]/.test(sanitizedPassword);
        const hasNumber = /[0-9]/.test(sanitizedPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(sanitizedPassword);

        if (!hasUpperCase) {
            errors.push('Password must contain at least one uppercase letter.');
        }
        if (!hasLowerCase) {
            errors.push('Password must contain at least one lowercase letter.');
        }
        if (!hasNumber) {
            errors.push('Password must contain at least one number.');
        }
        if (!hasSpecialChar) {
            errors.push('Password must contain at least one special character.');
        }
    }

    return {
        isValid: errors.length === 0,
        sanitizedEmail,
        sanitizedPassword,
        errors
    }
}