/**
 * Renders a text string with highlighted words or phrases wrapped in a span element with a specified CSS class.
 * Prioritizes highlighting longer matches first and escapes special characters for accurate matching.
 * 
 * @param {string} text - The input text to be rendered with highlights.
 * @param {string[]} [highlightedWords=[]] - An array of words or phrases to be highlighted.
 * @param {string} [highlightClassName='highlight'] - The CSS class name to apply to the highlighted span elements.
 * @returns {string | JSX.Element[]} The text with highlighted words wrapped in spans or the original text if no matches are found.
 * 
 * @example
 * // Usage example
 * const highlightedText = renderTextWithHighlights("Zerone Analytiqs", ["Zerone"], "highlight-class");
 * // Output: <span className="highlight-class">Zerone</span> Analytiqs
 */

export const renderTextWithHighlights = (text: string, highlightedWords: string[] = [], highlightClassName: string = 'highlight') => {
    if (highlightedWords.length === 0) return text;

    // Escape special characters and sort phrases by length to prioritize longer matches
    const escapedPhrases = highlightedWords
        .map(phrase => phrase.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
        .sort((a, b) => b.length - a.length); // Sort by length to prioritize longer matches

    // Regex that matches any of the phrases with word boundaries
    const regex = new RegExp(`(${escapedPhrases.join('|')})`, 'gi');

    // Split text by matches
    const parts = text.split(regex);

    return parts.map((part, index) => {
        const match = highlightedWords.find(phrase => phrase.toLowerCase() === part.toLowerCase().trim());
        if (match) {
            return (
                <span key={index} className={highlightClassName}>
                    {part}
                </span>
            );
        }
        return part;
    });
};

