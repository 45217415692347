import React, { useState, useEffect } from 'react'
import styles from 'styles/components/Tracker.module.sass'

interface TrackerProps {
    activeIndex: number;
    totalCircles: number;
}

/**
 * Tracker component visually indicates the current active step within a series of circles.
 * 
 * @component
 * 
 * @param {Object} props - The props for configuring the Tracker.
 * @param {number} props.activeIndex - The index of the currently active circle.
 * @param {number} props.totalCircles - The total number of circles to display.
 * 
 * @example
 * <Tracker activeIndex={2} totalCircles={5} />
 * // Renders a tracker with 5 circles, with the third circle highlighted.
 */
const Tracker: React.FC<TrackerProps> = ({ activeIndex, totalCircles }) => {
    const [currentIndex, setCurrentIndex] = useState(activeIndex);

    useEffect(() => {
        setCurrentIndex(activeIndex)
    }, [activeIndex])

    return (
        <div className={styles['tracker-container']}>
            {Array.from({ length: totalCircles }).map((_, index) => (
                <div
                    key={index}
                    className={`${styles['tracker-circle']} ${currentIndex === index ? styles['active'] : ''}`}>
                </div>
            ))}
        </div>
    )
}

export default Tracker;