import React from 'react';
import AnimatedBackgroundContainer from 'components/common/AnimatedBackgroundContainer';
import SignUpForm from 'components/forms/SignUpForm';
import styles from 'styles/pages/SignUpPage.module.sass';

/**
 * SignUpPage component renders the sign-up page layout with an animated background and a sign-up form.
 * 
 * The component utilizes `AnimatedBackgroundContainer` to provide a visually engaging background with
 * an optional footer. The `SignUpForm` component is nested inside, offering user registration functionality.
 * 
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import SignUpPage from 'pages/SignUpPage';
 * 
 * const App: React.FC = () => (
 *   <SignUpPage />
 * );
 *
 * export default App;
 * ```
 *
 * @returns {JSX.Element} The JSX for the SignUpPage component.
 */
const SignUpPage: React.FC = () => {
    return (
        <div className={styles['sign-up-page']}>
            {/* Animated background with footer */}
            <AnimatedBackgroundContainer showFooter={true}>
                {/* Sign-up form */}
                <SignUpForm />
            </AnimatedBackgroundContainer>
        </div>
    );
};

export default SignUpPage;