import React, { useState } from "react";
import ZeroneBotInsightForm from "components/forms/ZeroneBotInsightForm";
import HeaderNavigation from "components/layout/header/HeaderNavigation";
import LandingHero from "components/layout/header/LandingHero";
import styles from 'styles/layout/HeaderSection.module.sass'
import workspaceDemoVideo from "assets/video/f0975e7f-cfd7-4873-97ed-e9018547d442-demo.mp4"
import VideoPlayer from "components/common/VideoPlayer";

/**
 * HeaderSection component serves as the main header area of the application, integrating navigation,
 * hero content, an insight form, and an optional video player.
 * 
 * @component
 * 
 * @example
 * // Renders the HeaderSection with a toggleable video player and ZeroneBot insight form
 * <HeaderSection />
 * 
 * @returns {JSX.Element} The rendered HeaderSection component.
 */
const HeaderSection: React.FC = () => {
    const [showPlayer, setShowPlayer] = useState(false);
    const handleTogglePlayer = () => setShowPlayer(!showPlayer);
    
    return (
        <header className={styles['header-section']}>
            <div className={styles['main-wrapper']}>
                <HeaderNavigation />
                <div className={styles['background-container']}>
                    <LandingHero handleTogglePlayer= {handleTogglePlayer} />
                </div>
            </div>
            <div className={styles['bot-wrapper']}>
                <ZeroneBotInsightForm />
            </div>
            {showPlayer && (
                <VideoPlayer videoSrc={workspaceDemoVideo} onClose={handleTogglePlayer} />
            )}
        </header>
    )
}

export default HeaderSection;