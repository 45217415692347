import React, { useState, useEffect } from 'react';
import styles from 'styles/layout/TestimonialsSection.module.sass';
import openingQuote from 'assets/images/svg/opening-quote.svg';
import closingQuote from 'assets/images/svg/closing-quote.svg';
import Tracker from 'components/common/Tracker';

interface Testimonial {
  name: string;
  title: string;
  statement: string;
  image: string;
}

const testimonialsList: Testimonial[] = [
  {
    name: 'Ophelia',
    title: 'Business Consultant - ENM and Associates',
    statement:
      'You definitely cannot place a value on data because of how instrumental it is for our work. I see how this will transform so many industries.',
    image: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/testimonials%2FOphelia.PNG?alt=media&token=6f343392-b919-4a73-99cd-8895f2163d45',
  },
  {
    name: 'Ababio',
    title: 'Founder - TAIDE Center',
    statement:
      'Thank you so much for helping us gather valuable insights into the needs and preferences of our customers. This is great for our fitness and wellness business.',
    image: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/testimonials%2FAbabio.PNG?alt=media&token=9592f4f6-e316-4154-9c59-f1c61a311464',
  },
];

/**
 * TestimonialsSection component displays rotating testimonials with user feedback.
 * 
 * @component
 * 
 * @example
 * // Renders the TestimonialsSection component with two testimonials
 * <TestimonialsSection />
 * 
 * @see Tracker - Component for displaying the active testimonial index as a series of circles.
 */
const TestimonialsSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTestimonial, setCurrentTestimonial] = useState(testimonialsList[0]);
  const interval: number = 5000;

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % testimonialsList.length;
      setCurrentIndex(nextIndex);
      setCurrentTestimonial(testimonialsList[nextIndex]);
    }, interval);

    return () => clearInterval(sliderInterval);
  }, [currentIndex]);

  // Function to render the statement with highlighted text
  const renderStatement = (statement: string) => {
    if (statement.includes('You definitely cannot place a value on data')) {
      return (
        <>
          <span style={{ color: '#FF5522' }}>You definitely cannot place a value on data</span>
          {statement.replace('You definitely cannot place a value on data', '')}
        </>
      );
    }
    if (statement.includes('Thank you so much for helping us gather valuable insights')) {
      return (
        <>
          <span style={{ color: '#FF5522' }}>
            Thank you so much for helping us gather valuable insights
          </span>
          {statement.replace('Thank you so much for helping us gather valuable insights', '')}
        </>
      );
    }
    return statement;
  };

  return (
    <section className={styles['testimonials-section']}>
      <div className={styles['title-wrapper']}>
        <h2 className={styles['section-title']}>What they say about us</h2>
        <h2 className={styles['section-subtitle']}>Testimonials</h2>
      </div>
      <div className={styles['testimonial-container']}>
        {currentTestimonial && (
          <div className={`${styles['testimonial-info']}`} key={currentTestimonial.name}>
            <div className={styles['testimonial-statement-wrapper']}>
              <img className={styles['closing-quote']} src={openingQuote} alt="Opening Quote" />
              <p className={styles['testimonial-statement']}>
                {renderStatement(currentTestimonial.statement)}
              </p>
              <img className={styles['closing-quote']} src={closingQuote} alt="Closing Quote" />
            </div>
            <div className={styles['testimonee']}>
              <img
                className={styles['testimonial-img']}
                src={currentTestimonial.image}
                alt="testimonial-img"
              />
              <span className={styles['testimonee-info']}>
                <p className={styles['testimonial-name']}>{currentTestimonial.name}</p>
                <p className={styles['testimonial-title']}>{currentTestimonial.title}</p>
              </span>
            </div>
          </div>
        )}
      </div>
      <Tracker
        activeIndex={currentIndex}
        totalCircles={testimonialsList.length} />

    </section>
  );
};

export default TestimonialsSection;
