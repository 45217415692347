import PricingContainer from 'components/common/PricingContainer';
import HeaderNavigation from 'components/layout/header/HeaderNavigation';
import PricingHero from 'components/layout/header/PricingHero';
import EducationPricing from 'components/pricing/EducationPricing';
import IndividualBusinessPricing from 'components/pricing/IndividualBusinessPricing';
import StartupPricing from 'components/pricing/StartupPricing';
import React, { useState } from 'react'
import styles from 'styles/layout/PricingHeaderSection.module.sass'

/**
 * PricingHeaderSection component displays the main pricing section header,
 * including navigation, hero section, and pricing tabs with content for different
 * pricing categories (Individual/Business, Education, and Startups).
 *
 * @component
 *
 * @example
 * // Renders the PricingHeaderSection component
 * <PricingHeaderSection />
 *
 * @see HeaderNavigation - Component for displaying the navigation bar with a dark theme.
 * @see PricingHero - Component for displaying the hero section with the pricing introduction.
 * @see PricingContainer - Component for displaying tabs and managing active tab content.
 * @see IndividualBusinessPricing - Component for displaying pricing plans for Individual/Business.
 * @see EducationPricing - Component for displaying education-specific pricing.
 * @see StartupPricing - Component for displaying startup-specific pricing.
 */
const PricingHeaderSection: React.FC = () => {
    const [activeTab, setActiveTab] = useState('Individual / Business')

    const pricingTabs = ['Individual / Business', 'Education', 'Startups'];

    const handleTabChange = (tab: string) => {
        setActiveTab(tab)
    }
    
    return (
        <div className={styles['header-section']}>
            <div className={styles['main-wrapper']}>
                <HeaderNavigation theme='dark'/>
                <div className={styles['background-container']}>
                    <PricingHero />
                    <PricingContainer tabs={pricingTabs} activeTab={activeTab} onTabChange={handleTabChange}>
                        {activeTab === 'Individual / Business' && <IndividualBusinessPricing showCompareButton={false} />}
                        {activeTab === 'Education' && <EducationPricing />}
                        {activeTab === 'Startups' && <StartupPricing />}
                    </PricingContainer>
                </div>
            </div>
        </div>
    )
}

export default PricingHeaderSection;