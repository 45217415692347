import { Paragraph } from 'interface/BlogContent'
import React from 'react'
import styles from 'styles/components/BlogPostParagraph.module.sass'
import { v4 as uuid } from 'uuid'
import InteractiveChart from './InteractiveChart';
import { renderTextWithHighlights } from 'utils/TextUtils';

interface BlogPostParagraphProps {
    paragraph: Paragraph;
}

/**
 * BlogPostParagraph component renders a paragraph of text within a blog post,
 * optionally displaying an image or an interactive chart.
 *
 * @component
 *
 * @param {BlogPostParagraphProps} props - The props for the component.
 * @param {Paragraph} props.paragraph - The paragraph data to display, including optional highlighted words, an image, and chart data.
 *
 * @returns {JSX.Element | null} The rendered BlogPostParagraph component.
 *
 * @example
 * // Render a paragraph with text, an image, and an interactive chart
 * <BlogPostParagraph paragraph={paragraphData} />
 */
const BlogPostParagraph: React.FC<BlogPostParagraphProps> = ({ paragraph }) => {
    if (!paragraph) return null;

    const highlightedWords: string[] = Array.isArray(paragraph.highlightedWords)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        ? paragraph.highlightedWords.filter((word): word is string => typeof word === 'string')
        : [];

    const renderImage = paragraph.paragraphImage && (
        <img src={paragraph.paragraphImage.url} className={styles['image']} alt="" />
    );

    const renderInteractiveChart = paragraph.chartData && (
        <InteractiveChart
            key={paragraph.chartData.title}
            chartType={paragraph.chartData.chartType}
            title={paragraph.chartData.title}
            labels={paragraph.chartData.labels}
            values={paragraph.chartData.values}
            {...(paragraph.chartData.groups ? { groups: paragraph.chartData.groups } : {})}
            {...(paragraph.chartData.annotation ? { annotation: paragraph.chartData.annotation } : {} )}
        />
    );

    return (
        <div className={styles['paragraph']} id={uuid()}>
            {paragraph.section && (<h2 className={styles['section']}>{paragraph.section}</h2>)}
            {paragraph.title && (<h3 className={styles['title']}>{paragraph.title}</h3>)}
            <div className={styles['main-content']}>
                {(paragraph.text && paragraph.text.length > 1 || paragraph.paragraphImage) &&(
                    <div className={styles['left-section']}>
                        {paragraph.paragraphImage && paragraph.paragraphImage.position === 'start' && renderImage}
                        <p className={styles['text']}>
                            {renderTextWithHighlights(paragraph.text, highlightedWords, styles['highlight'])}
                        </p>
                        {paragraph.paragraphImage?.position === 'end' && renderImage}
                        {paragraph.chartData?.chartType === 'venn' && renderInteractiveChart}
                    </div>
                )}
                {(paragraph.chartData && paragraph.chartData.chartType !== 'venn') && (
                    <div className={styles['right-section']}>
                        {renderInteractiveChart}
                    </div>
                )}
            </div>
        </div>
    )
}

export default BlogPostParagraph