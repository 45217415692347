/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { logError } from "services/logService/errorLogger"
import { createSignature } from "utils/CreatePostSignatureUtil"
import { ApiMethod, APIOperations, ApiResponse, MultipleRequestsResult } from "./ApiTypes";
import { getDefaultHeaders, handleError, handleFetchResponse } from "./FetchUtils";
import { verifyGetRequest } from "./ApiHelper";

const API_BASE_URL = process.env.REACT_APP_ENVIRONMENT === 'production' 
    ? process.env.REACT_APP_PROD_API_URL 
    : process.env.REACT_APP_DEV_API_URL

/**
 * Interface for API client properties.
 * 
 * @interface ApiClientProps
 * @property {string} TAG - Unique tag for identifying and logging API requests.
 * @property {string} url - The API endpoint URL.
 * @property {APIOperations} options - HTTP request options such as method, headers, and body.
 * @property {string | null} [signature] - Optional HMAC signature for request validation.
 */
interface ApiClientProps {
    TAG: string;
    url: string;
    options: APIOperations;
    signature?: string | null;
}

/**
 * Makes an API request using the provided parameters and returns the response.
 * 
 * @template T - The type of the response data.
 * @param {ApiClientProps} params - Parameters for the API client.
 * @returns {Promise<ApiResponse<T>>} - The API response as a promise.
 * 
 * @example
 * const response = await apiClient<{ userId: number }>({
 *   TAG: 'GetUserData',
 *   url: '/user/123',
 *   options: { method: 'GET' }
 * });
 */
export async function apiClient<T>({TAG, url, options, signature = null}: ApiClientProps): Promise<ApiResponse<T>> {
    try {
        const headers = await getDefaultHeaders(TAG, signature)
        const response = await fetch(`${API_BASE_URL}${url}`, { ...options, headers })
        const jsonResponse = await handleFetchResponse<T>(TAG, response)

        return {
            ...jsonResponse,
            data: (jsonResponse.data as any)?.data || jsonResponse.data
        }
    } catch (error) {
        logError(
            'Error creating POST signature',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            TAG
        )
        return handleError<T>(TAG, error)
    }
}

/**
 * Interface for request properties used in GET and POST functions.
 * 
 * @interface RequestProps
 * @property {string} tag - Unique tag for identifying and logging requests.
 * @property {string} url - The API endpoint URL.
 * @property {object} [body] - Optional request body for POST/PUT requests.
 */
interface RequestProps {
    tag: string;
    url: string;
    body?: object;
}

/**
 * Makes a GET request and verifies the response signature if applicable.
 * 
 * @template T - The type of the response data.
 * @param {RequestProps} params - The request properties.
 * @returns {Promise<ApiResponse<T>>} - The API response as a promise.
 * 
 * @example
 * const response = await get<{ userId: number }>({ tag: 'FetchUser', url: '/user/123' });
 */
export async function get<T extends string | object | null>({ tag, url}: RequestProps): Promise<ApiResponse<T>> {
    const response = await apiClient<T>({
        TAG: tag,
        url,
        options: { method: 'GET' }
    })

    if (response.status === 200) {
        const data = response.data;
        const signature = (response as any)?.signature;

        const verificationResult = verifyGetRequest({
            tag,
            url,
            data,
            signature,
        })

        if (verificationResult.status !== 200) return verificationResult
    }

    return response
}

/**
 * Makes a POST request with the provided body and creates a signature for verification.
 * 
 * @template T - The type of the response data.
 * @param {RequestProps} params - The request properties including body.
 * @returns {Promise<ApiResponse<T>>} - The API response as a promise.
 * 
 * @example
 * const response = await post<{ success: boolean }>({
 *   tag: 'SignUp',
 *   url: '/user',
 *   body: { name: 'John Doe' }
 * });
 */
export async function post<T>({ tag, url, body }: RequestProps): Promise<ApiResponse<T>> {
    if (!body || typeof body !== 'object') {
        logError('Invalid body for POST request', { body, url }, tag);
        return {
            data: null,
            status: 400,
            error: 'Invalid request body.',
        };
    }

    const signature = createSignature(body, tag);
    if (!signature) {
        logError('Signature creation failed', { body, url }, tag);
        return {
            data: null,
            status: 400,
            error: 'Signature creation failed.',
        };
    }

    return apiClient<T>({
        TAG: tag,
        url,
        options: { method: 'POST', body: JSON.stringify(body) },
        signature
    });
}

/**
 * Handles multiple API requests concurrently and verifies GET responses if applicable.
 * 
 * @param {Record<string, { url: string; tag: string; method: ApiMethod; body?: any }>} requests - An object containing multiple request parameters.
 * @returns {Promise<MultipleRequestsResult>} - The results of all requests.
 * 
 * @example
 * const responses = await multipleRequests({
 *   request1: { url: '/user/123', tag: 'FetchUser', method: 'GET' },
 *   request2: { url: '/user', tag: 'CreateUser', method: 'POST', body: { name: 'John Doe' } }
 * });
 */
export async function multipleRequests(
    requests: Record<string, { url: string; tag: string; method: ApiMethod; body?: any }>
): Promise<MultipleRequestsResult> {
    const results: MultipleRequestsResult = {};

    const promises = Object.entries(requests).map(async ([key, { url, tag, method, body }]) => {
        try {
            let options: APIOperations = { method };
            let signature: string | null = null;

            // Handle POST/PUT requests with a body
            if ((method === 'POST' || method === 'PUT') && body && typeof body === 'object') {
                signature = createSignature(body, tag);
                if (!signature) {
                    throw new Error('Signature creation failed for POST/PUT request');
                }
                options = { ...options, body: JSON.stringify(body) };
            }

            // Execute API call
            const response = await apiClient({ TAG: tag, url, options, signature });

            // For GET requests, verify the signature if a signature is provided in the response
            if (method === 'GET' && response.status === 200) {
                const data = response.data;
                const responseSignature = (response as any)?.signature;

                if (data !== null && (typeof data === 'string' || typeof data === 'object')) {
                    const verificationResult = verifyGetRequest({
                        tag,
                        url,
                        data,
                        signature: responseSignature,
                    });
            
                    if (verificationResult.status !== 200) {
                        results[key] = verificationResult;
                        return;
                    }
                } else {
                    logError('Unexpected data type for GET request signature verification', { data, url }, tag);
                    results[key] = {
                        data: null,
                        status: 400,
                        error: 'Invalid data type for signature verification.',
                    };
                    return;
                }
            }

            results[key] = response;
        } catch (error) {
            logError('Error in multipleRequests', { error: error instanceof Error ? error.message : 'Unknown error', url, tag }, tag);
            results[key] = {
                data: null,
                status: 500,
                error: error instanceof Error ? error.message : 'Unexpected error',
            };
        }
    });

    await Promise.all(promises);
    return results;
}
