import InputField from 'components/common/InputField'
import React, { useState } from 'react'
import styles from 'styles/components/LoginForm.module.sass'
import zeroneLogoDark from 'assets/images/svg/logo-dark.svg'
import { Link, useNavigate } from 'react-router-dom'
import SecondaryButton from 'components/common/SecondaryButton'
import PrimaryButton from 'components/common/PrimaryButton'
import { validateAndSanitizeLogin } from 'utils/ValidateLogin'
import { createSignature } from 'utils/CreatePostSignatureUtil'

/**
 * LoginForm component renders a login form with input fields for email and password.
 * 
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import LoginForm from 'components/forms/LoginForm';
 * 
 * const App: React.FC = () => (
 *   <LoginForm />
 * );
 * 
 * export default App;
 * ```
 * 
 * @returns {JSX.Element} The JSX for the LoginForm component, including input fields and action buttons.
 */
const LoginForm: React.FC = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [validationErrors, setValidationErrors] = useState<string[]>([])

    const navigate = useNavigate()

    /**
     * Handles the login click event by validating and sanitizing user input, generating a cryptographic signature,
     * and performing necessary actions based on the validation results.
     *
     * @param {React.FormEvent} event - The form submit event.
     * @returns {void}
     */
    const handleLoginClicked = (event: React.FormEvent) => {
        event.preventDefault()
        setValidationErrors([])

        const { isValid, sanitizedEmail, sanitizedPassword, errors } = validateAndSanitizeLogin({ email, password });

        if (isValid) {
            const signatureData = {
                email: sanitizedEmail,
                password: sanitizedPassword
            }
            const signature = createSignature(signatureData, 'LoginForm')

            if (signature) {
                console.log('Sanitized Email:', sanitizedEmail)
                console.log('Sanitized Password:', sanitizedPassword)
                console.log('Generated Signature:', signature)
            } else {
                setValidationErrors(['An unexpected error occurred. Please try again later.'])
            }
        } else {
            setValidationErrors(errors)
        }
    }

    /**
     * Navigates the user to the sign-up page when the sign-up button is clicked.
     *
     * @returns {void}
     */
    function handleSignupClicked() {
        navigate('/sign-up')
    }

    return (
        <form className={styles['login-form']}>
            <div className={styles['logo-wrapper']}>
                <Link to={"/"}>
                    <img src={zeroneLogoDark} className={styles['logo']} alt='Zerone AnalytiQs Logo' />
                </Link>
            </div>
            <div className={styles['input-wrapper']}>
                <InputField 
                    label={'Email'} 
                    type={'email'} 
                    value={email} 
                    onChange={setEmail} 
                    required={true} 
                />
                <InputField 
                    label={'Password'} 
                    type={'password'} 
                    value={password} 
                    onChange={setPassword} 
                    required={true} 
                />
            </div>
            <div className={styles['action-buttons']}>
                <PrimaryButton
                    text={`Login`}
                    width='auto'
                    primaryColor='#FF5522'
                    textColor='#FFFFFF'
                    hoverColor='#FFFFFF'
                    hoverTextColor='#FF5522'
                    doSomething={(event: React.FormEvent) => handleLoginClicked(event)}
                />               
                <SecondaryButton
                    text="Don’t have an account? Sign up"
                    width='auto'
                    primaryColor= "transparent"
                    textColor="#ff5522"
                    hoverColor="#ff5522"
                    hoverTextColor="#FFFFFF"
                    doSomething={handleSignupClicked}
                /> 
            </div>
            {validationErrors && validationErrors.length > 0 && (
                <span className={styles['error']}>{validationErrors[0]}</span>
            )}
        </form>
    )
}

export default LoginForm;