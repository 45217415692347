import React, { useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import styles from 'styles/components/InteractiveChart.module.sass';
import { BetaChartData } from 'interface/BetaChartData';
/* eslint-disable */
/**
 * InteractiveChart component renders a responsive and animated chart with customizable data and layout.
 * 
 * @component
 * 
 * @param {Object} props - The props for configuring the InteractiveChart.
 * @param {string} props.title - The title displayed above the chart.
 * @param {'pie' | 'bar' | 'line' | 'bar-group' | 'venn' | 'ranked-bar'} props.chartType - The type of chart to render.
 * @param {string[] | string[][]} props.labels - The labels for each data point in the chart.
 * @param {number[] | number[][]} props.values - The values corresponding to each label, used for the chart data.
 * 
 * @example
 * // Renders an InteractiveChart with a pie chart
 * <InteractiveChart
 *   title="Sales Distribution"
 *   chartType="pie"
 *   labels={['Product A', 'Product B', 'Product C']}
 *   values={[30, 50, 20]}
 * />
 * 
 * @example
 * // Renders an InteractiveChart with a bar chart
 * <InteractiveChart
 *   title="Monthly Revenue"
 *   chartType="bar"
 *   labels={['January', 'February', 'March']}
 *   values={[1000, 1500, 1200]}
 * />
 */
const InteractiveChart: React.FC<BetaChartData> = ({ title, chartType, labels, values, groups = null, annotation = null }) => {
    const plotRef = useRef<any>(null);

    const commonLayout = {
        title: {
            font: {
                size: 16,
                color: '#005581',
                family: 'Poppins, sans-serif',
            },
            x: 0.5,
            xanchor: 'center' as const,
        },
        showlegend: chartType === 'pie' || chartType === 'bar-group' || chartType === 'venn',
        legend: {
            orientation: 'h' as const,
            x: 0.5,
            xanchor: 'center' as const,
            y: -0.3,
            font: {
                size: 12,
                color: '#333',
                family: 'Poppins, sans-serif',
            },
        },
        margin: { t: 60, b: 40, l: 60, r: 20 },
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
    };

    const colorPalette = ['#B6FBFF', '#00486D', '#F5A623', '#FF5522'];

    const commonConfig = {
        displayModeBar: false,
        responsive: true,
        staticPlot: false,
    };

    const isSmallScreen = window.innerWidth < 768;

    useEffect(() => {
        if (plotRef.current) {
            try {
                const updateData = chartType === 'bar' ? { y: values } : { values };
                const animationConfig = {
                    transition: { duration: 800, easing: 'cubic-in-out' as const },
                    frame: { duration: 800 },
                };

                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                plotRef.current?.animate(
                    {
                        data: [updateData],
                        traces: [0],
                        layout: { yaxis: { autorange: true } },
                    },
                    animationConfig
                );
            } catch (error) {
                console.error('Animation error:', error);
            }
        }
    }, [values, chartType]);

    const groupedBarData: Partial<Plotly.Data>[] =
    chartType === 'bar-group' && groups?.length && Array.isArray(labels[0]) && Array.isArray(values[0])
        ? groups.map((group, groupIndex) => ({
              type: 'bar',
              x: labels[0] as string[],
              y: values[groupIndex] as number[],
              marker: { color: colorPalette[groupIndex % colorPalette.length] || '#000' },
              name: group ?? `Group ${groupIndex + 1}`, // Group name for legend
          }))
        : [];

    const vennData: Partial<Plotly.ScatterData>[] = chartType === 'venn'
        ? [{
            type: 'scatter',
            mode: 'markers+text',
            x: values as number[],
            y: values as number[],
            text: Array.isArray(labels[0]) ? (labels as string[][]).flat() : (labels as string[]),
            textposition: 'middle center',
            marker: {
                size: (values as number[]).map(value => value * 5),
                color: colorPalette.slice(0, (values as number[]).length),
                opacity: 0.5,
            },
            hoverinfo: 'x',
        } as unknown as Partial<Plotly.ScatterData>]
        : [];


    const rankedBarData: Partial<Plotly.Data>[] = chartType === 'ranked-bar'
        ? [{
                type: 'bar',
                x: values as number[],
                y: labels as string[],
                orientation: 'h', // Horizontal bar chart
                marker: {
                    color: colorPalette[1] || '#FF5522',
                },
            }]
        : [];

    const mosaicData: Partial<Plotly.Data>[] = chartType === 'treemap'
        ? [{
            type: 'treemap',
            labels: Array.isArray(labels[0]) ? (labels as string[][]).flat() : (labels as string[]),
            parents: Array(labels.length).fill(''),
            values: Array.isArray(values[0]) ? (values as number[][]).flat() : (values as number[]),
            textinfo: 'label+text+percent',
            textposition: 'middle center',
            hoverinfo: 'y',
            marker: {
                colors: colorPalette,
            },
        }]
        : [];

    return (
        <div className={styles['interactive-chart-container']}>
            <p className={styles['chart-title']}>{title}</p>
            {chartType === 'pie' ? (
                <Plot
                    ref={(el) => { plotRef.current = el; }}
                    data={[
                        {
                            type: 'pie',
                            labels: Array.isArray(labels[0]) ? (labels as string[][]).flat() : (labels as string[]),
                            values: Array.isArray(values[0]) ? (values as number[][]).flat() : (values as number[]),
                            textinfo: 'percent',
                            hoverinfo: 'percent',
                            marker: {
                                colors: colorPalette,
                                line: { color: '#fff', width: 2 },
                            },
                            hole: 0.4,
                        },
                    ]}
                    layout={commonLayout}
                    config={commonConfig}
                    useResizeHandler
                    style={{ width: '100%', height: '100%' }}
                />
            ) : chartType === 'bar-group' && groupedBarData.length > 0 ? (
                <Plot
                    ref={(el) => { plotRef.current = el; }}
                    data={groupedBarData}
                    layout={{
                        ...commonLayout,
                        barmode: 'group',
                        xaxis: {
                            title: { text: '', font: { color: '#005581' } },
                            showticklabels: !isSmallScreen,
                        },
                        yaxis: { title: { text: 'Percentage (%)', font: { color: '#005581' } } },
                    }}
                    config={commonConfig}
                    useResizeHandler
                    style={{ width: '100%', height: '100%' }}
                />
            ) : chartType === 'venn' ? (
                <Plot
                    ref={(el) => { plotRef.current = el; }}
                    data={vennData}
                    layout={{
                        ...commonLayout,
                        showlegend: false,
                    }}
                    config={commonConfig}
                    useResizeHandler
                    style={{ width: '100%', height: '100%' }}
                />
            ) : chartType === 'ranked-bar' ? (
                <Plot
                    ref={(el) => { plotRef.current = el; }}
                    data={rankedBarData}
                    layout={{
                        ...commonLayout,
                        xaxis: {
                            title: { text: 'Total Respondent', font: { color: '#005581' } },
                        },
                        yaxis: {
                            title: { text: 'Issues', font: { color: '#005581' } },
                            automargin: true,
                        },
                    }}
                    config={commonConfig}
                    useResizeHandler
                    style={{ width: '100%', height: '100%' }}
                />
            ) : chartType === 'bar' ? (
                <Plot
                    ref={(el) => { plotRef.current = el; }}
                    data={[
                        {
                            type: 'bar',
                            x: Array.isArray(labels[0]) ? (labels as string[][]).flat() : labels,
                            y: Array.isArray(values[0]) ? (values as number[][]).flat() : values,
                            text: Array.isArray(values[0])
                                ? (values as number[][]).flat().map((v) => `${v}%`)
                                : values.map((v) => `${v}%`),
                            textposition: 'none',
                            hoverinfo: 'x+y',
                            mode: 'lines+markers',
                            marker: {
                                color: colorPalette,
                            },
                            showlegend: true,
                        },
                    ]}
                    layout={{
                        ...commonLayout,
                        xaxis: {
                            title: { text: '', font: { color: '#005581' } },
                            showticklabels: !isSmallScreen,
                        },
                        yaxis: { title: { text: 'Percentage (%)', font: { color: '#005581' } } },
                    }}
                    config={commonConfig}
                    useResizeHandler
                    style={{ width: '100%', height: '100%' }}
                />
            ) : chartType === 'treemap' ? (
                <Plot
                    ref={(el) => { plotRef.current = el; }}
                    data={mosaicData}
                    layout={{
                        ...commonLayout
                    }}
                    config={commonConfig}
                    useResizeHandler
                    style={{ width: '100%', height: '100%' }}
                />
            ): null}
            {annotation && (<p className={styles['chart-annotation']}>{annotation}</p>)}
        </div>
    );
};

export default InteractiveChart;
