import { Icon } from '@iconify/react';
import { ButtonProps } from 'interface/ButtonProps';
import React from 'react'
import styles from 'styles/components/PrimaryButton.module.sass'

/**
 * PrimaryButton component renders a customizable button that supports dynamic
 * text, colors, font weight, and an optional icon.
 * 
 * This button provides hover effects that change the background color, text color,
 * and applies a shadow when hovered. It also supports a custom click
 * handler passed via the `doSomething` prop.
 * 
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import PrimaryButton from 'components/common/PrimaryButton';
 * 
 * const App: React.FC = () => (
 *   <PrimaryButton
 *     text="View Reports"
 *     primaryColor="#009E4C"
 *     textColor="#FFFFFF"
 *     hoverColor="#FFFFFF"
 *     hoverTextColor="#009E4C"
 *     icon={arrowIcon}
 *     doSomething={() => console.log('Button clicked!')}
 *   />
 * );
 * 
 * export default App;
 * ```
 * 
 * @param {string} width - The width of the button (optional).
 * @param {string} text - The text to display inside the button.
 * @param {string} [fontWeight] - The font weight of the text (optional). Default is `700`.
 * @param {string} primaryColor - The default background color of the button.
 * @param {string} textColor - The default text color of the button.
 * @param {string} hoverColor - The background color of the button when hovered.
 * @param {string} hoverTextColor - The text color of the button when hovered.
 * @param {string | IconifyIcon} [icon] - Optional icon to be displayed next to the button text.
 * @param {function} doSomething - Function to execute when the button is clicked.
 * 
 * @returns {JSX.Element} The JSX for the PrimaryButton component.
 */
const PrimaryButton: React.FC<ButtonProps> = ({
    width,
    text,
    fontWeight = '700',
    primaryColor,
    textColor,
    hoverColor,
    hoverTextColor,
    icon,
    doSomething,
}) => {
    const newWidth : string = String(width) || 'auto';
    return (
        <button
            className={styles['primary-button']}
            style={{
                backgroundColor: primaryColor,
                color: textColor,
                fontWeight: fontWeight,
                width: newWidth,
            }}
            onClick={(e) => { doSomething(e) }}
            onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = hoverColor;
                e.currentTarget.style.color = hoverTextColor;
                e.currentTarget.style.boxShadow = `4px 4px 0px ${primaryColor}`;
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = primaryColor;
                e.currentTarget.style.color = textColor;
                e.currentTarget.style.boxShadow = 'none';
            }}>
                <span>{text}</span>
                {icon && <Icon icon={icon} className={styles['button-icon']} />}
        </button>
    );
};

export default PrimaryButton;