import { PriceCardProps } from "interface/PriceCardProps";

/**
 * goldPricePlan object defines the properties and features of the gold subscription plan.
 * It includes pricing details, a list of features, and a click handler for the subscription button.
 * 
 * @constant
 * 
 * @type {PriceCardProps}
 * 
 * @example
 * console.log(goldPricePlan.moreInfo);
 * // Output: "10 Data Pioneer Seats"
 * 
 * @see PriceCardProps - Interface representing the structure of a price plan.
 */
export const goldPricePlan : PriceCardProps = {
    subscriptionType: 'gold',
    moreInfo: '10 Data Pioneer Seats',
    price: '399.5',
    nonAfricanPrice: '799',
    subscriptionTerm: 'per month',
    features: ['Access AI-Powered Workspace', '7 Workspace projects per user per month', 'Access to Premium (Basic + all premium) Reports', 'On-demand training.'],
    onButtonClick() {
        console.log('Gold plan clicked')
    },
}