import React from 'react'
import styles from 'styles/components/SectionHeader.module.sass'

interface SectionHeaderProps {
    title?: string;
    subtitle?: string;
}

/**
 * SectionHeader component renders a section title and optional subtitle 
 * in a styled wrapper. Primarily used for visually distinct section headers.
 *
 * @component
 * @param {SectionHeaderProps} props - The properties for the SectionHeader component.
 * @param {string} [props.title] - The main title of the section (optional).
 * @param {string} [props.subtitle] - The subtitle of the section (optional).
 *
 * @example
 * <SectionHeader title="Our Services" subtitle="What we offer" />
 *
 * @returns {JSX.Element} The JSX for the SectionHeader component.
 */
const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subtitle }) => {
    return (
        <div className={styles['title-wrapper']}>
            {title && (
                <h2 className={styles['section-title']}>{title}</h2>
            )}
            {subtitle && (
                <h2 className={styles['section-subtitle']}>{subtitle}</h2>
            )}
        </div>
    )
}

export default SectionHeader;