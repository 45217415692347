import { Author } from "interface/Author";

/**
 * DebbieOppongAsumang is an object representing the author Debbie Oppong-Asumang with her professional details.
 * 
 * @constant {Author}
 * 
 * @property {string} name - The full name of the author.
 * @property {string} imageUrl - The URL of the author's LinkedIn profile image.
 * @property {string} role - The professional role or title of the author.
 * @property {string} href - A hyperlink to the author's LinkedIn profile.
 * 
 * @example
 * import { DebbieOppongAsumang } from "path/to/author";
 * 
 * console.log(DebbieOppongAsumang.name); // Outputs: "Debbie Oppong-Asumang"
 * console.log(DebbieOppongAsumang.href); // Outputs: "https://www.linkedin.com/in/debbie-oppong-asumang-a4370a104/"
 */
export const DebbieOppongAsumang: Author = {
    name: "Debbie Oppong-Asumang",
    imageUrl: "https://media.licdn.com/dms/image/v2/D5603AQHg19kdhjfJfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711244833433?e=1737590400&v=beta&t=X-xBs_ZziDVhUk3o8qnhWpICSVovyRYgSpecJWdc5s0",
    role: "Chief Operating Officer, Zerone Analytiqs",
    href: "https://www.linkedin.com/in/debbie-oppong-asumang-a4370a104/"
}