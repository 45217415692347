import { BlogContent } from "interface/BlogContent";
import { fetchApiData } from "../ApiHelper";
import { get } from "../ApiClient";
import EndpointProps from "interface/EndpointProps";
import FetchDataByIDProps from "interface/FetchDataByIDProps";

/**
 * Fetches a list of blog content from the server.
 * 
 * This function performs an API call to retrieve blog content using the provided tag and
 * processes the API response using the `fetchApiData` utility.
 * 
 * @async
 * @function getBlogs
 * @param {EndpointProps} params - The request parameters containing a unique tag for identifying the request.
 * @returns {Promise<{ data: BlogContent[]; error: string | null }>} - A promise that resolves to an object containing the array of `BlogContent` and any error message.
 * 
 * @example
 * // Example usage:
 * async function loadBlogs() {
 *   const result = await getBlogs({ tag: 'BlogFetch' });
 *   if (result.error) {
 *     console.error('Error fetching blogs:', result.error);
 *   } else {
 *     console.log('Fetched blogs:', result.data);
 *   }
 * }
 * {
 *   data: [
 *       {
 *           id: 1,
 *           title: "Understanding TypeScript",
 *           content: "This is an introductory blog on TypeScript.",
 *           author: "Jane Doe",
 *           publishedDate: "2024-01-01"
 *       },
 *       {
 *           id: 2,
 *           title: "React Best Practices",
 *           content: "Learn about best practices for building React apps.",
 *           author: "John Smith",
 *           publishedDate: "2024-02-15"
 *       }
 *   ],
 *   error: null
 * }
 */
export async function getBlogs({ tag }: EndpointProps): Promise<{ data: BlogContent[]; error: string | null }> {
    return fetchApiData<{ blogs: BlogContent[] }>(tag,
        () => get<{ blogs: BlogContent[] }>({ tag, url: '/blogs' }),
        (data) => data.blogs || []
    )
}

/**
 * Fetches the hot topic blog content from the server.
 * 
 * This function performs an API call to retrieve the hot topic blog post.
 * and processes the response using the `fetchApiData` utility.
 * 
 * @async
 * @function getHotTopic
 * @param {EndpointProps} params - The request parameters containing a unique tag for identifying the file making the request.
 * @returns {Promise<{ data: BlogContent | null; error: string | null }>} - A promise that resolves to an object containing the `BlogContent` or null if not found, and any error message.
 * 
 * @example
 * // Example usage:
 * async function loadHotTopic() {
 *   const result = await getHotTopic({ tag: 'HotTopicFetch' });
 *   if (result.error) {
 *     console.error('Error fetching hot topic:', result.error);
 *   } else {
 *     console.log('Fetched hot topic:', result.data);
 *   }
 * }
 */
export async function getHotTopic({ tag }: EndpointProps): Promise<{ data: BlogContent | null; error: string | null }> {
    return fetchApiData<{ blog: BlogContent }>(tag,
        () => get<{ blog: BlogContent }>({ tag, url: '/hot-trend' }),
            (data) => data.blog || null
    )
}

/**
 * Fetches a specific blog post from the server using the provided ID.
 * 
 * This function performs an API call to retrieve a blog post based on the given parameters
 * and processes the API response using the `fetchApiData` utility.
 * 
 * @async
 * @function getBlogPost
 * @param {FetchBlogPostProps} params - The request parameters containing the tag and unique blog post ID.
 * @returns {Promise<{ data: BlogContent | null; error: string | null }>} - A promise that resolves to an object containing the `BlogContent` or null if not found, and any error message.
 * 
 * @example
 * // Example usage:
 * async function loadBlogPost() {
 *   const result = await getBlogPost({ tag: 'BlogPostFetch', id: '123' });
 *   if (result.error) {
 *     console.error('Error fetching blog post:', result.error);
 *   } else {
 *     console.log('Fetched blog post:', result.data);
 *   }
 * }
 */
export async function getBlogPost({ tag, id }: FetchDataByIDProps): Promise<{ data: BlogContent | null; error: string | null }> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return fetchApiData<{ blog: BlogContent }>(tag,
        () => get<{ blog: BlogContent }>({ tag, url: `tag/${id}` }),
            (data) => data.blog || null
    )
}