/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { logError } from "services/logService/errorLogger"
import { ApiResponse } from "./ApiTypes"

const API_BASE_URL = process.env.REACT_APP_ENVIRONMENT === 'production' 
    ? process.env.REACT_APP_PROD_API_URL 
    : process.env.REACT_APP_DEV_API_URL

const APP_API_KEY = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_PROD_API_KEY
    : process.env.REACT_APP_DEV_API_KEY

/**
 * Fetches a CSRF token from the server.
 * 
 * @async
 * @param {string} TAG - A tag used for logging purposes.
 * @returns {Promise<string>} The CSRF token retrieved from the server or a default token.
 * @throws Will throw an error if the token cannot be fetched.
 * 
 * @example
 * const csrfToken = await getCsrfToken('PricingPage');
 */
export const getCsrfToken = async (TAG: string): Promise<string> => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/csrf-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${APP_API_KEY || ''}`
            }
        })
        
        if (response.ok) {
            const data = await response.json()
            return data.csrfToken || 'CSRF_TOKEN_FROM_STORAGE_OR_API'
        }
        throw new Error('Failed to fetch CSRF Token')
    } catch (error) {
        logError(
            'Error retrieving CSRF Token',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            TAG
        )
        throw error
    }
}

/**
 * Generates default headers for API requests, including CSRF token and API key.
 * 
 * @async
 * @param {string} TAG - A tag used for logging purposes.
 * @returns {Promise<HeadersInit>} The headers to be used for API requests.
 * @throws Will throw an error if headers cannot be generated.
 * 
 * @example
 * const headers = await getDefaultHeaders('PricingPage');
 */
export const getDefaultHeaders = async (TAG: string, signature: string | null): Promise<HeadersInit> => {
    try {
        const csrfToken = await getCsrfToken(TAG);
        
        const defaultHeaders: Record<string, string> = {
            'Content-Type': 'application/json',
            'X-Origin': window.location.origin,
            'X-CSRF-Token': csrfToken,
            'Authorization': `Bearer ${APP_API_KEY || ''}`,
        }

        if (signature && signature !== null) {
            defaultHeaders['X-Signature'] = signature;
        }

        return defaultHeaders
    } catch (error) {
        logError(
            'Error generating headers',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            TAG
        )
        throw error
    }
}

/**
 * Handles the response from a fetch request.
 * 
 * @template T
 * @async
 * @param {string} TAG - A tag used for logging purposes.
 * @param {Response} response - The fetch response object.
 * @returns {Promise<ApiResponse<T>>} The parsed response data and status, or an error response.
 * 
 * @example
 * const apiResponse = await handleFetchResponse('PricingPage', response);
 */
export const handleFetchResponse = async function <T>(TAG: string, response: Response): Promise<ApiResponse<T>> {
    try {
        const jsonResponse: T = await response.json()
        if (!response.ok) {
            const errorMessage = (jsonResponse as any)?.error || response.statusText || 'Unknown error'
            throw new Error(errorMessage)
        }
        return { data: jsonResponse, status: response.status }
    } catch (error) {
        const errorDetails: ApiResponse<T> = {
            data: null,
            status: response.status,
            error: (error as Error).message || 'Unknown error occurred during fetch response handling',
        }
        logError(
            'Error occurred while handling fetch response',
            errorDetails,
            TAG
        )
        return errorDetails
    }
}

/**
 * Handles errors that occur during fetch requests.
 * 
 * @template T
 * @param {string} TAG - A tag used for logging purposes.
 * @param {any} error - The error object thrown during the fetch request.
 * @returns {ApiResponse<T>} An error response containing the status and error message.
 * 
 * @example
 * const errorResponse = handleError('PricingPage', error);
 */
export const handleError = function <T> (TAG: string, error: any): ApiResponse<T> {
    let errorMessage = 'An unexpected error occurred'

    if (error instanceof TypeError) {
        errorMessage = 'Network error or request was aborted'
    } else if (error instanceof Error) {
        errorMessage = error.message
    }

    const errorDetails: ApiResponse<T> = {
        data: undefined as unknown as T,
        status: 500,
        error: errorMessage,
    };

    logError(
        'Error occurred while handling fetch response',
        errorDetails,
        TAG
    )
    return errorDetails;
}