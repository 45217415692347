import React, { useEffect } from 'react'
import styles from 'styles/layout/FooterMain.module.sass'
import zeroneLogo from 'assets/images/svg/logo-dark.svg'
import xLogo from 'assets/images/social/x-logo.svg'
import instagramLogo from 'assets/images/social/instagram.svg'
import youtubeLogo from 'assets/images/social/youtube.svg'
import linkedInLogo from 'assets/images/social/linkedin.svg'
import { Link } from 'interface/Link'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

/**
 * Represents an item in the footer with a title and associated links.
 * @typedef {Object} LinkItem
 * @property {string} title - The title of the link section.
 * @property {Link[]} links - An array of links under the section.
 */
interface LinkItem {
    title: string;
    links: Link[]
}

/**
 * Represents a social media link with a title, icon, and href.
 * @typedef {Object} SocialLink
 * @property {string} title - The name of the social media platform.
 * @property {string} icon - The path to the social media platform's icon.
 * @property {string} href - The URL of the social media profile.
 */
interface SocialLink {
    title: string;
    icon: string;
    href: string;
}

/**
 * Array of footer link items, containing sections with titles and associated links.
 * @constant {LinkItem[]}
 */
const footerLinkItems: LinkItem[] = [
    {
        title: "Zerone",
        links: [
            {
                text: "About Us",
                href: "/about-us"
            },
            {
                text: "Our Team",
                href: "#"
            },
            {
                text: "Policies",
                href: "#"
            }
        ]
    },
    {
        title: "Privacy",
        links: [
            {
                text: "Terms & Conditions",
                href: "/terms"
            },
            {
                text: "Legal",
                href: "/legal"
            },
            {
                text: "Data Privacy",
                href: "/privacy"
            }
        ]
    },
    {
        title: "Resources",
        links: [
            {
                text: "Blog",
                href: "#"
            },
            {
                text: "Best practices",
                href: "#"
            },
            {
                text: "Resource library",
                href: "#"
            }
        ]
    }
]

/**
 * Array of social media links, each containing a title, icon, and URL.
 * @constant {SocialLink[]}
 */
const socialLinks: SocialLink[] = [
    {
        title: "X",
        icon: xLogo,
        href: "https://x.com/zeroneanalytiqs"
    },
    {
        title: "Instagram",
        icon: instagramLogo,
        href: "https://www.instagram.com/zerone_analytiqs/"
    },
    {
        title: "YouTube",
        icon: youtubeLogo,
        href: "https://www.youtube.com/@ZeroneAnalytiqsLLC"
    },
    {
        title: "LinkedIn",
        icon: linkedInLogo,
        href: "https://www.linkedin.com/company/zerone-analytiqs/"
    }
];

/**
 * FooterMain component renders the footer section displaying company information, footer links, and social media links.
 *
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import FooterMain from 'components/layout/footer/FooterMain';
 * 
 * const App: React.FC = () => (
 *   <div>
 *     <FooterMain />
 *   </div>
 * );
 * 
 * export default App;
 * ```
 * 
 * @returns {JSX.Element} The JSX element displaying the footer section.
 */
const FooterMain: React.FC = () => {
    const navigate = useNavigate()
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function handleLogoClick() {
        navigate('/')
    }
    
    return (
        <footer className={styles['footer-main']}>
            <div className={styles['title-section']}>
                <img src={zeroneLogo} className={styles.logo} onClick={handleLogoClick}/>
                <div className={styles['fuelling-dev-wrapper']}>
                    <p>Empowering decisions with data.</p>
                </div>
            </div>
            <div className={styles['text-links-section']}>
                {footerLinkItems.map((item) => (
                    <div
                        key={item.title}
                        className={styles['text-links-item']}
                        aria-label={item.title}>
                            <p className={styles['item-title']}>{item.title}</p>
                            {item.links.map((link) => (
                                <RouterLink
                                    to={link.href}  
                                    key={link.text}
                                    className={styles['link']}>
                                        {link.text}
                                </RouterLink>
                            ))}
                    </div>
                ))}
            </div>
            <div className={styles['copyright-socials-section']}>
                <p className={styles['copyright']}>
                    Zerone Analytiqs © {new Date().getFullYear()}
                </p>
                <div className={styles['socials']}>
                    {socialLinks.map((social) => (
                        <div 
                            key={social.title} 
                            className={styles['social-icon']} 
                            style={{backgroundImage: `url(${social.icon})`}} 
                            aria-label={`Follow us on: ${social.title}`}>
                                <a 
                                    href={social.href} 
                                    target='_blank' 
                                    rel='noopener noreferrer' 
                                    aria-label={`Follow us on: ${social.title}`}>
                                </a>
                        </div>
                    ))}
                </div>
            </div>
        </footer>
    );
}

export default FooterMain;