import FeaturesSection from 'layouts/section/FeaturesSection'
import FooterSection from 'layouts/section/FooterSection'
import HeaderSection from 'layouts/section/HeaderSection'
import PricingSection from 'layouts/section/PricingSection'
import TestimonialsSection from 'layouts/section/TestimonialsSection'
import TrustedBySection from 'layouts/section/TrustedBySection'
import React from 'react'
import styles from 'styles/pages/LandingPage.module.sass'

/**
 * LandingPage component organizes and displays the main sections of the landing page, including the header, trusted-by section, features, pricing, testimonials, and footer.
 * 
 * @component
 * 
 * @example
 * // Renders the LandingPage with all its sections
 * <LandingPage />
 * 
 * @see HeaderSection - Displays the header and navigation.
 * @see TrustedBySection - Shows logos of trusted partners or clients.
 * @see FeaturesSection - Highlights key features of the product or service.
 * @see PricingSection - Lists available pricing plans.
 * @see TestimonialsSection - Displays testimonials from customers.
 * @see FooterSection - Displays footer content and links.
 */
const LandingPage: React.FC = () => {
    return (
        <div className={styles['landing-page']}>
            <HeaderSection />
            <TrustedBySection />
            <span className={styles['section-line']}/>
            <FeaturesSection />
            <span className={styles['section-line']}/>
            <PricingSection />
            <TestimonialsSection />
            <FooterSection />
        </div>
    )
}

export default LandingPage;