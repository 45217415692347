import React, { useState } from 'react';
import styles from 'styles/components/AccountTypeForm.module.sass';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from 'components/common/SecondaryButton';
import PrimaryButton from 'components/common/PrimaryButton';

/**
 * AccountTypeForm component allows users to choose an account type during the sign-up process.
 * 
 * @component
 * 
 * @example
 * // Renders the AccountTypeForm component
 * <AccountTypeForm />
 * 
 * @see PrimaryButton - Button component for the "Next" action
 * @see SecondaryButton - Button component for the "Login" action
 * 
 * @description
 * This form presents users with different account types to choose from:
 * - Free: Basic access to blogs and trending reports
 * - Basic and Sapphire: Mid-tier options with additional features
 * - Gold and Platinum: Premium options with full access to services
 * 
 * Users select an account type via radio buttons. The form includes a "Next" button to proceed
 * with the selected account type and a "Login" option for existing users.
 * The component uses a responsive layout to display options in rows on larger screens
 * and in a single column on smaller screens.
 */

const accountTypes = [
  [{ name: 'Free', color: 'black', description: 'This gives you access to blogs and free trending reports. You can use this account to get a sneak peek into our offerings until you’re ready'
 }],
  [
    { name: 'Basic', color: '#216F97', description: 'Take your team\'s analytics to the next level with Zerone Sapphire! Enjoy full access to our AI-powered workspace, up to 5 seats, 3 projects per user/month, 3 premium report credits, and a complimentary custom dataset annually. Perfect for small teams and businesses, Sapphire includes on-demand training to maximize your AI-driven insights.' },
    { name: 'Sapphire', color: '#003FD1', description: 'Take your team\'s analytics to the next level with Zerone Sapphire! Enjoy full access to our AI-powered workspace, up to 5 seats, 3 projects per user/month, 3 premium report credits, and a complimentary custom dataset annually. Perfect for small teams and businesses, Sapphire includes on-demand training to maximize your AI-driven insights.' }
  ],
  [
    { name: 'Gold', color: '#E88B00', description: 'Empower your business with Zerone Gold! Tailored for businesses and corporations, this plan offers full access to our AI-powered workspace, up to 10 seats, 7 projects per user/month, and 7 premium or customized reports. Plus, enjoy quarterly custom datasets and semi-annual training to unlock the full potential of your analytics. Elevate your Enterprise decision-making and drive success with Zerone Gold!' },
    { name: 'Platinum', color: '#BCBCBC', description: 'Achieve unparalleled analytics power with Zerone Platinum! Designed for large-scale users and custom projects, this plan offers unlimited seats and projects, full access to all reports (Basic, Premium, and Exclusive), and monthly custom data collection, analysis, and reporting. Platinum ensures you’re equipped for limitless insights and growth. Includes training to keep your team ahead. Save 10% with annual prepayment—elevate your analytics today! ' }
  ]
];

const AccountTypeForm: React.FC = () => {
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const navigate = useNavigate();

  const handleNextClicked = (event: React.FormEvent) => {
    event.preventDefault();
    console.log('Next button clicked, selected account:', selectedAccount);
    // Add navigation or form submission logic here
  };

  const handleLoginClicked = () => {
    navigate('/login');
  };

  return (
    <form className={styles['account-form']}>
      <h2 className={styles['form-title']}>Choose Account Type</h2>
      
      <div className={styles['account-options']}>
        {accountTypes.map((row, rowIndex) => (
          <div key={rowIndex} className={styles['account-row']}>
            {row.map((account, accountIndex) => (
              <div key={accountIndex} className={styles['account-option']}>
                <label className={styles['radio-label']}>
                  <input
                    type="radio"
                    name="accountType"
                    value={account.name}
                    checked={selectedAccount === account.name}
                    onChange={(e) => setSelectedAccount(e.target.value)}
                    className={styles['radio-input']}
                  />
                  <span 
                    className={styles['radio-text']}
                    style={{ color: account.color }}
                  >
                    {account.name}
                  </span>
                </label>
                <p className={styles['account-description']}>{account.description}</p>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className={styles['action-buttons']}>
        <PrimaryButton
          text='Next'
          width='auto'
          primaryColor='#FF5522'
          textColor='#FFFFFF'
          hoverColor='#FFFFFF'
          hoverTextColor='#FF5522'
          doSomething={(event: React.FormEvent) => handleNextClicked(event)}
        />
        <SecondaryButton
          text="Have an account? Login"
          width='auto'
          primaryColor="transparent"
          textColor="#ff5522"
          hoverColor="#ff5522"
          hoverTextColor="#FFFFFF"
          doSomething={handleLoginClicked}
        />
      </div>
    </form>
  );
};

export default AccountTypeForm;
