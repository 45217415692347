import { ReportContent } from 'interface/ReportContent'
import React from 'react'
import styles from 'styles/layout/ReportHeaderSection.module.sass'
import HeaderNavigation from '../header/HeaderNavigation';
import ReportSummary from 'components/common/ReportSummary';

interface ReportHeaderSectionProps {
    report: ReportContent | null;
}

/**
 * ReportHeaderSection component - Displays the header section for a report.
 *
 * This component includes a navigation header (`HeaderNavigation`) and a summary
 * of the report (`ReportSummary`). The header is styled for a dark theme, and
 * the summary is rendered only if a valid report is passed as a prop.
 *
 * @component
 * @param {ReportHeaderSectionProps} props - The properties for the ReportHeaderSection component.
 * @param {ReportContent | null} props.report - The report data to be displayed in the header section.
 *
 * @example
 * // Example usage:
 * <ReportHeaderSection report={reportData} />
 *
 * @returns {JSX.Element} The rendered ReportHeaderSection component.
 */
const ReportHeaderSection: React.FC<ReportHeaderSectionProps> = ({ report }) => {
    return (
        <section className={styles['report-header-section']}>
            <HeaderNavigation theme='dark' />

            {report && (
                <ReportSummary report={report} />
            )}
        </section>
    )
}

export default ReportHeaderSection;