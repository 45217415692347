import { fetchReport } from 'api/ReportApiService'
import FooterMain from 'components/layout/footer/FooterMain'
import ReportHeaderSection from 'components/layout/report/ReportHeaderSection'
import { ReportContent } from 'interface/ReportContent'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from 'styles/pages/ReportPage.module.sass'

const TAG = 'ReportPage'

/**
 * ReportPage component - A page layout for displaying report details.
 *
 * This component fetches a report based on the `id` extracted from the URL parameters
 * and displays the report details using the `ReportHeaderSection` component. It also includes
 * a footer section (`FooterMain`) for consistent layout.
 *
 * @component
 * @example
 * // Example usage:
 * <ReportPage />
 *
 * @returns {JSX.Element} The rendered ReportPage component.
 */
const ReportPage: React.FC = () => {
    const [report, setReport] = useState<ReportContent | null>(null)
    const { id } = useParams<{ id: UUID }>()
    
    useEffect(() => {
        const loadReport = async () => {
            if (id) {
                try {
                    const fetchedReprot = await fetchReport({ tag: TAG, id })
                    setReport(fetchedReprot)
                } catch {
                    setReport(null)
                }
            }
        }

        loadReport()
    }, [])
    console.log(report)

    return (
        <div className={styles['report-page']}>
            <ReportHeaderSection report={report} />
            <FooterMain />
        </div>
    )
}

export default ReportPage;