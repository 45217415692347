/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from 'styles/layout/DataPrivacySection.module.sass';

const DataPrivacySection: React.FC = () => {
  return (
    <section className={styles.dataPrivacySection}>
      <div className={styles.header}>
        <h1 className={styles.title}>Privacy Policy</h1>
        <p className={styles.lastModified}>
          <strong>Last Modified:</strong> 4th November 2024
        </p>
      </div>

      <div className={styles.content}>
        <p>
          At Zerone AnalytiQs, your privacy is critically important to us. This Data Privacy Policy
          outlines how we collect, use, and safeguard your personal information when you visit our
          website (https://zeroneanalytiqs.com) and use our mobile application,{' '}
          <strong>ZeroneData</strong>, available on Android and iOS. This policy also explains your
          rights in relation to your data.
        </p>

        <h2 className={styles.subtitle}>1. Introduction</h2>
        <p>
          Zerone AnalytiQs ("we," "our," or "us") is committed to protecting your personal
          information and ensuring transparency about how your data is collected and used. This
          policy applies to our website, ZeroneData mobile application, and any associated services
          or platforms. By using our services, you agree to the terms of this policy.
        </p>
        <p>
          If you have any questions or concerns regarding this Privacy Policy, please contact us
          using the information provided in Section 13.
        </p>

        <h2 className={styles.subtitle}>2. Information We Collect</h2>
        <p>
          We collect various types of information to provide and improve our services, including:
        </p>
        <h3>a. Personal Information</h3>
        <p>We may collect personally identifiable information (PII) such as:</p>
        <ul>
          <li>Full name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Mailing address</li>
          <li>Payment details (for premium services)</li>
        </ul>
        <h3>b. Non-Personal Information</h3>
        <p>We also collect non-personally identifiable information, including:</p>
        <ul>
          <li>Browser type</li>
          <li>Device type and model</li>
          <li>IP address</li>
          <li>Operating system</li>
          <li>Location data (if permitted by your device)</li>
          <li>
            Usage statistics, including pages viewed, links clicked, and time spent on our website
            or app.
          </li>
        </ul>
        <h3>c. Data From Mobile Applications</h3>
        <p>When you use the ZeroneData app, we may collect:</p>
        <ul>
          <li>Device identifiers (such as UDID, IMEI)</li>
          <li>App interaction data (session duration, pages viewed)</li>
          <li>GPS location (if permitted)</li>
          <li>Data on app crashes and diagnostics</li>
        </ul>
        <h3>d. Cookies and Tracking Technologies</h3>
        <p>
          Our website uses cookies and similar tracking technologies to enhance user experience,
          including:
        </p>
        <ul>
          <li>Session cookies for website navigation</li>
          <li>Analytics cookies for performance insights</li>
          <li>Third-party cookies for advertisements</li>
        </ul>
        <p>You can manage cookie preferences in your browser settings.</p>

        <h2 className={styles.subtitle}>3. How We Use Your Information</h2>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Provide, maintain, and improve our services</li>
          <li>Personalize user experiences and content</li>
          <li>Respond to your inquiries or provide customer support</li>
          <li>Process transactions and send related information (e.g., confirmations, receipts)</li>
          <li>Conduct research and analysis to enhance our services</li>
          <li>Send updates, promotional materials, and notifications (with opt-out options)</li>
          <li>Comply with legal obligations and regulatory requirements</li>
        </ul>
        <p>
          In the case of data collected through the ZeroneData app, we may use it to generate market
          insights, trends, and reports, but this data is anonymized before being shared with third
          parties.
        </p>

        <h2 className={styles.subtitle}>4. Sharing Your Information</h2>
        <p>
          We value your privacy and do not sell or rent your personal data to third parties.
          However, we may share your data in the following circumstances:
        </p>
        <ul>
          <li>
            <strong>With Service Providers:</strong> We may share data with third-party service
            providers who assist in operating our website, mobile app, and services (e.g., payment
            processors, cloud storage providers).
          </li>
          <li>
            <strong>For Legal Purposes:</strong> We may disclose your information if required to do
            so by law or in response to a legal request, such as a subpoena, court order, or
            government demand.
          </li>
          <li>
            <strong>Business Transfers:</strong> If Zerone AnalytiQs undergoes a business
            transaction such as a merger, acquisition, or asset sale, your data may be transferred
            as part of the transaction.
          </li>
        </ul>

        <h2 className={styles.subtitle}>5. Data Storage and Retention</h2>
        <h3>a. Data Storage Locations</h3>
        <p>
          We store and process your data using secure servers hosted by reputable third-party
          service providers. Your data may be transferred to, stored, or processed in locations
          outside your country of residence, including the United States or the European Union. We
          take steps to ensure that any such transfers comply with applicable data protection laws.
        </p>
        <h3>b. Data Retention Periods</h3>
        <p>
          We retain your personal information for as long as necessary to fulfill the purposes
          outlined in this policy, unless a longer retention period is required by law. When
          personal data is no longer needed, we securely delete or anonymize it.
        </p>

        <h2 className={styles.subtitle}>6. Data Sharing and Disclosure</h2>
        <p>
          We may share non-personal, aggregated, and anonymized data with partners and third parties
          for the purposes of improving our services, generating market insights, or conducting
          research. In such cases, we ensure that no personally identifiable information is included
          in the shared data.
        </p>

        <h2 className={styles.subtitle}>7. Your Rights and Choices</h2>
        <p>
          You have certain rights concerning your personal data. Depending on your location, these
          rights may include:
        </p>
        <ul>
          <li><strong>Access:</strong> You can request access to the personal data we hold about you.</li>
          <li>
          <strong>Correction:</strong> You can request correction of inaccurate or incomplete personal information.
          </li>
          <li>
          <strong>Deletion:</strong> You can request the deletion of your personal information, subject to certain
            legal restrictions.
          </li>
          <li>
          <strong>Portability:</strong> You can request a copy of your data in a commonly used electronic format.
          </li>
          <li>
          <strong>Consent Withdrawal:</strong> Where we rely on your consent to process your data, you can withdraw
            that consent at any time.
          </li>
        </ul>
        <p>
          To exercise any of these rights, please contact us using the information in Section 13.
        </p>

        <h2 className={styles.subtitle}>8. Security Measures</h2>
        <p>
          We implement robust technical, organizational, and physical measures to safeguard your
          personal information from unauthorized access, use, alteration, or disclosure. These
          measures include:
        </p>
        <ul>
          <li>Data encryption (both in transit and at rest)</li>
          <li>Secure server infrastructure</li>
          <li>Access control measures for our employees and partners</li>
          <li>Regular security audits and vulnerability assessments</li>
        </ul>
        <p>
          However, no method of data transmission over the Internet or electronic storage is 100%
          secure. While we strive to protect your personal information, we cannot guarantee its
          absolute security.
        </p>

        <h2 className={styles.subtitle}>9. Third-Party Services</h2>
        <p>
          Our website and ZeroneData app may contain links to third-party websites or services that
          are not owned or controlled by Zerone AnalytiQs. We are not responsible for the privacy
          practices of these third parties. We encourage you to review the privacy policies of any
          third-party services you interact with.
        </p>

        <h2 className={styles.subtitle}>10. Do Not Track</h2>
        <p>
          Some web browsers offer a "Do Not Track" feature, allowing you to signal your preference
          regarding tracking. At this time, Zerone AnalytiQs does not respond to "Do Not Track"
          signals. We continue to review and evaluate potential support for "Do Not Track"
          technology.
        </p>

        <h2 className={styles.subtitle}>11. Minors</h2>
        <p>
          Our services are not intended for individuals under the age of 18. We do not knowingly
          collect or solicit personal data from minors. If we become aware that we have collected
          personal information from a minor without appropriate consent, we will promptly take steps
          to delete such information.
        </p>

        <h2 className={styles.subtitle}>12. Changes to This Privacy Policy</h2>
        <p>
          Zerone AnalytiQs may update this privacy policy periodically to reflect changes in our
          practices, legal requirements, or operational needs. We will notify you of any significant
          changes through email or by posting a notice on our website or within the ZeroneData app.
          Your continued use of our services after such changes indicates your acceptance of the
          updated policy.
        </p>

        <h2 className={styles.subtitle}>13. Contact Us</h2>
        <div className={styles.contactSection}>
          <p>
            If you have any questions or concerns about this Privacy Policy or how your data is
            handled, please contact us at:{' '}
            <a href="https://zeroneanalytiqs.com/contact-us.html" className={styles.link}>
              https://zeroneanalytiqs.com/contact-us.html
            </a>
          </p>

          <div>
            <div className={styles.officeInfo}>
              <h4>Zerone AnalytiQs</h4>
              <h4>Head quarters:</h4>
              <address>
                1200 – 900 West Hastings Street
                <br />
                Vancouver, BC V6C 1E5
              </address>
            </div>

            <div className={styles.officeInfo}>
              <h4>Ghana Office (Operations):</h4>
              <address>
                18 South Ofankor
                <br />
                Ga West, Accra
                <br />
                GW 737 3350
              </address>
            </div>

            <div>
              <p>
                Email:{' '}
                <a href="mailto:legal@zeroneanalytiqs.com" className={styles.link}>
                  legal@zeroneanalytiqs.com
                </a>
              </p>
              <p>
                Phone:{' '}
                <a href="tel:+233538855417" className={styles.link}>
                  +233 538855417
                </a>
              </p>
            </div>
          </div>

          <p className={styles.thankYouNote}>
            Thank you for trusting Zerone AnalytiQs with your data.
          </p>
        </div>
      </div>
    </section>
  );
};

export default DataPrivacySection;
