import { fetchBlogs } from 'api/BlogApiService';
import BlogCard from 'components/cards/BlogCard';
import { BlogContent } from 'interface/BlogContent';
import React, { useEffect, useState } from 'react'
import styles from 'styles/components/BlogsContainer.module.sass'

/**
 * Interface for BlogsContainerProps defining the properties accepted by the BlogsContainer component.
 * 
 * @interface BlogsContainerProps
 * 
 * @property {string} activeTab - The currently active tab to filter displayed blogs.
 * @property {string} query - The search query string to filter displayed blogs.
 */
interface BlogsContainerProps {
    activeTab: string;
    query: string;
}

const TAG = 'BlogsContainer'

/**
 * BlogsContainer component fetches and displays a list of blogs, filtered by the active tab and search query.
 * 
 * @component
 * 
 * @param {BlogsContainerProps} props - Props for configuring the BlogsContainer component.
 * @param {string} props.activeTab - The currently active tab to filter the displayed blogs.
 * @param {string} props.query - The search query string to filter displayed blogs.
 * 
 * @example
 * // Example usage of BlogsContainer component
 * <BlogsContainer
 *   activeTab="All"
 *   query="technology"
 * />
 */
const BlogsContainer: React.FC<BlogsContainerProps> = ({ activeTab, query }) => {
    const [blogs, setBlogs] = useState<BlogContent[]>([])
    const [filteredBlogs, setFilteredBlogs] = useState<BlogContent[]>([])

    useEffect(() => {
        const loadBlogs = async () => {
            try {
                const fetchedBlogs = await fetchBlogs(TAG)
                setBlogs(fetchedBlogs)
            } catch {
                setBlogs([])
            }
        }

        loadBlogs()
    }, [])

    useEffect(() => {
        let filtered = blogs

        if (activeTab !== 'All' && activeTab !== 'Trending') {
            filtered = filtered.filter(blog => blog.category === activeTab)
        }

        if (activeTab === 'Trending') {
            filtered = filtered.filter(blog => blog?.trending)
        }

        if (query.trim() !== '') {
            const lowerCaseQuery = query.toLocaleLowerCase();
            filtered = filtered.filter(blog => {
                // Check if the query matches the title
                const matchesTitle = blog.title.toLowerCase().includes(lowerCaseQuery);
                
                // Check if the query matches the catchyPhrase
                const matchesCatchyPhrase = blog.catchyPhrase && blog.catchyPhrase.toLowerCase().includes(lowerCaseQuery);
                
                // Check if the query matches any paragraph text
                const matchesParagraphs = blog.paragraphs && blog.paragraphs.some(paragraph => 
                    paragraph.text.toLowerCase().includes(lowerCaseQuery)
                );

                return matchesTitle || matchesCatchyPhrase || matchesParagraphs;
            });
        }

        setFilteredBlogs(filtered)
    }, [activeTab, query, blogs])

    return (
        <div className={styles['blogs-container']}>
            {filteredBlogs && filteredBlogs.map((blog) => (
                <BlogCard
                    key={blog.id} 
                    blog={blog} 
                />
            ))}
        </div>
    )
}

export default BlogsContainer;