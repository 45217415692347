import React, { useState } from 'react'
import styles from 'styles/layout/BlogsSection.module.sass'
import BlogContainerNavigation from './BlogContainerNavigation'
import BlogsContainer from './BlogsContainer'

/**
 * BlogsSection component manages and displays the blog section with tab-based navigation and search functionality.
 * 
 * @component
 * 
 * @example
 * // Example usage of BlogsSection component
 * <BlogsSection />
 */
const BlogsSection: React.FC = () => {
    const [activeTab, setActiveTab] = useState('All')
    const [query, setQuery] = useState('')

    const blogTabs = ['All', 'Trending', 'Markets, Industries & Sectors', 'Products & Consumer Insights', 'Countries & Economies']

    const handleTabChange = (tab: string) => {
        setActiveTab(tab)
    }

    const handleSearch = (query: string) => {
        setQuery(query)
    }
    
    return (
        <section className={styles['blogs-section']}>
            <BlogContainerNavigation 
                tabs={blogTabs} 
                activeTab={activeTab} 
                onTabChange={handleTabChange} 
                handleSearch={handleSearch} 
            />

            <BlogsContainer 
                activeTab={activeTab} 
                query={query} 
            />
            
        </section>
    )
}

export default BlogsSection;