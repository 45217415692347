import React from 'react';
import styles from 'styles/pages/WorkSpacePage.module.sass';
import HeaderNavigation from 'components/layout/header/HeaderNavigation';
import WorkSpaceSection from 'layouts/section/WorkspaceSection'
import SimpleFooter from 'components/layout/footer/SimpleFooter';
import backgroundImage from 'assets/images/png/coming-soon-bg.png'; 

const ComingSoonPage: React.FC = () => {
    return (
        <div className={styles.workSpacePage} style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className={styles.overlay}>
                <HeaderNavigation theme="light" />
                <main className={styles.mainContent}>
                    <WorkSpaceSection />
                </main>
                <SimpleFooter />
            </div>
        </div>
    );
};

export default ComingSoonPage;