import React from 'react'
import styles from 'styles/components/StartupPricing.module.sass'
import { Icon } from '@iconify/react'
import checkIcon from '@iconify/icons-mdi/check-bold'
import { startupPlan } from 'constants/subscription/StartupPricePlan'
import SecondaryButton from 'components/common/SecondaryButton'
import startupImage from 'assets/images/svg/pricing-startup.svg'

/**
 * StartupPricing component presents the pricing plan for startups, highlighting a discount offer and key features.
 * 
 * @component
 * 
 * @example
 * // Renders the StartupPricing component with a discount offer and contact button
 * <StartupPricing />
 * 
 * @see SecondaryButton - Component for rendering the "Contact us" button
 */
const StartupPricing: React.FC = () => {
    const handleButtonClick = () => {
        window.location.href = 'mailto:info@zeroneanalytiqs.com';
    }

    return (
        <div className={styles['startup-container']}>
            <div className='left-wrapper'>
                <p className={styles['heading']}>
                    Get up to 50% discount if you are a<span className={styles['line-break']} /> Startup
                </p>
                <ul className={styles['feature-list']}>
                    {startupPlan.map((feature, index) => (
                        <li key={index} className={styles['item']}>
                            <Icon icon={checkIcon} className={styles['check-icon']} />
                            {feature}
                        </li>
                    ))}
                </ul>
                <SecondaryButton
                    width='auto'
                    text="Contact us"
                    primaryColor="#FFFFFF"
                    textColor="#ff5522"
                    hoverColor="#ff5522"
                    hoverTextColor="#FFFFFF"
                    doSomething={handleButtonClick}
                />
            </div>
            <div className={styles['right-wrapper']}>
                <img src={startupImage} />
            </div>
        </div>
    )
}

export default StartupPricing;