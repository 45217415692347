import React, { useState } from 'react';
import InputField from 'components/common/InputField';
import PrimaryButton from 'components/common/PrimaryButton';
import styles from 'styles/components/ContactForm.module.sass';

const ContactForm: React.FC = () => {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        console.log('Form submitted');
        // Add form submission logic here
    };

    return (
        <form className={styles['contact-form']}>
            <h2 className={styles['form-title']}>Reach out to us</h2>
            <div className={styles['all-input']}>
                <div className={styles['input-row']}>
                    <InputField 
                        label={'First Name'} 
                        type={'text'} 
                        value={firstName} 
                        onChange={setFirstName} 
                        required={true} 
                    />
                    <InputField 
                        label={'Last Name'} 
                        type={'text'} 
                        value={lastName} 
                        onChange={setLastName} 
                        required={true} 
                    />
                </div>

                <div className={styles['input-row']}>
                    <InputField 
                        label={'Email'} 
                        type={'email'} 
                        value={email} 
                        onChange={setEmail} 
                        required={true} 
                    />
                    <InputField 
                        label={'Phone Number'} 
                        type={'tel'} 
                        value={phone} 
                        onChange={setPhone} 
                        required={false} 
                    />
                </div>

                <div className={styles['input-row']}>
                    <InputField
                        label={'How can we help you?'}
                        type={'textarea'}
                        value={message}
                        onChange={setMessage}
                        required={true}

                    />
                </div>
            </div>

            <div className={styles['action-buttons']}>
                <PrimaryButton
                    text='Submit'
                    primaryColor='#FF5522'
                    textColor='#FFFFFF'
                    hoverColor='#FFFFFF'
                    hoverTextColor='#FF5522'
                    doSomething={(event: React.FormEvent) => handleSubmit(event)}
                />
            </div>
        </form>
    );
};

export default ContactForm;