import { fetchCategories } from 'api/ReportApiService'
import ReportCategoriesContentSection from 'components/layout/report/ReportCategoriesContentSection'
import ReportCategoriesHeaderSection from 'components/layout/report/ReportCategoriesHeaderSection'
import { ReportCategory } from 'interface/ReportCategory'
import FooterSection from 'layouts/section/FooterSection'
import React, { useEffect, useState } from 'react'
import styles from 'styles/pages/ReportCategoriesPage.module.sass'

const TAG = 'ReportCategoriesPage'

/**
 * ReportCategoriesPage component - A page layout component for displaying a list of report categories.
 * It fetches the report categories on mount and renders them using header and content sections.
 *
 * @component
 *
 * @example
 * // Example usage:
 * <ReportCategoriesPage />
 *
 * @returns {JSX.Element} The rendered ReportCategoriesPage component.
 */
const ReportCategoriesPage: React.FC = () => {
    const [categories, setCategories] = useState<ReportCategory[]>([])

    useEffect(() => {
        const loadCategories = async () => {
            try {
                const fetchedCategories = await fetchCategories(TAG)
                setCategories(fetchedCategories)
            } catch {
                setCategories([])
            }
        }

        loadCategories()
    }, [])

    return (
        <div className={styles['report-categories-page']}>
            <ReportCategoriesHeaderSection categories={categories} />
            <ReportCategoriesContentSection categories={categories} />
            <FooterSection />
        </div>
    )
}

export default ReportCategoriesPage;