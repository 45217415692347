import React from 'react';
import SectionHeader from 'components/common/SectionHeader';
import ContactForm from 'components/forms/ContactForm';
import styles from 'styles/pages/ContactUsPage.module.sass';
import FooterMain from 'components/layout/footer/FooterMain';
import HeaderNavigation from 'components/layout/header/HeaderNavigation';
import ourReachImage from 'assets/images/png/our-reach.png';

/**
 * ContactUsPage component renders a page with a section header, an image, and a contact form.
 *
 * @component
 * @example
 * Example usage:
 *
 * ```tsx
 * import ContactUsPage from 'pages/ContactUsPage';
 *
 * const App: React.FC = () => (
 *   <ContactUsPage />
 * );
 *
 * export default App;
 * ```
 *
 * @returns {JSX.Element} The JSX element displaying the contact us page.
 */
const ContactUsPage: React.FC = () => {
  return (
    <div>
      <HeaderNavigation theme="dark" />
      <div className={styles['contact-us-page']}>
        <div className={styles['contact-section-header']}>
          <SectionHeader title="Contact Us" subtitle="Feel Free. Speak to us." />
        </div>
        <p className={styles['intro-text']}>
          Need technical support? Want to talk to us about business?
          <br />
          Let us know and we will respond to you shortly.
        </p>
        <div className={styles['contact-content']}>
          <div className={styles['image-container']}>
            <img src={ourReachImage} alt="Our Reach" className={styles['our-reach-image']} />
          </div>
          <ContactForm />
        </div>
      </div>
      <FooterMain />
    </div>
  );
};

export default ContactUsPage;
