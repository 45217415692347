import ReportCarouselCard from 'components/cards/ReportCarouselCard'
import Tracker from 'components/common/Tracker'
import { CategoryTopic } from 'interface/CategoryTopic'
import React, { useEffect, useState } from 'react'
import styles from 'styles/components/ReportCategoryCarousel.module.sass'

interface ReportCategoryCarouselProps {
    reports: CategoryTopic[]
}

/**
 * A carousel component that displays an array of reports as cards.
 * The active card is shown in the center, with one card partially visible on the left
 * and another on the right. Clicking on a card shifts the active card in the
 * respective direction, creating a seamless flow of cards.
 *
 * This component adapts to screen size. On smaller screens, only one card is displayed at a time
 * with auto-rotation every 3 seconds. On larger screens, it displays three cards.
 *
 * @component
 * @example
 * Example usage:
 *
 * ```tsx
 * import ReportCategoryCarousel from 'components/ReportCategoryCarousel';
 *
 * const reports = [
 *   { id: '1', title: '2024 Elections in Ghana', description: 'Political report', dataset: 'Politics' },
 *   { id: '2', title: 'Beverages in Ghana', description: 'Market report', dataset: 'Drinks & Beverages' },
 *   { id: '3', title: 'Social Media Trends', description: 'Analysis of trends', dataset: 'Social Media' }
 * ];
 *
 * const App: React.FC = () => (
 *   <ReportCategoryCarousel reports={reports} />
 * );
 *
 * export default App;
 * ```
 *
 * @param {ReportCategoryCarouselProps} props - The properties for the ReportCategoryCarousel component.
 * @param {CategoryTopic[]} props.reports - An array of reports to display in the carousel.
 *
 * @returns {JSX.Element} The rendered carousel component.
 */
const ReportCategoryCarousel: React.FC<ReportCategoryCarouselProps> = ({ reports }) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 768);

    /**
     * Effect to update `isSmallScreen` based on the window size.
     * Adds a resize event listener to dynamically adjust the layout.
     */
    useEffect(() => {
        const updateScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 1024);
        };

        updateScreenSize();
        window.addEventListener('resize', updateScreenSize);
        
        return () => window.removeEventListener('resize', updateScreenSize);
    }, []);

    /**
     * Increments the `activeIndex` to show the next card in the carousel.
     * Wraps around to the first card after reaching the last card.
     */
    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % reports.length);
    };

    /**
     * Decrements the `activeIndex` to show the previous card in the carousel.
     * Wraps around to the last card if the current index is the first card.
     */
    const handlePrevious = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + reports.length) % reports.length);
    };

    /**
     * Sets up an auto-rotation interval to switch cards every 3 seconds on small screens.
     * Clears the interval on larger screens or when the component unmounts.
     */
    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;

        if (isSmallScreen) {
            interval = setInterval(() => {
                handleNext();
            }, 3000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isSmallScreen, reports.length]);

    /**
     * Determines the visible reports based on the screen size and active index.
     * On small screens, only the active card is visible.
     * On larger screens, the active card is centered with one card on each side.
     *
     * @returns {CategoryTopic[]} An array of visible reports for the current view.
     */
    const getVisibleReports = () => {
        if (isSmallScreen) {
            return [reports[activeIndex]];
        }
        return [
            reports[(activeIndex - 1 + reports.length) % reports.length],
            reports[activeIndex],
            reports[(activeIndex + 1) % reports.length]
        ];
    };

    if (reports.length < 3) {
        return <div className={styles['carousel']} />;
    }

    return (
        <div className={styles['carousel']}>
            <div className={styles['carousel-container']}>
                {getVisibleReports().map((report, index) => {
                    const position = window.innerWidth <= 768 ? 'center' : index === 0 ? 'left' : index === 1 ? 'center' : 'right'

                    return (
                        <div
                            key={report?.id}
                            className={`${styles['carousel-card']} ${styles[position]}`}
                            onClick={() => {
                                if (position === 'right') {
                                    handleNext();
                                } else if (position === 'left') {
                                    handlePrevious();
                                }
                            }}
                        >
                            <ReportCarouselCard
                                id={report?.id || ''}
                                title={report?.title || ''}
                                description={report?.description || ''}
                                dataset={report?.dataset || ''}
                                meta={report?.meta || {}}
                            />
                        </div>
                    );
                })}
            </div>

            <Tracker activeIndex={activeIndex} totalCircles={reports.length} />
        </div>
    );
}

export default ReportCategoryCarousel;


