import { ReportCategory } from 'interface/ReportCategory'
import React from 'react'
import styles from 'styles/layout/ReportCategoriesContentSection.module.sass'
import ReportCategoryItem from './ReportCategoryItem'

interface CategoriesContentSectionProps {
    categories: ReportCategory[]
}

/**
 * ReportCategoriesContentSection component renders a list of report categories using ReportCategoryItem.
 *
 * @component
 * @param {CategoriesContentSectionProps} props - Props containing an array of report categories to display.
 *
 * @typedef {Object} CategoriesContentSectionProps
 * @property {ReportCategory[]} categories - Array of report categories to be displayed.
 *
 * @example
 * // Example usage:
 * import ReportCategoriesContentSection from './ReportCategoriesContentSection';
 *
 * const categories = [
 *   { id: '1', title: 'Market Insights', meta: { cover: 'image.jpg' }, description: 'Details about markets', topics: [] }
 * ];
 *
 * <ReportCategoriesContentSection categories={categories} />;
 *
 * @returns {JSX.Element} The rendered ReportCategoriesContentSection component.
 */
const ReportCategoriesContentSection: React.FC<CategoriesContentSectionProps> = ({ categories }) => {
    return (
        <section className={styles['categories-content-section']}>
            {categories && categories.map((category) => (
                <ReportCategoryItem
                    key={category.id} 
                    id={category.id} 
                    title={category.title} 
                    meta={category.meta} 
                    description={category.description} 
                    topics={category.topics} 
                />
            ))}
        </section>
    )
}

export default ReportCategoriesContentSection;