import React from 'react'
import styles from 'styles/components/ReportSummary.module.sass'
import { ReportContent } from "interface/ReportContent"

/**
 * Props for the ReportSummary component.
 *
 * @interface ReportSummaryProps
 * @property {ReportContent} report - The report data to be displayed in the summary.
 */
interface ReportSummaryProps {
    report: ReportContent;
}

/**
 * ReportSummary component displays a summarized view of a report.
 *
 * This component includes key details such as the report's category, title,
 * description, publication year, and author information. It also dynamically 
 * applies background images based on the report's metadata.
 *
 * @component
 *
 * @example
 * import ReportSummary from 'components/common/ReportSummary';
 * 
 * const report = {
 *   id: "123",
 *   dataset: "Market Analysis",
 *   year: "2024",
 *   title: "The State of African Markets",
 *   description: "An in-depth look at market trends in Africa.",
 *   author: {
 *     name: "Jane Doe",
 *     imageUrl: "https://example.com/jane.jpg"
 *   },
 *   meta: {
 *     cover: "https://example.com/cover.jpg"
 *   }
 * };
 *
 * <ReportSummary report={report} />
 *
 * @param {ReportSummaryProps} props - The props for the component.
 * @returns {JSX.Element | null} The rendered ReportSummary component or null if no report data is provided.
 */
const ReportSummary: React.FC<ReportSummaryProps> = ({ report }) => {

    if (!report) return null;

    return (
        <div
            key={report.id} 
            className={styles['report-summary']} 
            style={{
                background: `linear-gradient(rgba(233, 247, 255, 0.9), rgba(219, 242, 255, 0.9)), url(${report.meta.cover || ''})` 
            }}
        >   
            <div className={styles['main-wrapper']}>
                <div className={styles['left-section']}>
                    <div className={styles['top']}>
                        <span className={styles['category']}>{report.dataset}</span>
                        <p className={styles['date-wrapper']}> • <span className={styles['date']}>{report.year}</span></p>
                    </div>
                    <h3 className={styles['title']}>{report.title}</h3>
                    <p className={styles['catchy-phrase']}>{report.description}</p>
                    {/* eslint-disable @typescript-eslint/no-unsafe-member-access */}
                    {report.author && (
                        <div className={styles['author']}>
                            <img src={report.author.imageUrl} alt={report.author.name} className={styles['author-image']} />
                            <p className={styles['name-role']}>by <span className={styles['author-name']}>{report.author.name}</span></p>
                        </div>
                    )}
                    {/* eslint-enable @typescript-eslint/no-unsafe-member-access */}
                </div>
                <div className={styles['right-section']} style={{ backgroundImage: `url(${report.meta.cover || ''})` }}></div>
            </div>
        </div>
    )
}

export default ReportSummary;