import { ExampleTopic } from "interface/ExampleTopic";

/**
 * topics array contains example topics with insights into various sectors in Ghana, including banking, environment, insurance, and 5G technology.
 * Each topic includes an ID, name, description, and a list of prompts for generating reports or discussions.
 * 
 * @constant
 * 
 * @type {ExampleTopic[]}
 * 
 * @example
 * console.log(topics[0].name);
 * // Output: "Banking"
 * 
 * @see ExampleTopic - Interface defining the structure of each topic and its prompts.
 */
export const topics: ExampleTopic[] = [
    // {
    //     id: "banking",
    //     name: "Banking",
    //     description: "Exploration of public perception, adoption, and challenges related to banking services and products in Ghana.",
    //     prompts: [
    //         {
    //             id: "Investment Choices and Awareness in Ghana's Banking Sector",
    //             description: "What types of financial products are Ghanaians familiar with, and what motivates them to invest through banks?"
    //         },
    //         {
    //             id: "Credit Card Awareness and Adoption",
    //             description: "How aware are Ghanaians of credit card benefits and risks, and what factors affect their decision to apply for credit cards?"
    //         },
    //         {
    //             id: "Factors Influencing Bank Selection",
    //             description: "What are the top considerations for Ghanaians when choosing a bank, and how important are transparency and customer service?"
    //         },
    //         {
    //             id: "Satisfaction with Banking Services",
    //             description: "How satisfied are Ghanaians with banking services, especially regarding loan applications, account services, and digital banking?"
    //         }
    //     ]
    // },
    // {
    //     id: "environment",
    //     name: "Environment",
    //     description: "Insights into environmental awareness, challenges, and sustainability efforts within Ghanaian communities.",
    //     prompts: [
    //         {
    //             id: "Community Awareness and Action on Environmental Issues",
    //             description: "How aware are Ghanaians of environmental issues in their communities, and what actions are being taken to address them?"
    //         },
    //         {
    //             id: "Government's Role in Environmental Protection",
    //             description: "What are public perceptions regarding the government’s role and effectiveness in managing environmental issues?"
    //         },
    //         {
    //             id: "Climate Change Impact on Local Communities",
    //             description: "What effects of climate change have Ghanaians observed locally, including shifts in weather patterns and agriculture?"
    //         },
    //         {
    //             id: "Individual Contributions to Environmental Sustainability",
    //             description: "How do individuals contribute to sustainability in their communities, and what more could be done?"
    //         }
    //     ]
    // },
    // {
    //     id: "insurance",
    //     name: "Insurance",
    //     description: "Insights into the insurance industry in Ghana, focusing on customer perception, challenges, and opportunities.",
    //     prompts: [
    //         {
    //             id: "Understanding Customer Perception of Insurance in Ghana",
    //             description: "What is the general perception among Ghanaians regarding the transparency and communication of policies by insurance companies?"
    //         },
    //         {
    //             id: "Factors Influencing Insurance Adoption",
    //             description: "What factors most influence Ghanaians in their decision to purchase insurance? How do these factors differ between urban and rural populations?"
    //         },
    //         {
    //             id: "Claims Process and Customer Experience",
    //             description: "How easy or challenging is the claims process for Ghanaians, and what are common points of frustration?"
    //         },
    //         {
    //             id: "Tailoring Insurance for Specific Needs and Demographics",
    //             description: "How well does the insurance industry in Ghana cater to the needs of small businesses and entrepreneurs?"
    //         }
    //     ]
    // },
    {
        id: "5g",
        name: "5G in Ghana",
        description: "Exploration of public opinion and potential impact of 5G technology in Ghana.",
        prompts: [
            {
                id: "opportunities",
                description: "What benefits do Ghanaians expect 5G technology to bring to their daily activities or businesses?"
            },
            {
                id: "concerns",
                description: "What concerns do Ghanaians have about the introduction of 5G technology, and what challenges might affect its adoption?"
            }
        ]
    }
]