import { Feature } from "interface/Feature"
import defaultFeatBgImage from 'assets/images/svg/graph-background-feature.svg'
import featureImage from 'assets/images/png/feature-data-collection.png'
import featureIcon from 'assets/images/svg/zerone-data-icon.svg'

/**
 * Data Collection feature object.
 * 
 * @type {Feature}
 * @property {IconifyIcon} icon - The icon for the feature, using the Iconify icon.
 * @property {boolean} isIcon - Determines whether the feature uses an icon.
 * @property {string} iconWrapperBgColor - Background color of the icon wrapper.
 * @property {string} featureImage - The path to the image that will be displayed.
 * @property {string} hoverBackgroundImage - Background image that appears on hover.
 * @property {string} hoverBackground - The gradient background applied on hover.
 * @property {string} title - The title of the feature (Data Collection).
 * @property {string} description - The description of the feature, explaining its purpose.
 * @property {string} defaultColor - Default color for the icon or text.
 * @property {string} hoverColor - Color applied when the feature is hovered.
 */
export const dataCollectionFeat : Feature = {
    icon: featureIcon,
    isIcon: false,
    iconWrapperBgColor: '#F5F5F5',
    featureImage: featureImage,
    hoverBackgroundImage: defaultFeatBgImage,
    hoverBackground: "linear-gradient(224.8deg, #fff, #d1efff)",
    title: 'Data Collection',
    description: 'Gather tailored data from targeted audiences with ZeroneData. Get survey responses in hours and receive your customized dataset, ready to analyze or integrate into your workspace.',
    defaultColor: '#707070',
    hoverColor: '#009E4C',
}