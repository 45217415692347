import BlogSummary from 'components/common/BlogSummary';
import HeaderNavigation from 'components/layout/header/HeaderNavigation';
import { BlogContent } from 'interface/BlogContent';
import React from 'react'
import styles from 'styles/layout/BlogHeaderSection.module.sass'

// const TAG = 'BlogHeaderSection'

interface BlogHeaderSectionProps {
    topic: BlogContent | null;
}

/**
 * BlogHeaderSection component - A layout section that displays the header navigation
 * and a featured blog summary.
 *
 * @component
 * @param {BlogHeaderSectionProps} props - Props containing the topic to display.
 * @returns {JSX.Element} The rendered BlogHeaderSection component.
 *
 * @example
 * // Example usage:
 * <BlogHeaderSection topic={fetchedTopic} />
 */
const BlogHeaderSection: React.FC<BlogHeaderSectionProps> = ({ topic }) => {

    return (
        <section className={styles['blog-header-section']}>
            <HeaderNavigation theme='dark' />

            {topic && (
                <BlogSummary 
                    id={topic.id} 
                    category={topic.category} 
                    title={topic.title} 
                    catchyPhrase={topic.catchyPhrase} 
                    author={topic.author} 
                    meta={topic.meta} 
                />
            )}
        </section>
    )
}

export default BlogHeaderSection;