import { logError } from "services/logService/errorLogger";

/**
 * Formats a given amount with the specified currency.
 * If amount is invalid or zero, it returns a fallback message.
 * 
 * @param {string} currency - The currency code (e.g., "USD", "KES").
 * @param {number} amount - The amount to be formatted.
 * @returns {string} - Formatted currency string or a fallback message.
 */
export const formatCurrency = (currency: string, amount: number): string => {
    try {
        // If the amount is zero or NaN, return the fallback message
        if (!amount || amount <= 0) {
            return "Contact us"
        }

        // Format the currency with the provided currency code
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    } catch (error) {
        logError(
            "Error formatting currency",
            { currency, amount, error },
            'formatCurrency.ts'
        );
        // Return fallback message if any error occurs
        return "Contact us"
    }
}