import ReusableIcon from 'components/common/ReusableIcon'
import { DynamicCardInfo } from 'interface/DynamicInfo'
import React from 'react'
import styles from 'styles/components/DynamicInfoCard.module.sass'

/**
 * DynamicInfoCard component displays a card with an icon, title, and description.
 *
 * This reusable card component is used to showcase dynamic information with a consistent layout.
 *
 * @component
 * @example
 * // Example usage:
 * import DynamicInfoCard from 'components/DynamicInfoCard';
 *
 * const App: React.FC = () => (
 *   <DynamicInfoCard
 *     id="1"
 *     icon="mdi:information"
 *     title="Dynamic Title"
 *     description="This is a sample description for the dynamic info card."
 *   />
 * );
 *
 * @param {DynamicCardInfo} props - The properties for the DynamicInfoCard component.
 * @param {string} props.id - The unique identifier for the card.
 * @param {string | IconType | IconifyIcon} props.icon - The icon to display in the card.
 * @param {string} props.title - The title text displayed in the card.
 * @param {string} props.description - The description text displayed in the card.
 *
 * @returns {JSX.Element} The rendered DynamicInfoCard component.
 */
const DynamicInfoCard: React.FC<DynamicCardInfo> = ({ id, icon, title, description }) => {
    return (
        <div key={id} className={styles['dynamic-card-info']}>
            <ReusableIcon icon={icon} className={styles['icon'] || 'icon'} color={'#005581'} />
            <h3 className={styles['title']}>{title}</h3>
            <p className={styles['description']}>{description}</p>
        </div>
    )
}

export default DynamicInfoCard;