import React, { useState } from 'react'
import styles from 'styles/layout/PricingSection.module.sass'
import IndividualBusinessPricing from 'components/pricing/IndividualBusinessPricing'
import EducationPricing from 'components/pricing/EducationPricing'
import StartupPricing from 'components/pricing/StartupPricing'
import PricingContainer from 'components/common/PricingContainer'

/**
 * PricingSection component displays the pricing options for different user categories—Individual/Business, Education, and Startups.
 * 
 * @component
 * 
 * @example
 * // Renders the PricingSection component with tabs for different pricing plans
 * <PricingSection />
 * 
 * @see TabSelector - Component for selecting the active pricing category tab
 * @see IndividualBusinessPricing - Component for displaying individual and business pricing plans
 * @see EducationPricing - Component for displaying education-specific pricing
 * @see StartupPricing - Component for displaying pricing options for startups
 */
const PricingSection: React.FC = () => {
    const [activeTab, setActiveTab] = useState('Individual / Business')

    const pricingTabs = ['Individual / Business', 'Education', 'Startups'];

    const handleTabChange = (tab: string) => {
        setActiveTab(tab)
    }

    return (
        <div className={styles['pricing-section']}>
            <div className={styles['title-wrapper']}>
                <h2 className={styles['section-title']}>CHOOSE THE RIGHT PACKAGE FOR YOUR TEAM</h2>
                <h2 className={styles['section-subtitle']}>
                    Zerone Pricing
                </h2>
            </div>
            <PricingContainer tabs={pricingTabs} activeTab={activeTab} onTabChange={handleTabChange}>
                {activeTab === 'Individual / Business' && <IndividualBusinessPricing />}
                {activeTab === 'Education' && <EducationPricing />}
                {activeTab === 'Startups' && <StartupPricing />}
            </PricingContainer>
        </div>
    )
}

export default PricingSection;