/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from 'styles/layout/TermsSection.module.sass';

const TermsSection: React.FC = () => {
  return (
    <section className={styles.termsSection}>
      <div className={styles.header}>
        <h1 className={styles.title}>Terms and Conditions</h1>
        <p className={styles.lastModified}>
          <strong>Last Modified:</strong> 4th November 2024
        </p>
      </div>
      
      <div className={styles.content}>
        <p>
          Welcome to Zerone AnalytiQs! These Terms and Conditions ("Terms") govern your use of our services, including our website (https://zeroneanalytiqs.com), the <strong>ZeroneData</strong> mobile application available on Android and iOS, and any related services offered by Zerone AnalytiQs ("we," "our," "us"). By accessing or using our website, mobile application, or services, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our services.
        </p>

        <h2 className={styles.subtitle}>1. Accounts and Membership</h2>
        <h3>a. Account Creation</h3>
        <p>
          To access certain features of our services, you must create an account on our website or ZeroneData app. You are responsible for maintaining the confidentiality of your account information, including your username and password. You agree to provide accurate, current, and complete information when creating your account.
        </p>
        <h3>b. Eligibility</h3>
        <p>
          You must be at least 18 years old to register for an account. By using our services, you represent and warrant that you meet these eligibility requirements. If you are using the services on behalf of a business or organization, you represent that you have the authority to bind that entity to these Terms.
        </p>

        <h2 className={styles.subtitle}>2. Payments and Billing</h2>
        <h3>a. Subscription Fees</h3>
        <p>
          Certain features of the ZeroneData app and our analytics services may require payment of subscription fees. The applicable fees, payment terms, and billing procedures will be disclosed when you register for these services. By subscribing, you agree to pay all fees associated with your chosen plan, including applicable taxes.
        </p>
        <h3>b. Payment Information</h3>
        <p>
          You must provide accurate and complete payment information when making a purchase. You authorize Zerone AnalytiQs to charge your provided payment method for all fees related to your subscription or service use. Failure to provide accurate billing information may result in suspension or cancellation of your account.
        </p>
        <h3>c. Auto-Renewal</h3>
        <p>
          Subscriptions may automatically renew at the end of the subscription period unless you cancel prior to the renewal date. You will be billed for the renewal term at the current subscription rate unless otherwise notified.
        </p>

        <h2 className={styles.subtitle}>3. Cancellation Policy</h2>
        <p>
          You may cancel your subscription at any time through your account settings. Cancellation will take effect at the end of the current billing cycle, and you will retain access to paid services until the end of that cycle. You will not be entitled to a refund for any unused portion of the subscription period.
        </p>

        <h2 className={styles.subtitle}>4. Refund Policy</h2>
        <p>
          Refunds are generally not provided for subscription fees or other paid services unless required by law. If you believe you are entitled to a refund due to extenuating circumstances, please contact us, and we will evaluate your request on a case-by-case basis.
        </p>

        <h2 className={styles.subtitle}>5. Acceptable Use</h2>
        <p>By using our services, you agree to:</p>
        <ul>
          <li>Use the services only for lawful purposes and in accordance with these Terms.</li>
          <li>Refrain from using the services to collect, store, or share any information that violates any applicable laws or infringes the rights of others.</li>
          <li>Not engage in any activity that disrupts or interferes with the services, including introducing viruses, malware, or other harmful code.</li>
          <li>Not attempt to access or manipulate any part of the services that you are not authorized to use.</li>
        </ul>
        <p>We reserve the right to suspend or terminate your account if you engage in any prohibited activities.</p>

        {/* Add more sections here following the same pattern */}

        <h2 className={styles.subtitle}>25. Contact Us</h2>
        <div className={styles.contactSection}>
          <p>
            If you have any questions about these Terms and Conditions, please contact us at:{' '}
            <a href="https://zeroneanalytiqs.com/contact-us.html" className={styles.link}>
              https://zeroneanalytiqs.com/contact-us.html
            </a>
          </p>
          <div>
            <div className={styles.officeInfo}>
              <h4>Zerone AnalytiQs</h4>
              <h4>Head quarters:</h4>
              <address>
                1200 – 900 West Hastings Street<br />
                Vancouver, BC V6C 1E5
              </address>
            </div>
            <div className={styles.officeInfo}>
              <h4>Ghana Office (Operations):</h4>
              <address>
                18 South Ofankor<br />
                Ga West, Accra<br />
                GW 737 3350
              </address>
            </div>
            <div>
              <p>
                Email:{' '}
                <a href="mailto:legal@zeroneanalytiqs.com" className={styles.link}>
                  legal@zeroneanalytiqs.com
                </a>
              </p>
              <p>
                Phone:{' '}
                <a href="tel:+233538855417" className={styles.link}>
                  +233 538855417
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsSection;