import React, { useState } from 'react'
import{ motion } from 'framer-motion'
import { Icon } from '@iconify/react'
import styles from 'styles/components/FeatureCard.module.sass'
import upArrowIcon from '@iconify/icons-fluent/arrow-up-24-regular'
import forwardArrowIcon from '@iconify/icons-ph/arrow-right-light';
import { Feature } from 'interface/Feature'

/**
 * Props for the FeatureCard component.
 * 
 * @typedef {Object} FeatureCardProps
 * @property {Feature} feature - The feature object containing details like title, icon, and description.
 */
interface FeatureCardProps {
    feature: Feature;
}

/**
 * FeatureCard component renders a feature card displaying the feature's icon, title, description, 
 * and additional content. It includes interactive hover effects, where the background changes,
 * an animation is applied, and the feature's details become highlighted.
 * 
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import FeatureCard from 'components/cards/FeatureCard';
 * import { myFeature } from 'features/landing/Features';
 * 
 * const App: React.FC = () => (
 *   <div>
 *     <FeatureCard feature={myFeature} />
 *   </div>
 * );
 * 
 * export default App;
 * ```
 * 
 * @param {FeatureCardProps} props - The properties passed to the component.
 * @param {Feature} props.feature - Object containing the feature details, such as icon, title, description, and hover styles.
 * 
 * @see [Iconify documentation](https://iconify.design/docs/) for details about the `Icon` component used for rendering icons.
 * @see [Framer Motion documentation](https://www.framer.com/motion/) for details about the animation and motion transitions.
 * 
 * @returns {JSX.Element} The JSX element displaying the feature card.
 */
const FeatureCard: React.FC<FeatureCardProps> = ({ feature }) => {

    const {
        icon,
        isIcon,
        iconWrapperBgColor,
        featureImage,
        hoverBackgroundImage,
        hoverBackground,
        title,
        description,
        defaultColor,
        hoverColor,
        specialText
    } = feature;

    const [isHovered, setIsHovered] = useState(false);
    const defaultTitleColor = '#1E1E1E'
    const defaultIconColor = '#707070'
    const defaultTopWrapperBackground = '#F5F5F5'

    return (
        <motion.div
            className={`${styles['feature-card']} ${isHovered ? styles.hovered : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ background: isHovered ? hoverBackground : '#fff' }}
            initial={{ scale: 1 }}
            animate={{ scale: isHovered ? 1.01 : 1 }}
            transition={{
                type: 'spring',
                stiffness: 1536,
                damping: 24,
                mass: 1
            }}
            >
                {/** Background with hover color tint */}
                <div className={styles['background-container']}>
                    <div
                        className={styles['background-image']}
                        style={{
                            backgroundImage: `url(${hoverBackgroundImage})`,
                            display: isHovered ? 'block' : 'none',
                        }}
                    ></div>
                </div>

                {/** Body Section: Title, Description, Icon*/}
                <div
                    className={styles.content}>
                        { /** Upper left icon */}
                        <div 
                            className={styles['icon-wrapper']}
                            style={{
                                backgroundColor: isHovered ? iconWrapperBgColor : defaultTopWrapperBackground, 
                                borderColor: isHovered ? hoverColor : defaultColor
                                 }}>
                            {isIcon ? (
                                <Icon
                                    icon={icon}
                                    className={styles['feature-icon']}
                                    style={{
                                        color: isHovered ? hoverColor : defaultIconColor,
                                        transition: 'color 0.3s ease'
                                    }}
                                    width="60"
                                />
                            ) : (
                                <img
                                    src={icon as string}
                                    alt={title}
                                    className={styles['feature-icon']}
                                    style={{
                                        stroke: isHovered ? hoverColor : defaultIconColor,
                                        fill: 'none',
                                    }}
                                />
                            )}
                        </div>
                        {/** Title, special text, and description */}
                        <div className={styles['text-wrapper']}>
                            <div className={styles['title-wrapper']}>
                                <h3
                                    className={styles['feature-title']}
                                    style={{ color: isHovered ? hoverColor : defaultTitleColor }}
                                >
                                    {title}
                                </h3>
                                {specialText && (
                                    <div className={styles['special-text']}
                                        style={{ backgroundColor: hoverColor }}>
                                        {specialText}
                                    </div>
                                )}
                            </div>
                            <p className={styles['feature-description']}>{description}</p>
                        </div>
                    </div>

            {/** Footer Section: Arrow and Image */}
            <div className={styles.footer}>
                {/** Bottom left arrow icon */}
                <motion.div
                    className={styles['arrow-wrapper']}
                    style={{ borderColor: hoverColor, backgroundColor: isHovered ? hoverColor : 'transparent' }}
                    initial={{ rotate: 0 }}
                    animate={{ rotate: isHovered ? 0 : 0 }}
                    transition={{
                        type: 'spring',
                        stiffness: 3000,
                        damping: 16,
                        mass: 1
                    }}>
                    <Icon
                        icon={isHovered ? forwardArrowIcon : upArrowIcon}
                        className={`${styles['arrow-icon']}`}
                        color={isHovered ? '#FFFFFF' : hoverColor}
                        width="24" />
                </motion.div>

                {/** Feature image */}
                <motion.img
                    src={featureImage}
                    alt={`${title} Image`}
                    className={styles['feature-image']}
                    transition={{
                        type: 'spring',
                        stiffness: 1536,
                        damping: 24,
                        mass: 1
                    }} />
            </div>
        </motion.div>
    );
}

export default FeatureCard;