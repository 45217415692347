/**
 * An animation configuration for a pulsing effect.
 * The element smoothly scales up to 1.2 and returns to its original size, repeating indefinitely.
 * 
 * @constant {Object} pulseAnimation
 * @property {number[]} scale - Keyframes for scaling, starting and ending at 1, with an increase to 1.2.
 * @property {Object} transition - Defines the duration, repeat count, and easing function for the animation.
 * @property {number} transition.duration - Duration of one animation cycle in seconds.
 * @property {number} transition.repeat - Number of times the animation should repeat. `Infinity` indicates an endless loop.
 * @property {string} transition.ease - Easing function for smooth transitions between keyframes.
 * 
 * @example
 * // Apply pulseAnimation to a component using motion in framer-motion
 * import { motion } from 'framer-motion';
 * import { pulseAnimation } from './animations';
 * 
 * <motion.div animate={pulseAnimation}>Pulsing Element</motion.div>
 */
export const pulseAnimation = {
    scale: [1, 1.2, 1],
    transition: {
        duration: 3,
        repeat: Infinity,
        ease: 'easeInOut',
    },
};