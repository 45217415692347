import AboutContactSalesSection from 'components/layout/about-us/AboutContactSalesSection'
import AboutUsHeaderSection from 'components/layout/about-us/AboutUsHeaderSection'
import AboutUsMainSection from 'components/layout/about-us/AboutUSMainSection'
import FooterMain from 'components/layout/footer/FooterMain'
import TrustedBySection from 'layouts/section/TrustedBySection'
import React from 'react'
import styles from 'styles/pages/AboutUsPage.module.sass'

/**
 * AboutUsPage component renders the main About Us page with sections for the header, main content, 
 * contact sales, trusted by logos, and footer.
 * 
 * @component
 *
 * @example
 * // Rendering AboutUsPage
 * import AboutUsPage from 'path/to/AboutUsPage';
 * 
 * const App: React.FC = () => <AboutUsPage />;
 * 
 * export default App;
 *
 * @returns {JSX.Element} The JSX element rendering the About Us page.
 */
const AboutUsPage: React.FC = () => {
    return (
        <div className={styles['about-us-page']}>
            <AboutUsHeaderSection />
            <AboutUsMainSection />
            <AboutContactSalesSection />
            <TrustedBySection />
            <FooterMain />
        </div>
    )
}

export default AboutUsPage;