import { BlogContent } from 'interface/BlogContent';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from 'styles/components/BlogCard.module.sass'

/** 
 * Blog content data to be displayed on the card.
 * @type {BlogContent}
 */
interface BlogCardProps {
  blog: BlogContent;
}

/**
 * BlogCard component renders a preview card for a blog post.
 * 
 * @component
 * @param {BlogCardProps} props - Props for the BlogCard component.
 * @param {BlogContent} props.blog - Blog content data to be displayed on the card.
 * 
 * @example
 * // Render a BlogCard with a blog object
 * <BlogCard blog={blog} />
 */
const BlogCard: React.FC<BlogCardProps> = ({ blog }) => {
  const { id, meta, category, title, catchyPhrase, author } = blog;
  const navigate = useNavigate()

  /**
   * Handles navigation to the blog post page when the card is clicked.
   */
  const handleBlogCardClick = () => {
    navigate(`/blog/${id}`)
  }

  return (
    <div className={styles.blogCard} key={blog.id} onClick={handleBlogCardClick}>
      <div className={styles.blogImage} style={{ backgroundImage: `url(${meta.imageUrl || ''})` }} />
      <div className={styles.content}>
        <p className={styles.category}>{category}</p>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.summary}>{catchyPhrase}</p>
        <div className={styles['author']}>
            <img src={author.imageUrl} alt={author.name} className={styles['author-image']} />
            <p className={styles['name-role']}>by <span className={styles['author-name']}>{author.name}</span> • <span className={styles['read-time']}>{meta.readTime}</span></p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;