import { RadioButtonGroupProps } from 'interface/RadioButtonGroupProps';
import React from 'react'
import styles from 'styles/components/RadioButtonGroup.module.sass'

/**
 * RadioButtonGroup component renders a set of radio buttons for selecting an option.
 * 
 * @component
 * @param {string[]} options - An array of options to be displayed as radio buttons.
 * @param {string} selectedOption - The currently selected option.
 * @param {(option: string) => void} onOptionChange - A function to handle when an option is selected.
 * 
 * @example
 * <RadioButtonGroup
 *   options={['Option 1', 'Option 2', 'Option 3']}
 *   selectedOption="Option 1"
 *   onOptionChange={(option) => console.log('Selected option:', option)}
 * />
 * 
 * @returns {JSX.Element} The JSX for the RadioButtonGroup component.
 */
const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
    options,
    selectedOption,
    onOptionChange
}) => {
    return (
        <div className={styles['radio-group']}>
            {options.map((option) => (
                <label key={option} className={styles['radio-option']}>
                    <input
                        type='radio'
                        name='radio-group'
                        value={option}
                        checked={selectedOption === option}
                        onChange={() => onOptionChange(option)}
                        className={styles['radio-input']}
                    />
                    <span className={styles['radio-circle']}></span>
                    {option}
                </label>
            ))}
        </div>
    )
}

export default RadioButtonGroup;