import React from 'react'
import styles from 'styles/components/PricingHero.module.sass'

/**
 * PricingHero component displays a hero section for the pricing page, introducing
 * the pricing plans with a title, main focus text, and a discount message.
 *
 * @component
 *
 * @example
 * // Renders the PricingHero component
 * <PricingHero />
 */
const PricingHero:React.FC = () => {
    return (
        <div className={styles['pricing-hero-container']}>
            <p className={styles['short-intro']}>ZERONE PRICING</p>
            <h2 className={styles['focus']}>Get the most out of data<span className={styles['line-break']} /> with our plans</h2>
            <p className={styles['discount']}><span>%</span>Discounts for Startups</p>
        </div>
    )
}

export default PricingHero;