import React from 'react';
import Lottie from 'lottie-react';
import successAnimation from 'assets/animations/SuccessAnimation.json';
import styles from 'styles/components/SuccessSection.module.sass';

/**
 * Props for the SuccessSection component.
 *
 * @interface SuccessSectionProps
 * @property {string} [title] - Optional custom title for the success message.
 * @property {string} [subtitle] - Optional custom subtitle for the success message.
 * @property {string} [note] - Optional custom note text.
 * @property {object} [animationData] - Optional custom Lottie animation data.
 *
 * @example
 * Example usage:
 *
 * ```tsx
 * const props: SuccessSectionProps = {
 *   title: "Custom Success Title",
 *   subtitle: "Custom success subtitle message",
 *   note: "Custom note text",
 *   animationData: {...custom lottie animation data}
 * };
 * ```
 */
interface SuccessSectionProps {
  title?: string;
  subtitle?: string;
  note?: string;
  animationData?: object;
}

/**
 * SuccessSection component displays a success message with an animation, title, subtitle, and note.
 *
 * @component
 * @param {string} [title] - The main title of the success message. Defaults to "All set!".
 * @param {string} [subtitle] - The subtitle of the success message. Defaults to "Check your email to verify your account."
 * @param {string} [note] - Additional note text. Defaults to a message about checking spam folder.
 * @param {object} [animationData] - Lottie animation data for the success animation. Defaults to imported successAnimation.
 *
 * @example
 * Example usage:
 *
 * ```tsx
 * import SuccessSection from 'components/SuccessSection';
 *
 * const ExampleComponent: React.FC = () => (
 *   <SuccessSection
 *     title="Custom Success!"
 *     subtitle="Your account has been created successfully."
 *     note="You will be redirected to the dashboard in a few seconds."
 *   />
 * );
 *
 * export default ExampleComponent;
 * ```
 *
 * @returns {JSX.Element} The JSX element displaying the success message and animation.
 */
const SuccessSection: React.FC<SuccessSectionProps> = ({
  title = 'All set!',
  subtitle = 'Check your email to verify your account.',
  note = "If you didn't see the email in your inbox, please check your spam folder.",
  animationData = successAnimation,
}) => {
  return (
    <div className={styles.successSection}>
      <Lottie animationData={animationData} loop={true} className={styles.animation} />
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{subtitle}</p>
      <p className={styles.note}>{note}</p>
    </div>
  );
};

export default SuccessSection;
