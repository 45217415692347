import React from 'react'
import styles from 'styles/components/VideoPlayer.module.sass'

interface VideoPlayerProps {
    videoSrc: string;
    onClose: () => void;
}

/**
 * VideoPlayer component renders a fullscreen video player with overlay and controls.
 * Clicking outside the video closes the player.
 * 
 * @component
 * 
 * @param {Object} props - Props for the VideoPlayer component.
 * @param {string} props.videoSrc - The source URL for the video.
 * @param {() => void} props.onClose - Callback function to close the video player.
 * 
 * @example
 * <VideoPlayer videoSrc="path/to/video.mp4" onClose={() => console.log('Video closed')} />
 * 
 * @returns {JSX.Element} The JSX for the VideoPlayer component.
 */
const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoSrc, onClose }) => {
    return (
        <div className={styles['video-player-overlay']}  onClick={onClose}>
            <div className={styles['video-player-container']} onClick={(e) => e.stopPropagation()}>
            <video
                    src={videoSrc}
                    controls
                    autoPlay
                    className={styles['video']}
                >
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    )
}

export default VideoPlayer;