import React from 'react';
import styles from 'styles/components/PricingContainer.module.sass';
import TabSelector from 'components/navigation/TabSelector';

interface PricingContainerProps {
    tabs: string[];
    activeTab: string;
    onTabChange: (tab: string) => void;
    children: React.ReactNode;
}

/**
 * PricingContainer component provides a reusable layout for displaying tabbed pricing options.
 * 
 * @component
 * 
 * @param {string[]} tabs - Array of tab names.
 * @param {string} activeTab - Currently selected tab.
 * @param {(tab: string) => void} onTabChange - Function to handle tab change events.
 * @param {React.ReactNode} children - The content to display in each tab.
 * 
 * @example
 * <PricingContainer
 *     tabs={['Individual / Business', 'Education', 'Startups']}
 *     activeTab="Individual / Business"
 *     onTabChange={handleTabChange}
 * >
 *     <IndividualBusinessPricing />
 * </PricingContainer>
 */
const PricingContainer: React.FC<PricingContainerProps> = ({ tabs, activeTab, onTabChange, children }) => {
    return (
        <div className={styles['pricing-container']}>
            <TabSelector
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={onTabChange}
            />
            <div className={styles['pricing-content']}>
                {children}
            </div>
        </div>
    );
};

export default PricingContainer;
