import { ColorPair } from "interface/ColorPair";

/**
 * Array of color pairs, each containing a light and a dark color.
 * @type {ColorPair[]}
 * @example
 * [
 *   { light: "#D7FDFF", dark: "#13757B" },
 *   { light: "#F0E4FF", dark: "#9747FF" },
 * ]
 */
const colorPairs: ColorPair[] = [
    { light: "#D7FDFF", dark: "#13757B" },
    { light: "#F0E4FF", dark: "#9747FF" },
    { light: "#D9FEEB", dark: "#009C4B" },
    { light: "#FFEAE4", dark: "#C62E00" },
];

/**
 * Returns a random color pair from the predefined list of color pairs.
 * @returns {ColorPair} A randomly selected color pair with light and dark colors.
 * @example
 * const colorPair = getRandomColorPair();
 * console.log(colorPair); // { light: "#D7FDFF", dark: "#13757B" }
 */
const getRandomColorPair = (): ColorPair => {
    const randomIndex = Math.floor(Math.random() * colorPairs.length);
    return colorPairs[randomIndex]!;
}

export default getRandomColorPair;