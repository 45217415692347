import React  from 'react'
import styles from 'styles/layout/FeaturesSection.module.sass'
import { workspaceFeat } from 'features/landing/WorkspaceFeat';
import { dataCollectionFeat } from 'features/landing/DataCollectionFeat';
import { discoverReportsFeat } from 'features/landing/DiscoverReportsFeat';
import { zeroneBotFeat } from 'features/landing/ZeroneBotFeat';
import FeatureCard from 'components/cards/FeatureCard';

/**
 * FeaturesSection component represents a section on the landing page 
 * that displays multiple features using the FeatureCard component.
 * 
 * The section includes a title, subtitle, and a grid of feature cards.
 * Each feature card represents a specific functionality like Data Visualization,
 * Data Collection, Discover Reports, and ZeroneBot.
 * 
 * @component
 * @example
 * Example usage:
 * 
 *  * ```tsx
 * import FeaturesSection from 'layout/section/FeaturesSection';
 * 
 * const App: React.FC = () => (
 *   <div>
 *     <FeaturesSection />
 *   </div>
 * );
 *
 * export default App;
 * ```
 * 
 * @returns {JSX.Element} The JSX for the FeaturesSection component.
 */

const FeaturesSection: React.FC = () => {

    /**
     * Array of feature objects that represents the features we provide.
     * @type {Array<Feature>}
     */
    const featuresList = [
        workspaceFeat,
        dataCollectionFeat,
        discoverReportsFeat,
        zeroneBotFeat
    ]

    return (
        <section className={styles['features-section']}>
            <div className={styles['title-wrapper']}>
                <h2 className={styles['section-title']}>Features</h2>
                <h2 className={styles['section-subtitle']}>
                    Discover the power<span className={styles['line-break']} /> of data with Zerone
                </h2>
            </div>
            <div className={styles['features-grid']}>
                {featuresList.map((feature) => (
                    <FeatureCard
                        key={feature.title}
                        feature={feature}
                    />
                ))}
            </div>
        </section>
    );
};

export default FeaturesSection;