import AnimatedBackgroundContainer from 'components/common/AnimatedBackgroundContainer'
import LoginForm from 'components/forms/LoginForm'
import React from 'react'
import styles from 'styles/pages/LoginPage.module.sass'

/**
 * LoginPage component renders the login page layout with an animated background and a login form.
 * 
 * The component utilizes `AnimatedBackgroundContainer` to provide a visually engaging background with
 * an optional footer. The `LoginForm` component is nested inside, offering user authentication functionality.
 * 
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import LoginPage from 'pages/LoginPage';
 * 
 * const App: React.FC = () => (
 *   <LoginPage />
 * );
 * 
 * export default App;
 * ```
 * 
 * @returns {JSX.Element} The JSX for the LoginPage component.
 */
const LoginPage: React.FC = () => {
    return (
        <div className={styles['login-page']}>
            <AnimatedBackgroundContainer showFooter={true}>
                <LoginForm />
            </AnimatedBackgroundContainer>
        </div>
    )
}

export default LoginPage;