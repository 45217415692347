import React from 'react';
import ContactUs from '../../components/layout/footer/ContactUs';
import FooterMain from '../../components/layout/footer/FooterMain';
import styles from 'styles/layout/FooterSection.module.sass'

/**
 * FooterSection component combines the ContactUs and FooterMain components
 * to form a single footer section with contact methods and company information.
 *
 * @component
 * @example
 * Example usage:
 *
 * ```tsx
 * import FooterSection from 'layouts/section/FooterSection';
 *
 * const App: React.FC = () => (
 *   <div>
 *     <FooterSection />
 *   </div>
 * );
 *
 * export default App;
 * ```
 *
 * @returns {JSX.Element} The JSX element displaying the combined footer section.
 */
const FooterSection: React.FC = () => {
    return (
        <footer className={styles['footer-section']}>
            <div className={styles['contact-us-wrapper']}>
                <ContactUs />
            </div>
            <div className={styles['footer-main-wrapper']}>
                <FooterMain />
            </div>
        </footer>
    );
}

export default FooterSection;