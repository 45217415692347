import React, { useState, useEffect } from 'react'
import styles from 'styles/components/PromptDisplay.module.sass'
import reportLineIcon from '@iconify/icons-mdi/report-line'
import SecondaryButton from './SecondaryButton';
import { Prompt } from 'interface/Prompt';

interface PromptDisplayProps {
    prompts: Prompt[];
    hint: string;
    onGenerate: (prompt: Prompt | null) => void;
}

/**
 * PromptDisplay component displays a list of prompts and allows the user to select one to generate insights.
 * 
 * @component
 * 
 * @param {Object} props - The props for configuring the PromptDisplay.
 * @param {Prompt[]} props.prompts - An array of prompts to be displayed.
 * @param {string} props.hint - A hint message to show when there are no prompts available.
 * @param {function} props.onGenerate - A function to handle the generation of insights based on the selected prompt.
 * 
 * @example
 * // Renders PromptDisplay with a list of prompts and a hint
 * <PromptDisplay 
 *   prompts={[{ description: 'Analyze sales trends' }, { description: 'Evaluate customer feedback' }]}
 *   hint="No prompts available. Please add a new prompt."
 *   onGenerate={(prompt) => console.log(`Generating insights for: ${prompt?.description}`)}
 * />
 */
const PromptDisplay: React.FC<PromptDisplayProps> = ({ prompts, hint, onGenerate}) => {
    const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null)

    const handlePromptClick = (prompt: Prompt) => {
        setSelectedPrompt(prompt)
    }

    useEffect(() => {
        setSelectedPrompt(null)
    }, [prompts])
    return (
        <div className={styles['prompt-display-container']}>
            <div className={styles['prompt-content-wrapper']}>
                {selectedPrompt ? (
                    <div className={styles['prompt-bubble']}>
                        {selectedPrompt.description}
                    </div>
                ) : (
                    prompts.length > 0 ? (
                        <div className={styles['prompt-bubble-list']}>
                            {prompts.map((prompt, index) => (
                                <div 
                                    key={index} 
                                    className={styles['prompt-bubble']}
                                    onClick={() => handlePromptClick(prompt)}
                                >
                                    {prompt.description}
                                </div>
                            ))}
                        </div>
                ) : (
                    <p className={styles['hint']}>{hint}</p>
                ))}
            </div>
            <div className={styles['generate-button']}>
                <SecondaryButton
                    text="Generate Insights"
                    primaryColor="#ff5522"
                    textColor="#fff"
                    hoverColor="#fff"
                    hoverTextColor="#ff5522"
                    icon={reportLineIcon}
                    doSomething={() => onGenerate(selectedPrompt)}
                />
            </div>
        </div>
    )
}

export default PromptDisplay;