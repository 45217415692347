
import React, { useState } from 'react'
import { SearchDropdownProps } from 'interface/SearchDropdownProps'
import styles from 'styles/components/SearchDropdown.module.sass'
import { Icon } from '@iconify/react';
import arrowDownIcon from '@iconify/icons-mdi/chevron-down';

/**
 * SearchDropdown component renders a dropdown menu with searchable options.
 * 
 * @component
 * 
 * @param {Object} props - The props for configuring the SearchDropdown.
 * @param {Array<{ id: string, name: string }>} props.options - The list of options to display in the dropdown.
 * @param {string} props.buttonName - The label text for the dropdown button.
 * @param {string} props.placeholder - Placeholder text for the input field.
 * @param {string} props.buttonColor - Color of the dropdown button and border.
 * @param {string} [props.buttonContentColor='#fff'] - Text color for the button content.
 * @param {function} props.onSelect - Callback function invoked when an option is selected, passing the selected option's ID.
 * 
 * @example
 * <SearchDropdown 
 *   options={[{ id: '1', name: 'Option 1' }, { id: '2', name: 'Option 2' }]}
 *   buttonName="Select an Option"
 *   placeholder="Search options..."
 *   buttonColor="#3498db"
 *   onSelect={(id) => console.log(`Selected option ID: ${id}`)}
 * />
 */
const SearchDropdown: React.FC<SearchDropdownProps> = ({ options, buttonName, placeholder, buttonColor, buttonContentColor = '#fff', onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null)

    const handleOptionClick = (id: string) => {
        setSelectedOption(id)
        onSelect(id)
        setIsOpen(false)
    };


    return (
        <div className={styles['search-drop-down']} 
            onClick={() => setIsOpen(!isOpen)}
            style={{ border: `1px solid ${buttonColor}`}}>
            <input
                type='text'
                placeholder={placeholder}
                value={selectedOption ? options.find(option => option.id === selectedOption)?.name : ''}
                readOnly
                className={styles['input-field']}
            />
            <div
                className={styles['dropdown-button']}
                style={{
                    backgroundColor: buttonColor,
                    borderLeftColor: buttonColor,
                    color: buttonContentColor || '#fff'
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className={styles['button-name']}>{buttonName}</span>
                <Icon icon={arrowDownIcon} className={styles['button-icon']} />
            </div>
            {isOpen && (
                <div 
                    className={`${styles['dropdown-menu']} ${isOpen ? styles['open'] : ''}`}
                    style={{ border: `1px solid ${buttonColor}`}}>
                        {options.map(option => (
                            <div
                                key={option.id}
                                onClick={() => handleOptionClick(option.id)}
                                className={styles['dropdown-item']}>
                                    {option.name}
                            </div>
                        ))}
                </div>
            )}
        </div>
    )
}

export default SearchDropdown;