import React, { useRef, useEffect } from 'react';
import masterCardLogo from 'assets/images/png/mastercard-foundation.png';
import paystackLogo from 'assets/images/png/paystack.png';
import ubcLogo from 'assets/images/png/uo-british-columbia.png';
import microsoftStartupLogo from 'assets/images/png/microsoft-for-startups.png';
import charinapLogo from 'assets/images/png/charinap-change-foundation.png';
import theGracedLifeLogo from 'assets/images/png/the-graced-life.png';
import blackBusinessBCLogo from 'assets/images/png/black-business-association-bc.png';
import styles from 'styles/layout/TrustedBySection.module.sass';

/**
 * Represents a trusted company with a name, logo, and link to its website.
 * 
 * @property {number} id - A unique identifier for the company.
 * @property {string} name - The name of the company.
 * @property {string} logo - The path to the company's logo image.
 * @property {string} url - The URL to the company's website.
 * @property {string} [sizeClass] - Optional CSS class to control the size of the logo.
 * @property {number} width - The width of the logo.
 */
interface TrustedCompany {
    id: number;
    name: string;
    logo: string;
    url: string;
    sizeClass?: string;
    width: number;
}

/**
 * List of trusted companies to be displayed.
 * Each company contains an id, name, logo, url, and optional size class and width.
 */
const trustedCompanies: TrustedCompany[] = [
    { id: 1, name: "Mastercard Foundation", logo: masterCardLogo, url: "https://www.mastercard.us/en-us.html", width: 300 },
    { id: 2, name: "Paystack", logo: paystackLogo, url: "https://paystack.com/", width: 300 },
    { id: 3, name: "The University of British Columbia", logo: ubcLogo, url: "https://www.ubc.ca/", width: 300 },
    { id: 4, name: "Microsoft for Startups", logo: microsoftStartupLogo, sizeClass: 'small', url: "https://startups.microsoft.com/", width: 300 },
    { id: 5, name: "Charinap Change Foundation", logo: charinapLogo, sizeClass: 'small', url: "#", width: 194 },
    { id: 6, name: "The Gracedlife Leadership Centre", logo: theGracedLifeLogo, sizeClass: 'llclogo', url: "#" , width: 194},
    { id: 7, name: "Black Business Association of BC", logo: blackBusinessBCLogo, url: "#", width: 300 }
  ];

/**
 * TrustedLogos component displays logos of trusted companies with a scrolling animation.
 *
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import React from 'react';
 * import TrustedBySection from 'layout/section/TrustedBySection';
 * 
 * const App: React.FC = () => {
 *     return (
 *         <div>
 *             <h1>Welcome to Zerone</h1>
 *             <p>We are trusted by the following companies:</p>
 *             <TrustedBySection />
 *         </div>
 *     );
 * };
 * 
 * export default App;
 * ```
 * 
 * @returns {JSX.Element} The JSX element rendering the logos.
 */
const TrustedBySection: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollContainer = containerRef.current;

    if (scrollContainer) {
      /**
       * Calculate total scroll distance based on the combined widths of the logos.
       * Fallback width is used if the logo's width is not defined.
       */
      const totalScrollWidth = trustedCompanies.reduce(
        (acc, company) => acc + company.width || 300,
        0
      );

      /**
       * Set Animation duration and total scroll width.
       */
      const animationDuration = '20s';

      scrollContainer.style.setProperty('--scroll-duration', animationDuration);
      scrollContainer.style.setProperty('--scroll-width', `${totalScrollWidth}px`);

      // Start scrolling animation by adding 'start-scrolling' class.
      scrollContainer.classList.add('start-scrolling');
    }

    return () => {
      // Cleanup function to remove the animation class when the component unmounts
      if (scrollContainer) {
        scrollContainer.classList.remove('start-scrolling');
      }
    };
  }, []);

  return (
    <section className={styles['trusted-logos-section']}>
      <h2 className={styles['section-title']}>Trusted By</h2>
      <div ref={containerRef} className={styles['logos-container']}>
        {trustedCompanies.map((company) => (
          <div
            key={company.id}
            className={`${styles['logo-item']} ${company.sizeClass ? styles[company.sizeClass] : ''}`}
            style={{ backgroundImage: `url(${company.logo})` }}
            aria-label={`${company.name} logo`}
          />
        ))}
      </div>
    </section>
  );
};

export default TrustedBySection;