import SalesSolutionCard from 'components/cards/SalesSolutionCard';
import React from 'react'
import styles from 'styles/layout/ContactSalesSection.module.sass'
import puzzleImage from 'assets/images/png/puzzle-piece.png'

/**
 * ContactSalesSection component displays a section with a title, subtitle,
 * and a SalesSolutionCard component to encourage users to contact sales for custom solutions.
 *
 * @component
 *
 * @example
 * // Renders the ContactSalesSection component
 * <ContactSalesSection />
 *
 * @see SalesSolutionCard - Component for displaying contact sales call-to-action.
 */
const ContactSalesSection: React.FC = () => {
    return (
        <div className={styles['contact-sales-section']}>
            <div className={styles['title-wrapper']}>
                <h2 className={styles['section-title']}>WHAT BEST SUITS YOU AND YOUR TEAM</h2>
                <h2 className={styles['section-subtitle']}>
                    Pricing for everyone
                </h2>
            </div>
            <SalesSolutionCard 
                openingText={'WE ARE OPEN TO TALK'} 
                mainTitle={'Can\'t find the perfect plan?'} 
                descriptionText={'Reach out to us with your exact requirements so that we can build a custom solution for your special needs.'}
                buttonText={'Talk to sales'}
                bannerImage={puzzleImage}/>
        </div>
    )
}

export default ContactSalesSection;