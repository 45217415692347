import React from 'react';
import styles from 'styles/layout/ComparePlansSection.module.sass'
import PlansTable from 'components/tables/PlansTable';
import SectionHeader from 'components/common/SectionHeader';

/**
 * ComparePlansSection component renders a section with a title, subtitle,
 * a plans comparison table, and a promotional note.
 * 
 * This section includes a responsive design where the section adjusts
 * font sizes on tablet screens and hides itself on smaller devices.
 * The `SectionHeader` component is used for consistent title and subtitle styling.
 *
 * @component
 * @example
 * <ComparePlansSection />
 *
 * @returns {JSX.Element} The rendered ComparePlansSection component.
 */
const ComparePlansSection: React.FC = () => {
  return (
    <section className={styles['compare-plans-section']}>
      <SectionHeader
        title='Find the best plan'
        subtitle='Compare our plans'
      />
      <PlansTable />
      <p className={styles['note']}>**10% off if prepaid for the whole year</p>
    </section>
  );
};

export default ComparePlansSection;
