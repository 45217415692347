import { SectionData } from 'interface/BetaSectionData';
import React, { useEffect, useState } from 'react'
import styles from 'styles/components/ZeroneBotBeta.module.sass'
import { fiveGReport } from 'constants/data/beta/FiveGReport';
import { logError } from 'services/logService/errorLogger';
import BouncingLoader from './BouncingLoader';
import InteractiveChart from './InteractiveChart';

interface ZeroneBotBetaProps {
    selectedPromptId: string | null;
    selectedTopicId: string | null;
}

/**
 * ZeroneBotBeta component displays a dynamically loaded report based on selected prompts and topics.
 * 
 * @component
 * 
 * @param {Object} props - The props for configuring ZeroneBotBeta.
 * @param {string | null} props.selectedPromptId - The ID of the selected prompt, used to determine the report section to display.
 * @param {string | null} props.selectedTopicId - The ID of the selected topic, determining the data context.
 * 
 * @example
 * <ZeroneBotBeta selectedPromptId="concerns" selectedTopicId="5g" />
 * // Displays a report on concerns for the 5G topic.
 */
const ZeroneBotBeta: React.FC<ZeroneBotBetaProps> = ({ selectedPromptId, selectedTopicId }) => {
    const [data, setSelectedData] = useState<SectionData | null>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (selectedTopicId && selectedPromptId) {
            if (selectedPromptId === 'concerns' || selectedPromptId === 'opportunities') {
                setSelectedData(fiveGReport[selectedPromptId]);
            } else {
                logError(
                    "Invalid selectedTopicId",
                    { selectedTopicId, selectedPromptId },
                    "ZeroneBotBeta.tsx"
                );
                setSelectedData(null);
            }
        }
    }, [selectedTopicId, selectedPromptId]);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 3000); // 3-seconds delay
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={styles['zerone-bot-beta-container']}>
            {loading ? (
                <div className={styles['loader-wrapper']}>
                    <BouncingLoader text="Generating Report..." />
                </div>
            ) : (
                <div className={styles['report']}>
                    <p className={styles['report-description']}>{data?.description}</p>
                    <div className={styles['report-content']}>
                        {data?.topics.map((topic, index) => (
                            <p key={index} className={styles['report-paragraph']}>
                                <strong>{topic.name}</strong>: {topic.details}
                            </p>
                        ))}
                    </div>
                    {data?.chartData && (
                        <div className={styles['report-chart']}>
                            <InteractiveChart
                                title={data.chartData.title} 
                                chartType={data.chartData.chartType}
                                labels={data.chartData.labels}
                                values={data.chartData.values}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default ZeroneBotBeta;