import { DynamicInfo } from "interface/DynamicInfo";

/**
 * Represents the product offerings of the organization as a `DynamicInfo` object.
 * 
 * This export defines the key products and services provided, including "AI Analytics Workspace," 
 * "Industry & Consumer Reports," "Data Collection," and "Advisory Services." Each offering is 
 * described with a unique ID, icon, title, and detailed description.
 * 
 * @constant
 * @type {DynamicInfo}
 * 
 * @property {UUID} id - Unique identifier for the product offerings section.
 * @property {string} title - The main title for the product offerings section.
 * @property {string} subtitle - A subtitle providing context for the section.
 * @property {DynamicCardInfo[]} items - An array of objects representing each product offering, 
 * including metadata such as ID, icon, title, and description.
 * 
 * @example
 * import { productOfferings } from 'constants/data/about/ProductOfferings';
 * 
 * console.log(productOfferings.title); // Outputs: "WHAT WE DO"
 * console.log(productOfferings.items[0].title); // Outputs: "AI Analytics Workspace"
 */
export const productOfferings: DynamicInfo = {
    id: "1d31e690-a658-4fb5-bd72-2bf78c8a6bbe",
    title: "WHAT WE DO",
    subtitle: "Product & Offerings",
    items: [
        {
            id: "e831558e-ef17-43cc-97a3-c0756a5006db",
            icon: "ep:data-analysis",
            title: "AI Analytics Workspace",
            description: "Unlock AI-powered data analytics and visualization to analyze data, create visuals, and generate reports. Leverage natural language processing (NLP) to craft charts, graphics, and narratives using simple commands. Choose from tailored subscription packages for access."
        },
        {
            id: "d5bbbd1b-846b-478f-9415-3d75c7a8cc23",
            icon: "fluent-mdl2:report-document",
            title: "Industry & Consumer Reports",
            description: "Explore data-rich research reports on various industries and consumer trends across the African market. Access our comprehensive report catalog on demand or contract personalized reports tailored to any industry, product, service, or consumer behavior within the Africa market."
        },
        {
            id: "1d31e690-a658-4fb5-bd72-2bf78c8a6bbe",
            icon: "icon-park-outline:data-user",
            title: "Data Collection",
            description: "Gather impactful data with the ZeroneData app from a dynamic community of changemakers. Whether for research, surveys, or quizzes, tailor and target your audience with precision."
        },
        {
            id: "d505e471-908c-4ece-a24e-46f452dd7119",
            icon: "healthicons:medical-advice",
            title: "Advisory Services",
            description: "Advisory services on step-by-step entry/expansion into Africa, starting with Ghana, our ignite hub."
        }
    ]
}