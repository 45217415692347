import { BlogContent } from "interface/BlogContent";
import { illegalMining } from "./IllegalMining";
import { starlinkGhana } from "./StarLinkGhana";
import { kwameNkurumah } from "./KwameNkurumah";

/**
 * An array of blog content objects representing predefined blogs for Zerone.
 * 
 * @constant
 * @type {BlogContent[]}
 * 
 * @example
 * // Access the list of predefined blogs
 * console.log(zeroneBlogs);
 */
export const zeroneBlogs: BlogContent[] = [
    illegalMining,
    starlinkGhana,
    kwameNkurumah
]