import React from 'react';
import AnimatedBackgroundContainer from 'components/common/AnimatedBackgroundContainer';
import AccountTypeForm from 'components/forms/AccountTypeForm';
import styles from 'styles/pages/AccountTypePage.module.sass';

const AccountTypePage: React.FC = () => {
  return (
    <div className={styles['account-page']}>
      <AnimatedBackgroundContainer showFooter={true}>
        <AccountTypeForm />
      </AnimatedBackgroundContainer>
      
    </div>

  );
};

export default AccountTypePage;
