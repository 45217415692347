import React from 'react';
import styles from 'styles/components/PlansTable.module.sass';
import checkIcon from 'assets/images/svg/check.svg'; 

interface TableRow {
  feature: string;
  basic: JSX.Element | string;
  sapphire: JSX.Element | string;
  gold: JSX.Element | string;
  platinum: JSX.Element | string;
}

const tableData: TableRow[] = [
  {
    feature: 'Access to AI-Powered Workspace',
    basic: <img src={checkIcon} alt="Check" className={styles['check-icon']} />, 
    sapphire: <img src={checkIcon} alt="Check" className={styles['check-icon']} />,
    gold: <img src={checkIcon} alt="Check" className={styles['check-icon']} />,
    platinum: <img src={checkIcon} alt="Check" className={styles['check-icon']} />,
  },
  {
    feature: 'No. of Workspace Seats',
    basic: '1',
    sapphire: '5',
    gold: 'Up to 10',
    platinum: 'Up to 50',
  },
  {
    feature: 'No. of Workspace Projects per User by Month',
    basic: '2',
    sapphire: '5',
    gold: '7',
    platinum: 'Unlimited',
  },
  {
    feature: 'Access to Reports',
    basic: '1 report credit per month',
    sapphire: '3 report credits per month',
    gold: '7 report credits per month',
    platinum: 'Unlimited access to all reports',
  },
  {
    feature: 'On Demand Training', 
    basic: <img src={checkIcon} alt="Check" className={styles['check-icon']} />, 
    sapphire: <img src={checkIcon} alt="Check" className={styles['check-icon']} />,
    gold: <img src={checkIcon} alt="Check" className={styles['check-icon']} />,
    platinum: <img src={checkIcon} alt="Check" className={styles['check-icon']} />,
  },
];

const PlansTable: React.FC = () => {
  return (
    <div className={styles['table-container']}>
      <table className={styles['compare-plans-table']}>
        <thead>
          <tr>
            <th></th>
            <th>Basic</th>
            <th>Sapphire</th>
            <th>Gold</th>
            <th>Platinum</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>{row.feature}</td>
              <td className={index === 3 ? styles['left-align'] : ''} >{row.basic}</td>
              <td className={index === 3 ? styles['left-align'] : ''}>{row.sapphire}</td>
              <td className={index === 3 ? styles['left-align'] : ''}>{row.gold}</td>
              <td className={index === 3 ? styles['left-align'] : ''}>{row.platinum}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlansTable;