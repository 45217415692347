import { SectionData } from "interface/BetaSectionData";

interface FiveGReportProps {
    concerns: SectionData;
    opportunities: SectionData;
}

/**
 * fiveGReport object defines the structure of concerns and opportunities related to 5G deployment in Ghana.
 * 
 * @constant
 * 
 * @type {Object}
 * @property {SectionData} concerns - Key concerns about 5G, covering financial, privacy, and health-related issues.
 * @property {SectionData} opportunities - Opportunities presented by 5G, including advancements in FinTech, e-commerce, logistics, and smart city development.
 * 
 * @example
 * console.log(fiveGReport.concerns.description);
 * // Output: "While 5G technology promises significant advancements, its deployment in Ghana has sparked important public concerns..."
 * 
 * @see SectionData - Interface representing the structure for section data used in both concerns and opportunities.
 */
export const fiveGReport: FiveGReportProps = {
  concerns: {
    description: "While 5G technology promises significant advancements, its deployment in Ghana has sparked important public concerns. Key issues surrounding financial accessibility, data privacy, and potential health risks could impact public confidence and adoption. Addressing these concerns is essential for fostering a safe, inclusive, and transparent rollout of 5G across the country.",
    topics: [
      {
        name: "Cost of 5G Devices and Services",
        details: "The high cost of 5G-capable devices and data plans is a major barrier, particularly for consumers facing financial constraints. Ensuring affordability is crucial to making 5G accessible to all Ghanaians."
      },
      {
        name: "Privacy and Data Security",
        details: "Privacy concerns, including potential tracking and increased data usage, have raised calls for stronger data protection measures. Public confidence depends on clear, robust security policies to safeguard personal information."
      },
      {
        name: "Health Risks",
        details: "Concerns about the potential health effects of 5G radiation, though less widespread, underscore the importance of transparent communication. Providing clear information on health and safety standards is vital to addressing these apprehensions."
      }
    ],
    chartData: {
      chartType: "bar",
      title: "Public Concerns about 5G Deployment in Ghana",
      labels: ["Cost of Devices", "Privacy & Security", "Health Risks"],
      values: [50, 25, 10]
    }
  },
  opportunities: {
      description: "The arrival of 5G technology brings vast possibilities for Ghana, promising to transform industries and fuel growth. With faster speeds and minimal delays, 5G is more than an upgrade—it’s a tool to drive innovation, improve services, and open doors to new opportunities. From boosting financial technology to advancing urban development, 5G can reshape daily life and empower businesses, helping Ghana build a more connected, forward-looking future.",
      topics: [
        {
          name: "Financial Technology (FinTech) Expansion",
          details: "The integration of 5G could significantly advance the FinTech sector by enabling faster and more secure financial transactions, thus promoting financial inclusion and supporting a digital economy."
        },
        {
          name: "Growth in E-commerce",
          details: "Improved connectivity through 5G is expected to bolster the e-commerce industry, providing small and large businesses with opportunities to expand their reach and streamline online services."
        },
        {
          name: "Logistics and Transportation Management",
          details: "5G’s high-speed networks have the potential to optimize logistics and transportation systems, enhancing efficiency and reducing operational costs within supply chain management."
        },
        {
          name: "Development of Smart Cities",
          details: "The Internet of Things (IoT) capabilities enabled by 5G could drive the development of smart cities in Ghana, allowing for more efficient urban planning and resource management in metropolitan areas."
        }
      ],
      chartData: {
        chartType: "pie",
        title: "Public Views on 5G Business Opportunities in Ghana",
        labels: ["FinTech Expansion", "E-commerce Growth", "Logistics", "Smart Cities"],
        values: [30, 25, 20, 15]
    }
  }
}