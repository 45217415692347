import React from 'react'
import styles from 'styles/components/CurrencySelector.module.sass'
import { CurrencySelectorProps } from 'interface/CurrencySelectorProps'
import RadioButtonGroup from 'components/navigation/RadioButtonGroup'

/**
 * CurrencySelector component provides a UI for selecting a currency from a list of available options.
 * 
 * @component
 * 
 * @param {Object} props - The props for configuring the CurrencySelector.
 * @param {string[]} props.availableCurrencies - An array of available currency options.
 * @param {string} props.selectedCurrency - The currently selected currency.
 * @param {function} props.setSelectedCurrency - A function to update the selected currency when the user makes a selection.
 * 
 * @example
 * // Renders CurrencySelector with available currencies and handles currency selection
 * <CurrencySelector 
 *   availableCurrencies={['USD', 'EUR', 'JPY']} 
 *   selectedCurrency="USD" 
 *   setSelectedCurrency={(currency) => console.log(`Selected: ${currency}`)}
 * />
 */
const CurrencySelector: React.FC<CurrencySelectorProps> = ({
    availableCurrencies,
    selectedCurrency,
    setSelectedCurrency,
}) => {

    return (
        <div className={styles['currency-selector']}>
            <label className={styles['label']}>Select Currency:</label>
            <RadioButtonGroup
                options={availableCurrencies}
                selectedOption={selectedCurrency}
                onOptionChange={setSelectedCurrency}
            />
        </div>
    )
}

export default CurrencySelector;