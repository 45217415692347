import SearchBar from 'components/common/SearchBar';
import TabSelector from 'components/navigation/TabSelector';
import React from 'react'
import styles from 'styles/components/BlogContainerNavigation.module.sass'

/**
 * Interface for BlogContainerNavigationProps defining the properties accepted by the BlogContainerNavigation component.
 * 
 * @interface BlogContainerNavigationProps
 * 
 * @property {string[]} tabs - Array of strings representing the available tabs for navigation.
 * @property {string} activeTab - The currently active tab.
 * @property {function} onTabChange - Callback function triggered when the active tab is changed.
 * @property {function} handleSearch - Callback function triggered when a search is performed in the search bar.
 */
interface BlogContainerNavigationProps {
    tabs: string[];
    activeTab: string;
    onTabChange: (tab: string) => void;
    handleSearch: (query: string) => void;
}

/**
 * BlogContainerNavigation component provides navigation controls for a blog container, including a tab selector and search bar.
 * 
 * @component
 * 
 * @param {BlogContainerNavigationProps} props - Props for configuring the BlogContainerNavigation component.
 * @param {string[]} props.tabs - Array of strings representing the available tabs for navigation.
 * @param {string} props.activeTab - Currently active tab.
 * @param {function} props.onTabChange - Function to call when changing tabs.
 * @param {function} props.handleSearch - Function to handle search queries from the search bar.
 * 
 * @example
 * // Example usage of BlogContainerNavigation component
 * <BlogContainerNavigation
 *   tabs={['All', 'Trending', 'Technology']}
 *   activeTab="All"
 *   onTabChange={(tab) => console.log(`Selected Tab: ${tab}`)}
 *   handleSearch={(query) => console.log(`Search Query: ${query}`)}
 * />
 */
const BlogContainerNavigation: React.FC<BlogContainerNavigationProps> = ({ tabs, activeTab, onTabChange, handleSearch }) => {
    return (
        <div className={styles['blog-container-navigation']}>
            <TabSelector 
                tabs={tabs} 
                activeTab={activeTab} 
                onTabChange={onTabChange} 
            />
            <SearchBar
                placeholder='Search blogs' 
                onSearch={handleSearch} />
        </div>
    )
}

export default BlogContainerNavigation;