import SearchDropdown from 'components/common/SearchDropdown';
import ZeroneBotBeta from 'components/common/ZeroneBotBeta';
import React, { useState } from 'react'
import styles from 'styles/components/ZeroneBotInsightForm.module.sass'
import { topics } from 'constants/insights/ExampleTopics'
import PromptDisplay from 'components/common/PromptDisplay';
import zeroneBotImage from 'assets/images/svg/zerone-bot-3-d-2.svg'
import { Prompt } from 'interface/Prompt';

/**
 * ZeroneBotInsightForm component provides an interactive form for users to choose a research topic and generate insights with ZeroneBot.
 * 
 * @component
 * 
 * @example
 * // Renders the ZeroneBotInsightForm component
 * <ZeroneBotInsightForm />
 * 
 * @see SearchDropdown - Dropdown component for selecting a topic
 * @see ZeroneBotBeta - Component that generates a detailed report based on selected prompt and topic
 * @see PromptDisplay - Displays a list of prompts for the chosen topic, allowing users to select one
 * 
 * @description
 * Users first select a topic, then a prompt to define how they want the insights to be presented.
 * Once both a topic and prompt are selected, ZeroneBotBeta displays the generated insights report.
 * If no prompt or topic is selected, an introductory image and instructions are shown.
 */
const ZeroneBotInsightForm: React.FC = () => {
    const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
    const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);

    const handleSelect = (id: string) => {
        setSelectedTopic(id)
        setSelectedPrompt(null)
        console.log(`Selected topic ID: ${selectedTopic}`);
    };

    const selectedPrompts = selectedTopic
        ? topics.find(topic => topic.id === selectedTopic)?.prompts.map(prompt => ({ id: prompt.id, description: prompt.description })) || []
        : [];


    const handleGenerateInsights = (prompt: { id: string; description: string } | null) => {
        if (selectedTopic && prompt) {
            setSelectedPrompt(prompt)
        }
    }

    return (
        <div className={styles['zerone-bot-insight-form']}>
            {!selectedPrompt && !selectedTopic && (
                <img src={zeroneBotImage}  className={styles['bot-image']}/>
            )}
            <div className={styles['right-wrapper']}>
                <div className={styles['text-wrapper']}>
                    <p className={styles['call-for-action']}>Try out our new AI Analyst <span className={styles['highlight']}>ZeroneBot.</span></p>
                    <p className={styles['zerone-bot-desc']}>Choose a topic and tell <span className={styles['highlight']}>ZeroneBot</span> how you would your report to be presented.</p>
                </div>
                <div className={styles['dropdown-wrapper']}>
                    <SearchDropdown
                        options={topics.map(topic => ({ id: topic.id, name: topic.name }))}
                        buttonName='Choose Topic'
                        placeholder='What is your research about?'
                        buttonColor='#136a97'
                        onSelect={handleSelect}
                    />
                </div>
                {selectedPrompt && selectedTopic ? (
                    <ZeroneBotBeta
                        selectedPromptId={selectedPrompt.id}
                        selectedTopicId={selectedTopic}
                    />
                ) : (
                    <PromptDisplay
                        prompts={selectedPrompts}
                        hint="A prompt will be generated based on the example you choose."
                        onGenerate={handleGenerateInsights}
                    />
                )}
            </div>
        </div>
    )
}

export default ZeroneBotInsightForm;