import { fetchBlogPost } from 'api/BlogApiService'
import BlogHeaderSection from 'components/layout/blog/BlogHeaderSection'
import FooterMain from 'components/layout/footer/FooterMain'
import { BlogContent } from 'interface/BlogContent'
import BlogPostContentSection from 'layouts/section/BlogPostContentSection'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from 'styles/pages/BlogPage.module.sass'

const TAG = 'BlogPostPage'

/**
 * BlogPostPage component - Displays a single blog post, including header, content, and footer sections.
 * Fetches and renders the blog post based on the `id` parameter from the URL.
 *
 * @component
 * @returns {JSX.Element} The rendered BlogPostPage component.
 *
 * @example
 * // Example usage:
 * <Route path="/blog/:id" element={<BlogPostPage />} />
 */
const BlogPostPage: React.FC = () => {
    const [blog, setBlog] = useState<BlogContent | null>(null)
    const { id } = useParams<{ id: string }>()

    useEffect(() => {
        const loadBlogPost = async () => {
            if  (id) {
                try {
                    const fetchedTopic = await fetchBlogPost({ tag: TAG, id})
                    setBlog(fetchedTopic)
                } catch {
                    setBlog(null)
                }
            }
        }

        loadBlogPost()
    }, [])

    return (
        <div className={styles['blog-post-page']}>
            <BlogHeaderSection topic={blog} />
            <BlogPostContentSection blog={blog} />
            <FooterMain />
        </div>
    )
}

export default BlogPostPage;