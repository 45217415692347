import { Feature } from "interface/Feature"
import defaultFeatBgImage from 'assets/images/svg/graph-background-feature.svg'
import featureImage from 'assets/images/svg/zerone-bot-3-d-2.svg'

/**
 * ZeroneBot feature object.
 * 
 * @type {Feature}
 * @property {IconifyIcon} icon - The icon for the feature, using the Iconify icon.
 * @property {boolean} isIcon - Determines whether the feature uses an icon.
 * @property {string} iconWrapperBgColor - Background color of the icon wrapper.
 * @property {string} featureImage - The path to the image that will be displayed.
 * @property {string} hoverBackgroundImage - Background image that appears on hover.
 * @property {string} hoverBackground - The gradient background applied on hover.
 * @property {string} title - The title of the feature (ZeroneBot).
 * @property {string} description - The description of the feature, explaining its purpose.
 * @property {string} defaultColor - Default color for the icon or text.
 * @property {string} hoverColor - Color applied when the feature is hovered.
 * @property {string} [specialText] - Special text to highlight important information (e.g., "New").
 */
export const zeroneBotFeat : Feature = {
    icon: "hugeicons:market-analysis",
    isIcon: true,
    iconWrapperBgColor: '#FFFBFA',
    featureImage: featureImage,
    hoverBackgroundImage: defaultFeatBgImage,
    hoverBackground: "linear-gradient(224.8deg, #fff, #d1efff)",
    title: 'ZeroneBot',
    description: 'Meet ZeroneBot, your AI analyst for instant insights. Use chat prompts to create reports and visualizations, tailored to your needs within our Workspace.',
    defaultColor: '#707070',
    hoverColor: '#C62E00',
    specialText: 'New'
}