import React, { ChangeEvent } from 'react'
import styles from 'styles/components/InputField.module.sass'

/**
 * Props for the InputField component.
 * 
 * @interface InputFieldProps
 * @property {string} label - The label text displayed for the input field.
 * @property {'email' | 'password' | 'text' | 'tel' | 'number'} type - The type of input field (e.g., email, password).
 * @property {string} value - The current value of the input field.
 * @property {(value: string) => void} onChange - Function to handle changes in the input field's value.
 * @property {string} [placeholder] - Optional placeholder text for the input field.
 * @property {boolean} required - Whether the input field is required.
 */
interface InputFieldProps {
    label: string;
    type: 'email' | 'password' | 'text' | 'tel' | 'number'| 'textarea';
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    required: boolean;
    rows?: number;
}

/**
 * InputField component renders an input element with a label and supports various input types.
 * 
 * This component provides input tracking via the `onChange` function prop, displays a label, 
 * and includes optional placeholder text. It can also mark the input as required.
 * 
 * @component
 * @param {InputFieldProps} props - The props for the InputField component.
 * @param {string} props.label - The label text displayed for the input field.
 * @param {'email' | 'password' | 'text' | 'tel' | 'number'} props.type - The type of input field.
 * @param {string} props.value - The current value of the input field.
 * @param {(value: string) => void} props.onChange - Function to handle changes in the input value.
 * @param {string} [props.placeholder] - Optional placeholder text for the input field.
 * @param {boolean} props.required - Whether the input field is required.
 * 
 * @example
 * Example usage:
 * 
 * ```tsx
 * import InputField from 'components/common/InputField';
 * 
 * const [email, setEmail] = useState('');
 * 
 * return (
 *   <InputField
 *     label="Email"
 *     type="email"
 *     value={email}
 *     onChange={setEmail}
 *     placeholder="Enter your email"
 *     required={true}
 *   />
 * );
 * ```
 * 
 * @returns {JSX.Element} The JSX element for the input field.
 */
const InputField: React.FC<InputFieldProps> = ({ label, type, value, onChange, placeholder = '', required , rows = 5 }) => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(e.target.value)
    }

    return (
        <div className={styles['input-container']}>
            <label htmlFor={label} className={styles['label']}>
                {label}{required && (<span className={styles['required']}>*</span>)}
            </label>
            {type === 'textarea' ? (
                <textarea
                    aria-label={label}
                    value={value}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    required={required}
                    className={styles['textarea-field']}
                    rows={rows}
                />
            ) : (
                <input
                    aria-label={label}
                    type={type}
                    value={value}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    required={required}
                    className={styles['input-field']} 
                />
            )}
        </div>
    )
}

export default InputField;