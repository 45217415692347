import React from 'react'
import styles from 'styles/layout/AboutUsHeaderSection.module.sass'
import HeaderNavigation from '../header/HeaderNavigation';
import SectionHeader from 'components/common/SectionHeader';
import ExperienceMetricsCard from 'components/cards/ExperienceMetricsCard';

/**
 * AboutUsHeaderSection component renders the header section for the "About Us" page.
 * 
 * This section includes:
 * - A navigation header with a dark theme.
 * - A title and subtitle describing the mission and vision of the platform.
 * - A hero description highlighting the core focus of the organization.
 * - An experience metrics card showcasing key achievements and focus areas.
 * - A mission and vision statement emphasizing the importance of data analytics for African markets.
 * 
 * @component
 * 
 * @example
 * // Example usage of the AboutUsHeaderSection component
 * import AboutUsHeaderSection from 'components/layout/AboutUsHeaderSection';
 * 
 * const App: React.FC = () => (
 *   <AboutUsHeaderSection />
 * );
 * 
 * export default App;
 */
const AboutUsHeaderSection: React.FC = () => {
    return (
        <section className={styles['header-section']}>
            <div className={styles['main-wrapper']}>
                <HeaderNavigation theme='dark' />
                <div className={styles['background-container']}>
                    <SectionHeader
                        title='WHO WE ARE'
                        subtitle='The go-to hub for everything data on Africa.' 
                    />
                    <p className={styles['hero-description']}>We have an extensive collection of reports, market insights and topics sourced from various industries on the African continent. As a statement of vision, we envision a future where we are the trusted partner in delivering advanced analytics and intelligent solutions pertaining to the African market.</p>
                    <ExperienceMetricsCard />
                    <p className={styles['mission-vision']}>
                    <span className={styles['highlight']}>Empowering decisions with data and analytics.</span> We see a future where non-aggregated
                    consumer and industry-level data about the African market is generally available for use by
                    multiple stakeholders within and outside the borders of Africa. We envision a world where
                    individuals, corporations and organizations have access to culturally relevant African data
                    needed for making both simple everyday choices and complex business and economic
                    decisions.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default AboutUsHeaderSection;