import { getBlogs, getHotTopic, getBlogPost } from "./endpoints/BlogEndpoints";
import { getReportCategories, getTopHotReports, getReport } from "./endpoints/ReportEndpoints";


/**
 * Interface for available blog-related endpoints.
 * 
 * @interface BlogEndpoints
 * @property {typeof getBlogs} getBlogs - Function for fetching blog data.
 * @property {typeof getHotTopic} getHotTopic - Function for fetching hot topc.
 * @property {typeof getBlogPost} getBlogPost - Function for fetching blog post.
 */
interface BlogEndpoints {
    getBlogs: typeof getBlogs;
    getHotTopic: typeof getHotTopic;
    getBlogPost: typeof getBlogPost;
}

/**
 * Interface for available report-related endpoints.
 * 
 * @interface ReportEndpoints
 * @property {typeof getReportCategories} getReportCategories - Function for fetching report categories.
 */
interface ReportEndpoints {
    getReportCategories: typeof getReportCategories;
    getTopHotReports: typeof getTopHotReports;
    getReport: typeof getReport;
}

/**
 * Main interface for all available endpoints.
 * 
 * @interface Endpoints
 * @extends BlogEndpoints
 * @extends ReportEndpoints
 */
interface Endpoints extends BlogEndpoints, ReportEndpoints {}

/**
 * Centralized object containing all endpoint functions.
 * This object provides easy access to different API endpoint functions throughout the application.
 * 
 * @constant
 * @type {Endpoints}
 * 
 * @example
 * // Import the endpoints object and use it to fetch blogs
 * import endpoints from './constants/api/endpoints';
 * 
 * async function fetchBlogData() {
 *   const result = await endpoints.getBlogs({ tag: 'BlogFetch' });
 *   if (result.error) {
 *     console.error('Error fetching blogs:', result.error);
 *   } else {
 *     console.log('Fetched blogs:', result.data);
 *   }
 * }
 * 
 * fetchBlogData();
 */
const endpoints: Endpoints = {
    getBlogs,
    getHotTopic,
    getBlogPost,
    getReportCategories,
    getTopHotReports,
    getReport
};

export default endpoints;