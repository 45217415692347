import ContactUs from 'components/layout/footer/ContactUs';
import FooterMain from 'components/layout/footer/FooterMain';
import HeaderNavigation from 'components/layout/header/HeaderNavigation';
import React from 'react';
import TermsSection from 'layouts/section/TermsSection'



const LegalPage: React.FC = () => (
  <div>
    <HeaderNavigation theme='dark'/>
    <TermsSection />
    <ContactUs/>
    <FooterMain/>

    
  </div>
);

export default LegalPage;