import ReportCategoryCard from 'components/cards/ReportCategoryCard';
import SectionHeader from 'components/common/SectionHeader';
import { ReportCategory } from 'interface/ReportCategory';
import React from 'react'
import styles from 'styles/components/ReportCategoryHero.module.sass'

/**
 * Props for the `ReportCategoryHero` component.
 * @typedef {Object} ReportCategoryHeroProps
 * @property {ReportCategory[]} categories - Array of report categories to display.
 */
interface ReportCategoryHeroProps {
    categories: ReportCategory[];
}

/**
 * A component that displays a hero section with a list of report categories.
 *
 * @param {ReportCategoryHeroProps} props - Component properties.
 * @param {ReportCategory[]} props.categories - The list of report categories to display in the hero section.
 * @returns {React.ReactElement} A rendered `ReportCategoryHero` component.
 * 
 * @example
 * <ReportCategoryHero 
 *  categories={[{ id: '1', title: 'Finance', meta: 'Latest financial reports' }]} 
 * />
 */
const ReportCategoryHero: React.FC<ReportCategoryHeroProps> = ({ categories }) => {
    return (
        <div className={styles['report-category-hero']}>
            <SectionHeader 
                title='FIND REPORTS BY CATEGORY'
                subtitle='Our Report Categories'
            />
            <div className={styles['categories-wrapper']}>
                {categories && categories.map((category) => (
                    <ReportCategoryCard
                        key={category.id} 
                        id={category.id} 
                        title={category.title} 
                        meta={category.meta} />
                ))}
            </div>
        </div>
    )
}

export default ReportCategoryHero;