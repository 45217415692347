import React from 'react'
import styles from 'styles/components/BouncingLoader.module.sass'

interface BouncingLoaderProps {
    primaryColor?: string;
    secondaryColor?: string;
    textColor?: string;
    text?: string;
}

/**
 * BouncingLoader component displays a pulsing loader animation with customizable colors and text.
 * 
 * @component
 * 
 * @param {Object} props - The props for configuring the BouncingLoader.
 * @param {string} [props.primaryColor='#FF5522'] - The primary color in the gradient of the bouncing ball.
 * @param {string} [props.secondaryColor='#136A97'] - The secondary color in the gradient of the bouncing ball.
 * @param {string} [props.textColor='#005581'] - The color of the loading text displayed below the bouncing ball.
 * @param {string} [props.text='Processing...'] - The text displayed beneath the loader.
 * 
 * @example
 * // Renders a BouncingLoader with default colors and text
 * <BouncingLoader />
 * 
 * @example
 * // Renders a BouncingLoader with custom colors and text
 * <BouncingLoader 
 *   primaryColor="#3498db" 
 *   secondaryColor="#2ecc71" 
 *   textColor="#e74c3c" 
 *   text="Loading your data..."
 * />
 */
const BouncingLoader: React.FC<BouncingLoaderProps> = ({
    primaryColor = '#FF5522', secondaryColor = '#136A97', textColor = '#005581', text = "Processing..."
}) => {
    return (
        <div className={styles['bouncing-loader']} style={{ color: textColor }}>
            <div
                className={styles['bounceball']}
                style={{
                    background: `linear-gradient(135deg, ${primaryColor}, ${secondaryColor})`
                }}
            ></div>
            <span className={styles['loading-text']}>{text}</span>
        </div>
    );
}

export default BouncingLoader;