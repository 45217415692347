import { Author } from "interface/Author";

/**
 * LindaArthur is an object representing the author Linda Arthur with her details.
 * 
 * @constant {Author}
 * 
 * @property {string} name - The name of the author.
 * @property {string} imageUrl - The URL of the author's profile image.
 * @property {string} role - The professional role or expertise of the author.
 * @property {string} href - The link to the author's LinkedIn profile.
 * 
 * @example
 * import { LindaArthur } from "path/to/author";
 * 
 * console.log(LindaArthur.name); // Outputs: "Linda Arthur"
 */
export const LindaArthur: Author = {
    name: "Linda Arthur",
    imageUrl: "https://media.licdn.com/dms/image/v2/D4D03AQGUg9DKmwt2Jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688647013904?e=1736985600&v=beta&t=dawl00ksB8KSIiIoh39M2lKDacjpjjQGCnvDTZsY2dE",
    role: "Data Analyst, Zerone Analytiqs",
    href: "https://www.linkedin.com/in/linda-arthur-a53b1b195/"
}