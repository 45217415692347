import PrimaryButton from 'components/common/PrimaryButton'
import ReusableIcon from 'components/common/ReusableIcon'
import { CategoryMeta } from 'interface/CategoryMeta';
import React from 'react'
import styles from 'styles/components/ReportCategoryHeader.module.sass'

/**
 * Props for the ReportCategoryHeader component.
 * 
 * @typedef {Object} ReportCategoryHeaderProps
 * @property {UUID} id - Unique identifier for the report category.
 * @property {string} title - The title of the report category displayed in the header.
 * @property {CategoryMeta} meta - Metadata for the category, including colors and icon details.
 */
interface ReportCategoryHeaderProps {
    id: UUID;
    title: string;
    meta: CategoryMeta;
}

/**
 * ReportCategoryHeader component displays a header for a report category,
 * including an icon, title, and a button to view all reports in the category.
 *
 * @component
 * @param {ReportCategoryHeaderProps} props - The properties for the component.
 * @param {UUID} props.id - Unique identifier for the report category.
 * @param {string} props.title - The title of the report category.
 * @param {CategoryMeta} props.meta - Metadata for the category, such as primary and secondary colors and an icon.
 *
 * @example
 * <ReportCategoryHeader
 *   id="fabd37f0-7bd9-413a-b782-cbc73fb01b00"
 *   title="Technology"
 *   meta={{
 *     icon: "mdi:robot",
 *     primaryColor: "#004264",
 *     secondaryColor: "#D8EFFF"
 *   }}
 * />
 *
 * @returns {JSX.Element} A component displaying the report category header with an icon, title, and view all reports button.
 */
const ReportCategoryHeader: React.FC<ReportCategoryHeaderProps> = ({ id, title, meta }) => {
     /**
     * Handles the click event for the "View All Reports" button.
     */
    function handleViewAllReports () {
        console.log(`View all reports on category: ${id}`);
    }

    return (
        <div className={styles['report-category-header']}>

            <div className={styles['category-icon']} style={{ backgroundColor: meta.secondaryColor }}>
                <ReusableIcon 
                    icon={meta.icon} 
                    className={styles['icon'] || 'icon'} 
                    color={meta.primaryColor} 
                />
            </div>

            <div className={styles['category-title-wrapper']}>
                <h2 className={styles['title']} style={{ color: meta.primaryColor }}>{title}</h2>
                <PrimaryButton
                    text={`View all ${title} Reports`}
                    width='auto'
                    primaryColor='#FF5522'
                    textColor='#FFFFFF'
                    hoverColor='#FFFFFF'
                    hoverTextColor='#FF5522'
                    doSomething={handleViewAllReports}
                />
            </div>
        </div>
    )
}

export default ReportCategoryHeader