import React from 'react';
import { ReusableIconProps } from 'interface/ReusableIconProps';
import { Icon, Icon as IconifyIcon } from '@iconify/react';

/**
 * ReusableIcon component for displaying an icon, either as an Iconify icon or an image.
 *
 * This component accepts an icon that can be either a URL string, an icon identifier (e.g., "mdi:sale"),
 * or an IconifyIcon object. It renders the icon appropriately based on its type:
 * - If `icon` is a string starting with (e.g., "mdi:", "cil:", "bi:", "solar:"), it renders using the IconifyIcon component.
 * - If `icon` is a URL string, it renders as an <img />.
 * - If `icon` is an IconifyIcon object, it renders using the IconifyIcon component.
 * - If the icon type is unrecognized, it returns null.
 *
 * @component
 * @param {ReusableIconProps} props - The props for the component.
 * @param {string | IconifyIcon} props.icon - The icon to display. It can be a URL string, an icon identifier (e.g., "mdi:sale"), or an IconifyIcon object.
 * @param {string} [props.altText='icon'] - Optional alt text for accessibility when the icon is an image URL.
 * @param {string} [props.className='icon'] - Optional class name for applying custom styles to the icon.
 *
 * @returns {JSX.Element | null} The rendered icon component or null if the icon type is unrecognized.
 *
 * @example
 * <ReusableIcon icon="mdi:home" className={style['custom-icon']} />
 * <ReusableIcon icon="/path/to/image.png" altText="Home icon" {style['custom-icon']} />
 */
const ReusableIcon: React.FC<ReusableIconProps> = ({ 
    icon,
    altText = 'icon',
    className = 'icon',
    color
}) => {
    // Valid icon prefixes
    const validIconPrefixes = ['mdi:', 'cil:', 'bi:', 'solar:', 'streamline:', 'dashicons:', 'healthicons:', 'ep:', 'fluent-mdl2:', 'icon-park-outline:'];

    const isIconifyIcon = 
        typeof icon === 'string' && 
        validIconPrefixes.some(prefix => icon.startsWith(prefix))

    return (
        <>
            {isIconifyIcon ? (
                <IconifyIcon icon={icon} className={className} color={color} />
            ) : typeof icon === 'string' ? (
                <img src={icon} className={className} alt={altText} style={{ color }} />
            ) : typeof icon === 'object' ? (
                <Icon icon={icon} className={className} color={color} />
            ) : null}
        </>
    );
};

export default ReusableIcon;
