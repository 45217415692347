import FooterMain from 'components/layout/footer/FooterMain';
import ContactSalesSection from 'layouts/section/ContactSalesSection';
import PricingHeaderSection from 'layouts/section/PricingHeaderSection';
import ComparePlansSection from 'layouts/section/ComparePlansSection';
import React from 'react'
import styles from 'styles/pages/PricingPage.module.sass'

/**
 * PricingPage component represents the main pricing page layout, combining the header section,
 * contact sales section, and footer to provide a complete view of pricing plans and sales contact options.
 *
 * @component
 *
 * @example
 * // Renders the PricingPage component
 * <PricingPage />
 *
 * @see PricingHeaderSection - Component for displaying the pricing page header, hero, and pricing tabs.
 * @see ContactSalesSection - Component encouraging users to contact sales for custom solutions.
 * @see FooterMain - Component for displaying the main footer.
 */
const PricingPage: React.FC = () => {
    return (
        <div className={styles['pricing-page']}>
            <PricingHeaderSection />
            <ComparePlansSection/>
            <ContactSalesSection />
            <FooterMain />
        </div>
    )
}

export default PricingPage;