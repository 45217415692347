import { logError } from "services/logService/errorLogger";
import { verifySignature } from "utils/VerifyGetSingatureUtil";
import { ApiResponse } from "./ApiTypes";

/**
 * Fetches data from an API call and processes it using a data extractor function.
 * 
 * @template T - The type of data returned by the API response.
 * @param {() => Promise<ApiResponse<T>>} apiCall - A function that performs the API call and returns a promise resolving to an ApiResponse object.
 * @param {(data: T) => any} dataExtractor - A function that extracts the desired data from the response.
 * @returns {Promise<{ data: any; error: string | null }>} A promise that resolves to an object containing the extracted data and any error message.
 * 
 * @example
 * async function exampleUsage() {
 *   const apiCall = async (): Promise<ApiResponse<{ name: string }>> => ({
 *     data: { name: 'Pricing' },
 *     status: 200
 *   });
 *   
 *   const dataExtractor = (data: { name: string }) => data.name;
 *   
 *   const result = await fetchApiData('PricingPage', apiCall, dataExtractor);
 *   console.log(result); // { data: 'Pricing', error: null }
 * }
 */
export async function fetchApiData<T>(TAG: string, apiCall: () => Promise<ApiResponse<T>>, dataExtractor: (data: T) => any): Promise<{ data: any; signature?: string | null; error: string | null }> {
    try {
        const response = await apiCall()
        return response.error
            ? { data: null, error: response.error }
            : response.data
            ? { data: dataExtractor(response.data), signature: response.signature ? response.signature : null, error: null }
            : { data: null, error: 'No data found!' }
    } catch (error) {
        logError(
            'Error fetching data',
            { error: error instanceof Error ? error.message : 'Unknown error'},
            TAG
        )
        return { data: null, error: 'Faild to fetch data!' }
    }
}

/**
 * Interface for representing properties required for verifying a GET request.
 * 
 * @interface GetRequestProps
 * @property {string} tag - A string representing a unique tag for logging or identification purposes.
 * @property {string} url - The URL of the GET request being verified.
 * @property {object | string | null} data - The data returned by the GET request, which will be verified.
 * @property {string} signature - The cryptographic signature to be verified.
 * 
 * @example
 * const props: GetRequestProps = {
 *   tag: 'FetchUserDetails',
 *   url: 'https://api.zerone.com/blog/',
 *   data: { title: 123, category: 'Blog A' },
 *   signature: 'abcdef1234567890'
 * };
 */
interface GetRequestProps {
    tag: string;
    url: string;
    data: object | string | null,
    signature: string
}

/**
 * Verifies the signature for GET request data.
 *
 * @function
 * @param {GetRequestProps} props - Contains tag, URL, data, and signature for verification.
 * @returns {ApiResponse} - The response object indicating success or failure of signature verification.
 * 
 * @example
 * // Example usage:
 * const props: GetRequestProps = {
 *   tag: 'FetchUserDetails',
 *   url: 'https://api.zerone.com/blog/',
 *   data: { title: 123, category: 'Blog A' },
 *   signature: 'abcdef1234567890'
 * };
 * 
 * const verificationResult = verifyGetRequest(props);
 * if (verificationResult.status === 200) {
 *   console.log('Signature is valid.');
 * } else {
 *   console.error('Signature verification failed:', verificationResult.error);
 * }
 */
export function verifyGetRequest({ tag, url, data, signature }: GetRequestProps): ApiResponse<any> {
    if (!data || !signature) {
        logError('Missing data or signature for verification', { data, url }, tag);
        return {
            data: null,
            status: 400,
            error: 'Data and signature are required for GET request verification.',
        };
    }

    if (typeof data === 'string' || typeof data === 'object') {
        const isValidSignature = verifySignature(data, signature, tag);
        if (!isValidSignature) {
            logError('Invalid signature detected', { data, signature, url }, tag);
            return {
                data: null,
                status: 400,
                error: 'Invalid signature detected.',
            };
        }
    } else {
        logError('Invalid data type for signature verification', { data, url }, tag);
        return {
            data: null,
            status: 400,
            error: 'Invalid data type for signature verification.',
        };
    }

    // Return success response if signature verification passes
    return {
        data,
        status: 200,
    };
}