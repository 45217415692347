import React, { useState } from 'react'
import SearchDropdown from 'components/common/SearchDropdown'
import { topics } from 'constants/insights/ExampleTopics'
import { zeroneReportCategories } from 'constants/data/report/ZeroneReportCategories';
import ReportCategory from 'components/layout/report/ReportCategoryItem';
import AnimatedBackgroundContainer from 'components/common/AnimatedBackgroundContainer';
import LoginForm from 'components/forms/LoginForm';

import BlogSummary from 'components/common/BlogSummary';
import ReportCategoryHero from 'components/layout/report/ReportCategoryHero';

const TestComponent: React.FC = () => {
    const [selectedTopic, setSelectedTopic] = useState<string | null>(null);

    const handleSelect = (id: string) => {
        setSelectedTopic(id);
        console.log(`Selected topic ID: ${selectedTopic}`);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "40px", padding: "20px"}}>
            <SearchDropdown
                options={topics.map(topic => ({ id: topic.id, name: topic.name }))}
                buttonName='Choose Topic'
                placeholder='What is your research about?'
                buttonColor='#136a97'
                onSelect={handleSelect}/>

            <ReportCategoryHero categories={zeroneReportCategories} />

            {zeroneReportCategories && zeroneReportCategories.map((category) => (
                <ReportCategory
                    key={category.id} 
                    id={category.id} 
                    title={category.title} 
                    meta={category.meta} 
                    description={category.description} 
                    topics={category.topics} 
                />
            ))}

            <AnimatedBackgroundContainer showFooter={true}>
                <LoginForm />
            </AnimatedBackgroundContainer>


            <BlogSummary 
                id={'436f8500-d826-4902-a876-326e26e5d5b0'} 
                category={'Technology'} 
                title={'A.I. is changing how people work in the Tech Indstry'} 
                catchyPhrase={'Lorem ipsum dolor sit amet consectetur. Varius lectus nunc elit parturient. Scelerisque feugiat dignissim vitae.'} 
                author={{
                    name: "Author",
                    imageUrl: "https://images.unsplash.com/photo-1531123897727-8f129e1688ce?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                    role: "Tech Writer",
                    href: "https://socialprofile.com/author"
                }} 
                meta={{
                    imageUrl: "https://images.unsplash.com/photo-1616161560417-66d4db5892ec?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                    createdDate: new Date(),
                    tags: ["AI", "Innovation", "Technology"],
                    readTime: "4 minutes"
                }} 
            />

            <BlogSummary 
                id={'436f8500-d826-4902-a876-326e26e5d5b0'} 
                category={'Technology'} 
                title={'A.I. is changing how people work in the Tech Indstry'} 
                catchyPhrase={'Lorem ipsum dolor sit amet consectetur. Varius lectus nunc elit parturient. Scelerisque feugiat dignissim vitae.'} 
                author={{
                    name: "Author",
                    imageUrl: "https://images.unsplash.com/photo-1531123897727-8f129e1688ce?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                    role: "Tech Writer",
                    href: "https://socialprofile.com/author"
                }} 
                meta={{
                    imageUrl: "https://images.unsplash.com/photo-1616161560417-66d4db5892ec?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                    createdDate: new Date(),
                    tags: ["AI", "Innovation", "Technology"],
                    readTime: "4 minutes"
                }} 
            />

        </div>
    )
}

export default TestComponent;