import { DynamicInfo } from "interface/DynamicInfo"

/**
 * Represents the core values of the organization as a `DynamicInfo` object.
 * 
 * This export defines the organization's commitment through three key values:
 * "Excellent Innovation," "Accountable Partnership," and "Empathetic Service." Each value
 * is represented as an item with a unique ID, icon, title, and description.
 * 
 * @constant
 * @type {DynamicInfo}
 * 
 * @property {UUID} id - Unique identifier for the core values section.
 * @property {string} title - The main title for the core values section.
 * @property {string} subtitle - A subtitle providing context for the section.
 * @property {DynamicCardInfo[]} items - An array of objects representing each core value, 
 * with associated metadata like ID, icon, title, and description.
 * 
 * @example
 * import { coreValues } from 'constants/data/about/CoreValues';
 * 
 * console.log(coreValues.title); // Outputs: "OUR COMMITMENT"
 * console.log(coreValues.items[0].title); // Outputs: "Excellent Innovation"
 */
export const coreValues: DynamicInfo = {
    id: "c4c982d8-e48f-47d6-815c-b6b981e86a97",
    title: "OUR COMMITMENT",
    subtitle: "Core Values",
    items: [
        {
            id: "a10b98c2-02a7-481c-be30-ae6edd2da881",
            icon: "streamline:industry-innovation-and-infrastructure-solid",
            title: "Excellent Innovation",
            description: "We believe data and technology can be used to fuel the advancement of industries, communities, and economies. And we believe such innovative solutions should be delivered in an excellent manner – in a truly outstanding way."
        },
        {
            id: "6c01f382-78b7-4092-bdf4-15846b95a92a",
            icon: "dashicons:admin-customizer",
            title: "Accountable Partnership",
            description: "We believe in being true partners to our clients; working with them shoulder to shoulder, delivering on our promises and holding ourselves accountable to the highest standards."
        },
        {
            id: "7424ddcb-9109-4e10-a19a-f691ba11d77c",
            icon: "healthicons:medical-advice",
            title: "Empathetic Service",
            description: "We serve our employees, users, and partners with an enormous sense of responsibility and mindfulness to their needs, even those unarticulated."
        }
    ]
}