import React from 'react';
import AnimatedBackgroundContainer from 'components/common/AnimatedBackgroundContainer';
import SuccessSection from 'layouts/section/SuccessSection'
import styles from 'styles/pages/SuccessPage.module.sass';

/**
 * SuccessPage component renders the success page layout with an animated background and a success message.
 * 
 * The component utilizes `AnimatedBackgroundContainer` to provide a visually engaging background with
 * an optional footer. The `SuccessSection` component is nested inside, displaying a success message and animation.
 * 
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import SuccessPage from 'pages/SuccessPage';
 * 
 * const App: React.FC = () => (
 *   <SuccessPage />
 * );
 * 
 * export default App;
 * ```
 * 
 * @returns {JSX.Element} The JSX for the SuccessPage component.
 */
const SuccessPage: React.FC = () => {
    return (
        <div className={styles['success-page']}>
            <AnimatedBackgroundContainer showFooter={true}>
                <SuccessSection />
            </AnimatedBackgroundContainer>
        </div>
    );
};

export default SuccessPage;