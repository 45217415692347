import { ReportCategory } from 'interface/ReportCategory'
import React, { useEffect, useState } from 'react'
import styles from 'styles/layout/ReportCategoriesHeaderSection.module.sass'
import HeaderNavigation from '../header/HeaderNavigation'
import ReportCategoryHero from './ReportCategoryHero'
import ReportCategoryCarousel from './ReportCategoryCarousel'
import { CategoryTopic } from 'interface/CategoryTopic'
import { fetchTopHotReports } from 'api/ReportApiService'

const TAG = 'ReportCategoriesHeader'

interface ReportCategoriesHeaderSectionProps {
    categories: ReportCategory[]
}

/**
 * ReportCategoriesHeaderSection component renders the header section for report categories, including navigation,
 * a hero section, and a carousel displaying hot reports.
 *
 * @component
 * @param {ReportCategoriesHeaderSectionProps} props - Props containing an array of report categories.
 *
 * @typedef {Object} ReportCategoriesHeaderSectionProps
 * @property {ReportCategory[]} categories - Array of report categories to be displayed in the header.
 *
 * @example
 * // Example usage:
 * import ReportCategoriesHeaderSection from './ReportCategoriesHeaderSection';
 *
 * const categories = [
 *   { id: '1', title: 'Market Insights', meta: { cover: 'image.jpg' }, description: 'Details about markets', topics: [] }
 * ];
 *
 * <ReportCategoriesHeaderSection categories={categories} />;
 *
 * @returns {JSX.Element} The rendered ReportCategoriesHeaderSection component.
 */
const ReportCategoriesHeaderSection: React.FC<ReportCategoriesHeaderSectionProps> = ({ categories }) => {
    const [hotReports, setHotReports] = useState<CategoryTopic[]>([])

    useEffect(() => {
        const loadHotReports = async () => {
            try {
                const fetchedHotReports = await fetchTopHotReports(TAG)
                setHotReports(fetchedHotReports)
            } catch {
                setHotReports([])
            }
        }

        loadHotReports()
    }, [])
    return (
        <section className={styles['report-categories-header-section']}>
            <div className={styles['main-wrapper']}>
                <HeaderNavigation theme='dark' />
                <div className={styles['background-container']}>
                    <ReportCategoryCarousel reports={hotReports} />
                    <ReportCategoryHero categories={categories} />
                </div>
            </div>
        </section>
    )
}

export default ReportCategoriesHeaderSection;