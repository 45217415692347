import { v4 as uuidv4 } from 'uuid'

/**
 * logError function logs an error message with a timestamp, unique error ID, and additional metadata to the console.
 * 
 * @function
 * 
 * @param {string} message - The error message to be logged.
 * @param {Record<string, any>} meta - Additional metadata related to the error.
 * @param {string} fileName - The name of the file where the error occurred.
 * 
 * @example
 * logError("Failed to fetch data", { endpoint: "/api/data" }, "dataService.ts");
 * // Logs the error with timestamp, error ID, file name, message, and metadata.
 * 
 * @see uuidv4 - Generates a unique identifier for each error instance.
 */
export const logError = (message: string, meta: Record<string, any>, fileName: string): void => {
    const timestamp = new Date().toISOString()
    const errorId = uuidv4()

    console.error(`[${timestamp}] [Error ID: ${errorId}] [File: ${fileName}] ${message}`, meta);
}