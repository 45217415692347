import React from 'react'
import styles from 'styles/components/ReportCategoryCard.module.sass'
//import { useNavigate } from 'react-router-dom'
import ReusableIcon from 'components/common/ReusableIcon'
import { CategoryMeta } from 'interface/CategoryMeta';

/**
 * Props for the ReportCategoryCardProps component.
 * 
 * @typedef {Object} ReportCategoryHeaderProps
 * @property {UUID} id - Unique identifier for the report category.
 * @property {string} title - The title of the report category displayed in the header.
 * @property {CategoryMeta} meta - Metadata for the category, including colors and icon details.
 */
interface ReportCategoryCardProps {
    id: UUID;
    title: string;
    meta: CategoryMeta;
}

/**
 * ReportCategoryCard component displays a clickable card for a report category,
 * including a customizable icon and title. When clicked, it logs the category ID 
 * and scrolls the page to the top.
 *
 * @component
 * @param {ReportCategoryHeader} props - The props for the component.
 * @param {UUID} props.id - The unique identifier for the report category.
 * @param {string} props.title - The title of the report category displayed on the card.
 * @param {CustomIconProps} props.icon - Configuration for the icon representing the report category, allowing customization of icon type, color, background, and size.
 *
 * @example
 * <ReportCategoryCard
 *   id="fabd37f0-7bd9-413a-b782-cbc73fb01b00"
 *   title="Sales"
 *   meta={{
 *     icon: "mdi:robot",
 *     primaryColor: "#004264",
 *     secondaryColor: "#D8EFFF"
 *   }}
 * />
 *
 * @returns {JSX.Element} A clickable card component representing a report category.
 */
const ReportCategoryCard: React.FC<ReportCategoryCardProps> = ({ 
    id,
    title,
    meta
 }) => {
    //const navigate = useNavigate()

    /**
     * Handles the click event on the card.
     * Navigates to the report's detailed page and scrolls to the top.
     */
    function handleCardClick(): void {
        //navigate(`/reports/topic/${id}`)
        console.log(id)
        window.scrollTo(0, 0)
    }

    return (
        <div 
            className={styles['report-category-card']} 
            onClick={handleCardClick}
            style={{
                boxShadow: `0px 2px 9.1px ${meta.secondaryColor}`
            }}
        >
            <div 
                className={styles['category-icon']} 
                style={{
                    backgroundColor: meta.secondaryColor
                }}
            >
                <ReusableIcon icon={meta.icon} altText='report-icon' className={styles['icon'] || 'icon'} color={meta.primaryColor} />
            </div>
            <h3 className={styles['category-title']}>{title}</h3>
        </div>
    )
 }

 export default ReportCategoryCard;
