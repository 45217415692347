import { TabSelectorProps } from 'interface/TabSelectorProps';
import React from 'react'
import styles from 'styles/components/TabSelector.module.sass'

/**
 * TabSelector component renders a set of tabs for selecting different categories or sections.
 * 
 * @component
 * @param {string[]} tabs - An array of tab names to be displayed.
 * @param {string} activeTab - The currently active tab.
 * @param {(tab: string) => void} onTabChange - A function to handle when a tab is clicked and change the active tab.
 * 
 * @example
 * <TabSelector
 *   tabs={['Overview', 'Details', 'Reviews']}
 *   activeTab="Overview"
 *   onTabChange={(tab) => console.log('Selected tab:', tab)}
 * />
 * 
 * @returns {JSX.Element} The JSX for the TabSelector component.
 */
const TabSelector: React.FC<TabSelectorProps> = ({
    tabs,
    activeTab,
    onTabChange
}) => {
    const gap = tabs.length > 4
        ? 'clamp(10px, 2vw, 20px)'
        : 'clamp(10px, 2vw, 30px)'
    const padding = tabs.length > 4
        ? 'clamp(4px, 2vw, 8px)'
        : 'clamp(8px, 2vw, 16px)'

    return (
        <div className={styles['tab-items-parent']} style={{ gap: gap, padding: padding }}>
            {tabs.map((tab) => (
                <div
                    key={tab} 
                    className={`${styles['tab-item']} ${activeTab == tab ? styles['active'] : ''}`}
                    style={{ padding: padding }}
                    onClick={() => onTabChange(tab)}>
                        {tab}
                </div>
            ))}
        </div>
    )
}

export default TabSelector;