import React from 'react'
import styles from 'styles/components/ReportCarouselCard.module.sass'
import arrowRightLight from '@iconify/icons-ph/arrow-right-light'
import SecondaryButton from 'components/common/SecondaryButton';
import { ColorPair } from 'interface/ColorPair';
import getRandomColorPair from 'utils/RandomColorPair';

/**
 * Props for the ReportCarouselCard component.
 * 
 * @typedef {Object} ReportCarouselCardProps
 * @property {UUID} id - Unique identifier for the report.
 * @property {string} title - The title of the report.
 * @property {string} description - A brief description of the report.
 * @property {string} dataset - The dataset label of the report.
 */
interface ReportCarouselCardProps {
    id?: UUID;
    title?: string;
    description?: string;
    dataset?: string;
    meta?: { cover?: string; };
}

/**
 * A component representing a carousel card displaying a report summary.
 * Each card includes a background image, title, category badge, description, 
 * and a button to view more details.
 * 
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import ReportCarouselCard from 'components/ReportCarouselCard';
 * 
 * const App: React.FC = () => (
 *   <ReportCarouselCard
 *     id="123e4567-e89b-12d3-a456-426614174000"
 *     title="2024 Elections in Ghana"
 *     description="A comprehensive analysis of the recent elections."
 *     category="Politics"
 *     meta={
 *        cover: 'http://url-to-image.com'
 *     }
 *   />
 * );
 * 
 * export default App;
 * ```
 * 
 * @param {ReportCarouselCardProps} props - The properties for the ReportCarouselCard component.
 * @param {UUID} props.id - Unique identifier for the report.
 * @param {string} props.title - The title of the report.
 * @param {string} props.description - A brief description of the report.
 * @param {string} props.category - The category label of the report.
 * @param {object} props.meta - The metadata for the topic. e.g cover
 * 
 * @returns {JSX.Element} The rendered carousel card component.
 */
const ReportCarouselCard: React.FC<ReportCarouselCardProps> = ({ id, title, description, dataset, meta }) => {
    /**
     * Handles the click event for the "View Report" button.
     * Logs the report ID to the console.
     */
    function handleViewReportButtonClicked () {
        console.log(`Report: ${id} clicked!`)
    }

    const pairColors: ColorPair = getRandomColorPair()

    console.log(meta)

    return (
        <div className={styles['report-carousel-card']}>
            <div className={styles['report-image']} style={{ backgroundImage: `url(${meta?.cover || ''})` }}>
                <p className={styles['image-title']}>{title}</p>
            </div>
            <div className={styles['report-content']}>
                <span
                    className={styles['category']}
                    style={{
                        backgroundColor: pairColors.light,
                        color: pairColors.dark
                    }}
                >
                    {dataset}
                </span>

                <div className={styles['main-content']}>
                    <p className={styles['title']}>{title}</p>
                    <p className={styles['description']}>{description}</p>
                </div>

                <SecondaryButton
                    width='auto'
                    padding='12px 20px 12px 0px'
                    text="View Report"
                    border='none'
                    primaryColor="transparent"
                    textColor="#005581"
                    hoverColor="transparent"
                    hoverTextColor="#005581"
                    icon={arrowRightLight}
                    doSomething={handleViewReportButtonClicked}
                />
            </div>
        </div>
    )
}

export default ReportCarouselCard;