import React, { useEffect, useState } from 'react'
import styles from 'styles/pages/BlogPage.module.sass'
import FooterSection from 'layouts/section/FooterSection'
import BlogHeaderSection from 'components/layout/blog/BlogHeaderSection'
import BlogsSection from 'components/layout/blog/BlogsSection'
import { fetchHotTopic } from 'api/BlogApiService'
import { BlogContent } from 'interface/BlogContent'

const TAG = 'BlogPage'

/**
 * BlogPage component - A page layout component for rendering the blog page.
 *
 * @component
 * @returns {JSX.Element} The rendered BlogPage component.
 *
 * @example
 * // Example usage:
 * <BlogPage />
 */
const BlogPage: React.FC = () => {
    const [topic, setTopic] = useState<BlogContent | null>(null)

    useEffect(() => {
        const loadHotTopic = async () => {
            try {
                const fetchedTopic = await fetchHotTopic(TAG)
                setTopic(fetchedTopic)
            } catch {
                setTopic(null)
            }
        }

        loadHotTopic()
    }, [])
    
    return (
        <div className={styles['blog-page']}>
            <BlogHeaderSection topic={topic} />
            <BlogsSection />
            <FooterSection />
        </div>
    )
}

export default BlogPage;