import ContactUs from 'components/layout/footer/ContactUs';
import FooterMain from 'components/layout/footer/FooterMain';
import HeaderNavigation from 'components/layout/header/HeaderNavigation';
import React from 'react';
import DataPrivacySection from 'layouts/section/DataPrivacySection'



const PrivacyPage: React.FC = () => (
  <div>
    <HeaderNavigation theme='dark'/>
    <DataPrivacySection />
    <ContactUs/>
    <FooterMain/>

    
  </div>
);

export default PrivacyPage;