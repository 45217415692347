import React from 'react'
import styles from 'styles/components/AnimatedBackgroundContainer.module.sass'
import Lottie from 'lottie-react'
import graphAnimation from 'assets/animations/graph-background-animation.json'
import SimpleFooter from 'components/layout/footer/SimpleFooter';

/**
 * Props for the AnimatedBackgroundContainer component.
 * 
 * @interface AnimatedBackgroundContainerProps
 * @property {React.ReactNode} children - The child elements to be rendered within the GraphBackground component.
 * @property {string} [className] - Optional CSS class names to apply to the outermost div element.
 * @property {string} [backgroundGradient] - Optional custom gradient string to define the background style.
 * @property {string} [showFooter] - Optional boolean to show footer.
 * 
 * @example
 * Example usage:
 * 
 * ```tsx
 * const props: AnimatedBackgroundContainerProps = {
 *   children: <div>Graph Content</div>,
 *   className: "custom-class",
 *   backgroundGradient: "linear-gradient(to right, #00f, #0ff)"
 *   animationData: {...lottie animation data }
 *   showFooter: true
 * };
 * ```
 */
interface AnimatedBackgroundContainerProps {
    children: React.ReactNode;
    className?: string;
    backgroundGradient?: string;
    animationData?: object;
    showFooter?: boolean;
}

/**
 * AnimatedBackgroundContainer component wraps its children with a div that applies a configurable background gradient.
 *
 * @component
 * @param {React.ReactNode} children - The child elements to be wrapped within the GraphBackground component.
 * @param {string} [className] - Optional additional CSS classes to apply to the wrapper div.
 * @param {string} [backgroundGradient] - Optional custom gradient for the background. Defaults to a predefined gradient.
 * @property {object} [animationData] - Optional lottie animation data, defaults to graph animation if not provided.
 * @property {string} [showFooter] - Optional boolean to show footer.
 * 
 * @example
 * Example usage:
 * 
 * ```tsx
 * import AnimatedBackgroundContainer from 'components/AnimatedBackgroundContainer';
 * 
 * const ExampleComponent: React.FC = () => (
 *   <AnimatedBackgroundContainer className="custom-class" backgroundGradient="linear-gradient(to bottom, #ff7e5f, #feb47b)" showFooter={true}>
 *     <p>This is a child component.</p>
 *   </AnimatedBackgroundContainer>
 * );
 * 
 * export default ExampleComponent;
 * ```
 * 
 * @returns {JSX.Element} The JSX element wrapping the children with a configurable background.
 */
const AnimatedBackgroundContainer: React.FC<AnimatedBackgroundContainerProps> = ({ children, className, backgroundGradient, animationData = graphAnimation, showFooter = false }) => {
    return (
        <section 
            className={className ? className : styles['animated-background']}
            style={{ 
                background: backgroundGradient ? backgroundGradient : 'linear-gradient(180deg, #005581, #00131d)',
                height: showFooter ? '100vh' : '100%'
            }}
        >
            {animationData && (
                <Lottie
                    animationData={animationData}
                    loop
                    className={styles['lottie-animation']}
                />
            )}
            <div className={styles['main-content']}>
                {children}
            </div>
            {showFooter && (
                <SimpleFooter />
            )}
        </section>
    )
}

export default AnimatedBackgroundContainer;