import DynamicInfoContainer from 'components/common/DynamicInfoContainer'
import { productOfferings } from 'constants/data/about/ProductOfferings'
import { coreValues } from 'constants/data/about/CoreValues'
import { DynamicInfo } from 'interface/DynamicInfo'
import React from 'react'
import styles from 'styles/layout/AboutUsMainSection.module.sass'

const mainInfoList: DynamicInfo[] = [
    productOfferings,
    coreValues
]

/**
 * AboutUsMainSection component renders the main content section of the "About Us" page.
 * 
 * This section includes dynamic information containers that display core values and product offerings.
 * The data is sourced from `constants/data/about` and passed into the `DynamicInfoContainer` component.
 * 
 * @component
 * 
 * @example
 * // Example usage of the AboutUsMainSection component
 * import AboutUsMainSection from 'components/layout/AboutUsMainSection';
 * 
 * const App: React.FC = () => (
 *   <AboutUsMainSection />
 * );
 * 
 * export default App;
 */
const AboutUsMainSection: React.FC = () => {
    return (
        <section className={styles['about-main-section']}>
            {mainInfoList && mainInfoList.map((info) => (
                <DynamicInfoContainer key={info.id} info={info} />
            ))}
        </section>
    )
}

export default AboutUsMainSection;