import { DynamicInfo } from 'interface/DynamicInfo'
import React from 'react'
import styles from 'styles/components/DynamicInfoContainer.module.sass'
import SectionHeader from './SectionHeader';
import DynamicInfoCard from 'components/cards/DynamicInfoCard';

/**
 * Props for the DynamicInfoContainer component.
 *
 * @interface DynamicInfoContainerProps
 * @property {DynamicInfo} info - The dynamic information object containing a title, subtitle, and items to display.
 */
interface DynamicInfoContainerProps {
    info: DynamicInfo;
}

/**
 * DynamicInfoContainer component renders a section containing dynamic information.
 * 
 * This component includes a section header for the title and subtitle, and
 * a grid layout to display individual dynamic information cards.
 * 
 * @component
 * 
 * @example
 * import DynamicInfoContainer from 'components/common/DynamicInfoContainer';
 * 
 * const info = {
 *   title: "Our Impact",
 *   subtitle: "Key metrics from across Africa",
 *   items: [
 *     { id: "1", icon: "mdi:chart-bar", title: "Impact Reports", description: "Over 50+ reports published" },
 *     { id: "2", icon: "mdi:earth", title: "Global Reach", description: "Insights across 4 continents" }
 *   ]
 * };
 * 
 * <DynamicInfoContainer info={info} />
 *
 * @param {DynamicInfoContainerProps} props - The props for the component.
 * @returns {JSX.Element} The rendered DynamicInfoContainer component.
 */
const DynamicInfoContainer: React.FC<DynamicInfoContainerProps> = ({ info }) => {
    return (
        <div key={info.title} className={styles['dynamic-info-container']}>
            <SectionHeader
                title={info.title}
                subtitle={info.subtitle}
            />
            <div className={styles['info-grid']}>
                {info.items && info.items.map((item) => (
                    <DynamicInfoCard
                        key={item.id}
                        id={item.id} 
                        icon={item.icon} 
                        title={item.title} 
                        description={item.description} 
                    />
                ))}
            </div>
        </div>
    )
}

export default DynamicInfoContainer;